import * as React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { Backdrop, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import { Layout, LayoutVarient } from './components/Layout';
import { Suspense } from 'react';
import { Home } from './Home/Home';
import { About } from './About/About';
import { NewsList } from './News/News';
import { Publication } from './Publication/Publication';
import { MemberService } from './MemberService/MemberService';
import { RelatedSite } from './RelatedSite/RelatedSite';
import { initGA, logPageView, setUserIdGA } from './components/Analytics';
import { Login } from './Login/Login';
import { ApplyMember } from './Login/ApplyMember';
import Manage from './ManageBoard/ManageBoardIndex';
import { useDispatchWithType, useUser } from './store';
import { useLazyGetIsLoginQuery } from './components/services/login';
import ErrorPage from './components/ErrorPage/404Page';
import mainTheme from './theme';
import ModalSystem from './components/Modal/ModalSystem';
import { animateScroll, scroller } from 'react-scroll'
import { loginUser } from './store/rootReducer';
import BlockAnonymous from './components/BlockAnonymous';
import { EventIndex } from './Event/EventPage';
import { User, UserRole } from './components/class';

function App() {
    return (
        <Suspense fallback={<Loader />}>
            <ThemeProvider theme={mainTheme}>
                <AppRouter />
            </ThemeProvider>
        </Suspense>
    )
}
function AppRouter() {
    const [getIsLogin, { data: loginState }] = useLazyGetIsLoginQuery();
    const [isLoading, setIsLoading] = React.useState<boolean>(true); //載入使用者登入資料

    //控制navbar顯示
    const [showNavBar, setShowNavBar] = React.useState<LayoutVarient>(null);
    const history = useHistory();
    const pathName = useLocation().pathname;
    const search = useLocation().search;
    let query = new URLSearchParams(search);
    
    React.useEffect(() => {
        let matches = ['/login', '/register', '/verify', '/forgotpassword', '/resetpassword'];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setShowNavBar('login');
        }
        else if (path.match('/manage')) {
            setShowNavBar('noNavbar');
        }
        else {
            setShowNavBar(null);
        }
    }, [pathName])
    
    //控制頁面切換時的位置
    const hash = useLocation().hash;
    React.useEffect(() => {
        if (hash)
            scroller.scrollTo(hash.split('#')[1], {
                smooth: true,
                //offset: -30,
            });
        else
            animateScroll.scrollToTop();
    }, [pathName, hash])

    //控制顯示頁面
    const user = useUser();
    const dispatch = useDispatchWithType();
    React.useEffect(() => {
        let matches = ['/login', '/register', '/verify', '/forgotpassword', '/resetpassword', '/invite'];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setIsLoading(false);
        }
        else {
            getIsLogin();
        }
    }, []);


    React.useEffect(() => {
        if (loginState) {
            if (loginState.isSuccess) {
                dispatch(loginUser(loginState.data));
                //訪客
                if (loginState.data?.role === UserRole.anonymous) {
                    return;
                }
            }
            else {
                //console.log('Not Login!')
                //navigate('/Login' + (pathName ? "?redirect=" + pathName + search : ""));
                setIsLoading(false);
            }
        }
    }, [loginState])

    //防止user資料變化影響GA
    const [userId, setUserId] = React.useState<string>();
    React.useEffect(() => { setUserId(user?.userId) }, [user]);

    //初始化GA
    const [first, setFirst] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (first) {
            initGA();
            setFirst(false);
        }
        logPageView();
    }, [history.location.pathname])
    React.useEffect(() => {
        if (userId) {
            setUserIdGA(userId);
            setIsLoading(false);
        }
        return () => {
            setUserIdGA(null);
        }
    }, [userId]);
    
    return (
        <>
            <CssBaseline />
            <ModalSystem />
            <Layout varient={showNavBar}>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/About' component={About} />
                    <Route path='/News' component={NewsList} />
                    <Route path='/Publication' component={Publication} />
                    <Route path='/Event' component={EventIndex} />
                    <Route path='/MemberService' component={MemberService} />
                    <Route path='/RelatedSite' component={RelatedSite} />
                    <Route path='/Login' component={Login} />
                    <Route path='/ApplyMember' component={ApplyMember} />
                    <Route path='/Manage' component={Manage} />
                    <Route path='/' component={ErrorPage} />
                </Switch>
            </Layout>
            
        </>
    )
}
/*<Backdrop open={isLoading} sx={{ color: '#fff', zIndex: 10000, background: (theme) => theme.palette.background.default }}>
    <Typography>Loading...</Typography>
</Backdrop>*/
const Loader = () => (
    <div>
        <div>loading...</div>
    </div>
);

export default App;