import {
    Backdrop, Box, BoxProps, Button, Card, CircularProgress, Container, Dialog,
    DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel,
    Link, OutlinedInput, Stack, styled,
    SvgIcon, Tab, Tabs, TextField, Toolbar, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { HelpOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useDispatchWithType } from '../store';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useUser } from "../store";
import { SubTitle } from "../components/Common";
import { NewsType, News, User, UserData, UserType } from "../components/class";
import { useGuestLoginMutation, useLoginMutation, useLogoutMutation } from '../components/services/login';
import { useGetUserQuery, useLazyGetUserQuery } from '../components/services/user';
import { loginUser, logoutUser } from '../store/rootReducer';
import { deleteAllCookie } from '../cookie-utils';

export enum loginType {
    個人會員 = 0,
    團體會員 = 1,
}
export type formData = {
    account: string;
    password: string;
}
export function Login() {
    const history = useHistory();
    const user = useUser();
    const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();

    const [login, { isLoading }] = useLoginMutation();
    const [logout, { isLoading: logoutLoading }] = useLogoutMutation();
    const [getUserData, { }] = useLazyGetUserQuery();

    const [userData, setUserData] = React.useState<UserData>();

    React.useEffect(() => {
        if (user) {
            getUserData(user.userId).unwrap().then(result => {
                if (result.isSuccess) {
                    setUserData(result.data)
                }
                else {
                    console.log(result.message)
                }
            })
        }
    }, []);

    const [filterType, setFilterType] = React.useState<loginType>(0);
    function handleChange(event: React.SyntheticEvent, newValue: any) {
        setFilterType(newValue);
    };

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword(show => !show);
    }

    const [errorMessage, setErrorMessage] = React.useState<JSX.Element>();

    let query = new URLSearchParams(useLocation().search);

    const schema = yup.object().shape({
        account: yup.string().required(t('login.account.error')),
        password: yup.string().required(t('login.password.error')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { account: "", password: "" }
    });

    function onLoginSubmit(data: { account: string, password: string }) {
        setErrorMessage(<></>);
        login(data).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    getUserData(result.data.userId).unwrap().then(result => {
                        if (result.isSuccess) {
                            setUserData(result.data)
                        }
                        else {
                            console.log(result.message)
                        }
                    })
                    dispatch(loginUser(result.data));
                    if (query.get('redirect')) {
                        history.push(query.get('redirect'));
                    }
                    else { history.push('/'); }
                }
                else {
                    if (result.message == "此帳號已被停權") { setErrorMessage(<>此帳號已被停用，如有疑問請聯絡管理員。<br/> <Link href='mailTo:a0989015018@gmail.com'>a0989015018@gmail.com</Link></>) } else setErrorMessage(<>登入失敗：帳號密碼或驗證碼錯誤</>);
                }
            }).catch((error) => {
                // 後端報錯 ex:500、404
                setErrorMessage(<>Unconnect</>);
                console.error("發生錯誤", error)
            });
    }

    function handleLogout() {
        logout().unwrap().then(result => {
            if (result.isSuccess) {
                dispatch(logoutUser());
                deleteAllCookie();
                window.location.reload();
            }
            else {
                console.log(result.message);
            }
        })
    }

    // 跳轉 
    const handleClickLink = (link: string) => {
        history.push(link);
    }

    return (<>
        <Box sx={{ backgroundColor: "background.fourth", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Box sx={{ width: "600px", height: "480px", backgroundColor: "background.paper", borderRadius: "12px", mt: 3, mb: 1, }}>
                <Box sx={{ width: "100px", m: 2 }}>
                    <SubTitle title="會員登入" />
                </Box>
                <Divider />

                {!user ? <>
                    <Tabs value={filterType} onChange={handleChange} centered
                        textColor="secondary" indicatorColor="secondary" sx={{ my: 1 }}>
                        <Tab value={loginType.個人會員} label="個人會員"
                            sx={{ fontSize: "18px", mx: 1 }}
                        />
                        <Tab value={loginType.團體會員} label="團體會員"
                            sx={{ fontSize: "18px", mx: 1 }}
                        />
                    </Tabs>

                    <Stack component="form" onSubmit={handleSubmit(onLoginSubmit)}
                        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <TextField
                            variant="outlined"
                            placeholder={filterType == loginType.個人會員 ? "個人會員帳號" : "團體會員帳號"}
                            inputRef={register('account').ref}
                            error={!!errors.account}
                            helperText={errors.account ? errors.account.message : ' '}
                            {...register("account")}

                            FormHelperTextProps={{ style: { position: 'absolute', bottom: '0.5px', right: '40px' } }}
                            disabled={isLoading}
                            sx={{
                                m: 2, width: "300px", height: "40px",
                                "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
                            }}
                        />

                        <TextField
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="密碼"
                            inputRef={register('password').ref}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ' '}
                            {...register("password")}

                            FormHelperTextProps={{ style: { position: 'absolute', bottom: '0.5px', right: '40px' } }}
                            disabled={isLoading}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end" className="ml-1">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            sx={{
                                m: 2, width: "300px", height: "40px",
                                "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
                            }}
                        />
                        <LoadingButton variant="contained" type="submit" loading={isLoading}
                            sx={{ width: "300px", height: "45px", m: 2 }}>
                            登入
                        </LoadingButton>

                        {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
                        { /* <Typography color="text.highlight2" sx={{ my: 2 }} >忘記密碼？</Typography> */}
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", mt: 3 }}>
                            <Button variant="outlined" sx={{ width: "120px", height: "35px", mx: 1, color: "primary.light" }}
                                onClick={() => handleClickLink("/ApplyMember")}
                            >
                                申請會員
                            </Button>
                            <Button variant="outlined" sx={{ width: "120px", height: "35px", mx: 1, color: "primary.light" }}
                                onClick={() => handleClickLink("/MemberService")}
                            >
                                入會辦法
                            </Button>
                        </Box>
                    </Stack>
                </>
                    :
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Typography fontSize="18px" sx={{ my: 2 }}>{user.name}，您好</Typography>
                        <Typography color="text.subText">身分：{UserType[userData?.type] }</Typography>
                        <Typography color="text.subText">會員ID：{user.userId}</Typography>
                        <LoadingButton variant="text" loading={logoutLoading} onClick={handleLogout} sx={{ mt: 1, color: "text.highlight2" }}>登出</LoadingButton>
                    </Box>
                }
            </Box>
        </Box>
    </>)
}