import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../components/class";

const initialData: ReducerData = {
    user: null,
    modalControl: {
        modalType: null, modalProps: {}
    },
    scrollTrigger: false,
    selectedYear: '',
    selectedPage: 1,
};

export const loginUser = createAction<User>('LOGINUSER');
export const updateUser = createAction<User>('UPDATEUSER');
export const logoutUser = createAction<void>('LOGOUTUSER');
export const showModal = createAction<Modal>('SHOW_MODAL');
export const hideModal = createAction<void>('HIDE_MODAL');
export const setScrollTrigger = createAction<boolean>('SET_SCROLL_TRIGGER');
export const setSelectedYear = createAction<string>('SET_SELECTED_YEAR');
export const setSelectedPage = createAction<number>('SET_SELECTED_PAGE');

export const rootReducer = createReducer(initialData, (builder) =>
    builder
        //放入已登入的使用者資料
        .addCase(loginUser, (state, action: PayloadAction<User>) => ({
            ...state,
            user: action.payload
        }))
        //更新使用者資料
        .addCase(updateUser, (state, action: PayloadAction<User>) => ({
            ...state,
            user: action.payload
        }))
        //登出，刪除使用者資料
        .addCase(logoutUser, (state, action: PayloadAction<void>) => ({
            ...state,
            user: null
        }))
        //打開Modal
        .addCase(showModal, (state, action: PayloadAction<Modal>) => ({
            ...state,
            modalControl: action.payload
        }))
        //關閉Modal
        .addCase(hideModal, (state, action: PayloadAction<void>) => ({
            ...state,
            modalControl: {
                modalType: null, modalProps: {}
            }
        }))
        //更新後台滾動觸發器狀態
        .addCase(setScrollTrigger, (state, action: PayloadAction<boolean>) => ({
            ...state,
            scrollTrigger: action.payload
        }))
        //更新選擇年分
        .addCase(setSelectedYear, (state, action: PayloadAction<string>) => ({
            ...state,
            selectedYear: action.payload
        }))
        //更新選擇頁數
        .addCase(setSelectedPage, (state, action: PayloadAction<number>) => ({
            ...state,
            selectedPage: action.payload
        }))
);

export interface ReducerData {
    user: User;
    modalControl: Modal;
    scrollTrigger: boolean;
    selectedYear: string;
    selectedPage: number;
}

export interface Modal {
    modalType: string;
    modalProps: any;
}