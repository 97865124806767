import { Add } from "@mui/icons-material";
import { Box, Button, ButtonProps, styled, Typography, TypographyProps } from "@mui/material";
import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, important, TextField } from "../components/responsive-components";
//import { useAuthenticationMutation } from "../components/services/user";

export const AddButton: (props: ButtonProps) => JSX.Element = ({ sx, ...other }) => (
    <Button
        variant='contained' startIcon={<Add />}
        sx={{
            borderRadius: '29px',
            backgroundColor: (theme) => theme.palette.secondary.light,
            '&:hover': { backgroundColor: (theme) => theme.palette.secondary.dark },
            ...sx
        }}
        {...other}
    />
);

export const OutlinedButton: (props: ButtonProps) => JSX.Element = ({ sx, ...other }) => (
    <Button
        variant='outlined'
        sx={{
            ...sx
        }}
        {...other}
    />
);

export const GreenBackStageButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
        backgroundColor: theme.palette.secondary.main
    },
    '&.Mui-disabled': {
        backgroundColor: '#89b296'
    }
}));

export const RedBackStageButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    color: theme.palette.text.white,
    backgroundColor: '#F1505B',
    '&:hover': {
        backgroundColor: theme.palette.text.danger
    }
}));

export const GreyBackStageButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    color: theme.palette.text.white,
    backgroundColor: '#777777',
    '&:hover': {
        backgroundColor: theme.palette.text.secondary
    }
}));

export const CyanBackStageButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    color: theme.palette.text.white,
    backgroundColor: '#38CBCB',
    '&:hover': {
        backgroundColor: '#1DBDBD'
    }
}));

export const BlueBackStageButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    color: theme.palette.text.white,
    backgroundColor: theme.palette.text.highlight2,
    '&:hover': {
        backgroundColor: '#00A8D7'
    }
}));

export const MainBackStageButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark
    },
    '&.Mui-disabled': {
        backgroundColor: '#89b296'
    }
}));

export const ManageSubTitle: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    marginTop: '12px',
    padding: '8px 16px',
    color: theme.palette.background.fifth,
    backgroundColor: theme.palette.text.secondary,
}));


/*export function PasswordComfirmDialog(props: PasswordComfirmDialogProps) {
    const { open, title, discardButton, confirmButton, onConfirm, onClose } = props;

    //密碼驗證
    const [password, setPassword] = React.useState('');
    const [authError, setAuthError] = React.useState('');
    const [authentication, { isLoading: isAuthenticationLoading }] = useAuthenticationMutation();
    async function handleAuthentication(password: string) {
        let auth: boolean = false;
        try {
            await authentication({ password: password }).unwrap().then((data) => {
                if (data.isSuccess) {
                    auth = true;
                    setAuthError('');
                }
                else {
                    setAuthError(`密碼錯誤`);
                };
            });
        } catch (error) {
            console.log(error);
            setAuthError(`驗證失敗：${error}`);
        }
        return (auth);
    }

    function handleClose() {
        onClose();
        setPassword('');
        setAuthError('');
    }

    async function handleConfirm() {
        let result = await handleAuthentication(password);
        if (result) {
            onConfirm();
            setPassword('');
            setAuthError('');
        }
    }

    return (
        <Dialog open={open} onClose={() => handleClose()} fullWidth
            style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} PaperProps={{ sx: { padding: '24px' } }}>
            <DialogTitle sx={{ textAlign: 'left' }}>
                {title}
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', jusifyContent: 'flex-start' }}>
                <Typography fontSize='inherit' textAlign='start'>
                    輸入管理員密碼以進行操作 {important}
                </Typography>
                <TextField value={password} onChange={(event) => setPassword(event.target.value)} />
                <Typography color='error' fontSize='inherit' textAlign='start'>
                    {authError}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '20px 24px' }}>
                {discardButton ?
                    React.cloneElement(discardButton, { onClick: () => handleClose(), disabled: isAuthenticationLoading }) :
                    <OutlinedButton disabled={isAuthenticationLoading}
                        onClick={() => handleClose()} sx={{ flex: 1 }}>
                        取消
                    </OutlinedButton>
                }
                <Box sx={{ width: '16px' }} />
                {discardButton ?
                    React.cloneElement(confirmButton, { onClick: () => handleConfirm(), disabled: isAuthenticationLoading || !onConfirm }) :
                    <RedBackStageButton disabled={isAuthenticationLoading || !onConfirm}
                        onClick={() => handleConfirm()} sx={{ flex: 1 }}>
                        確定
                    </RedBackStageButton>
                }
            </DialogActions>
        </Dialog>
    )
}
interface PasswordComfirmDialogProps {
    open: boolean;
    title: React.ReactNode;
    discardButton?: React.ReactElement;
    confirmButton?: React.ReactElement;
    onConfirm: Function;
    onClose: () => void;
}*/

