import { useTheme, Box, Container, Paper, Typography, Stack, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";

export  function Tab1of1() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
  

    return (<>
        <SubTitle title="會員種類與會費" />
        <br />
        <MemberType />
    </>)
}

function MemberType() {
    return <>
       
   
        
       
        
        
        
        
        
        
        
        <Typography variant='subtitle1' fontWeight="bold" marginBottom="10px"> 【會員種類】</Typography>
        <Typography variant='body1' color="#3F3F44">一般會員：現任或曾任各級學校學生事務（訓導）工作者；或熱心學生事務工作並認同本會宗旨者。</Typography>
        <Typography variant='body1' color="#3F3F44">團體會員：國內教育主管機關立案之各級學校。</Typography>
        <Typography variant='body1' color="#3F3F44">榮譽會員：對學生事務研究與發展著有貢獻者。</Typography>
        <Typography variant='body1' color="#3F3F44">永久會員：一次繳交新台幣一萬元常年會費之個人會員。</Typography>
        <br />
        <Typography variant='subtitle1' fontWeight="bold" marginBottom="10px">【會費種類】</Typography>
        <Typography variant='body1' color="#3F3F44">入 會 費：一般會員500元、團體會員1000元。</Typography>
        <Typography variant='body1' color="#3F3F44">常年會費：一般會員500元、大專校院團體會員4000元、高中職(含)以下之團體會員2000元。</Typography>
        <Typography variant='body1' color="#3F3F44">學會季刊：每年出刊4期，會員(含團體及個人)將取得一份，如需多購置可年繳720元/份，非會員(含團體及個人)可年繳2000元/份。</Typography>
    </>
}


