import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import './custom.css'
import { store } from './store';

export default function Index() {

    return (
        <>
            <title>CH_WEBSIE_TITLE</title>
            <App />
        </>
    );
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Index />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

unregisterServiceWorker();
