import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Publication, PublicationDetail, PublicationType } from '../class';
import { ResultType } from './utils';

export interface PublicationModel {
    title: string;
    subtitle: string;
    type: PublicationType;
    publicationDate: string;
    imageUrl: string;
    pdfUrl: string;
    filesUrl: string[];
    editor: string;
    editor2: string;
    editor3: string;
}

export const baseApi = createApi({
    reducerPath: 'publicationApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/publication' }),
    endpoints: (builder) => ({
        getPublication: builder.query<ResultType<Publication>, string>({
            query: (publicationId) => `id=${publicationId}`,
        }),
        getPublicationList: builder.query<ResultType<Publication[]>, PublicationType>({
            query: (type) => `list?type=${type}`,
        }),
    }),
})

// exhibitor或superuser或admin用api
export const publicationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPublicationDetail: builder.query<ResultType<PublicationDetail>, string>({
            query: (publicationId) => `detail/${publicationId}`,
        }),
        addPublication: builder.mutation<ResultType<string>, PublicationModel>({
            query: ({ ...patch }) => ({
                url: ``,
                method: 'POST',
                body: patch,
            }),
        }),
        editPublication: builder.mutation<ResultType<string>, { id: string } & PublicationModel>({
            query: ({ id, ...patch }) => ({
                url: `${id}`,
                method: 'PUT',
                body: patch,
            }),
        }),
        deletePublication: builder.mutation<ResultType<null>, string>({
            query: (id) => ({
                url: `${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: false
});

export const { useGetPublicationListQuery } = publicationApi;
export const { useLazyGetPublicationListQuery } = publicationApi;

export const { useGetPublicationDetailQuery } = publicationApi;
export const { useLazyGetPublicationDetailQuery } = publicationApi;
export const { useAddPublicationMutation } = publicationApi;
export const { useEditPublicationMutation } = publicationApi;
export const { useDeletePublicationMutation } = publicationApi;