import { useTheme, Box, Container, Paper, Typography, Stack, Pagination, Button, CircularProgress } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'
import { SubTitle } from "../../components/Common";
import { EventType, Event } from "../../components/class";
import { useGetEventListQuery } from "../../components/services/event";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EventContent } from "./EventContent";

export function Tab1of1() {
    const location = useLocation();
    const [page, setPage] = React.useState<number>(1)
    const perPageCount = 5;
    const [eventList, setEventList] = React.useState<Event[]>([])
    const sortList = eventList//.sort((a, b) => { return a.top == true ? -1 : 0 })
    const pages = Math.ceil(sortList.length / perPageCount);
    const showList = sortList.filter((n, index) => index >= perPageCount * (page - 1) && index < perPageCount * page)
    const id = new URLSearchParams(location.search).get('id')
    const { data, isLoading, isSuccess, refetch } = useGetEventListQuery(EventType.理監事會議)

    React.useEffect(() => {
        if (isSuccess) setEventList(data.data)
        if (data) refetch()
    }, [data])
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    return (<>
        <SubTitle title="理監事會議" />
        <br />
        {isLoading ? <Stack justifyContent='center' alignItems='center'>
            <CircularProgress />
        </Stack>
            :
            <>
                {id ?
                    <EventContent id={id} />
                    :
                    <>
                        <Stack direction="column" spacing={1.25}>
                            {showList.map(n => <EventItem data={n} type={EventType.理監事會議} key={n.eventId} />)}
                        </Stack>
                        <Stack spacing={2} mt="20px" >
                            <Pagination count={pages} page={page} variant="outlined" shape="rounded" onChange={handleChange} />
                        </Stack>
                    </>
                }
            </>
        }

        < br />
    </>)

}

export function EventItem(props: { data: Event, type: EventType }) {
    const { data, type } = props;
    const theme = useTheme();
    const history = useHistory();

    const titleStyle = {
        "textOverflow": "ellipsis",
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "WebkitLineClamp": { xs: '2', md: "1" },
        "overflow": "hidden",
        "width": { xs: '75%', md: "90%" },
        "lineHeight": "1.25rem",
        "fontSize": "1.15rem",
        cursor: 'pointer'
    }
    const handleClick = () => {
        history.push(`./Event?type=${EventType[type]}&id=${data.eventId}`)
    }
    return <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2.5}
        sx={{ "borderRadius": "5px", "overflow": "hidden", border: '#A9A9A9 solid 1px' }}
    >
        <Box width='25%' maxHeight='124px'>
        <img
            src={data.imageUrl}
            alt={data.title}
            onClick={handleClick}
                style={{ cursor: 'pointer',maxWidth:'100%' }}
                
        />
        </Box>
        <Stack>
            <Typography color='secondary.main'>{new Date(data.createTime).toLocaleDateString('zh-tw')}
            </Typography>
            <Stack
                direction="row"
                alignItems="flex-end"
                spacing={1.25}
            >
                <Typography
                    color='primary.main'
                    sx={titleStyle}
                    onClick={handleClick}
                >
                    {data.title}
                </Typography>
                <ArrowForwardIcon
                    sx={{ fill: theme.palette.secondary.main, "padding": "3px", "border": `${theme.palette.secondary.main} 2px solid`, "borderRadius": "50%", cursor:'pointer' }}
                    onClick={handleClick}
                />
            </Stack>
        </Stack>
    </Stack>
}



