import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Event, EventDetail, EventType } from '../class';
import { ResultType } from './utils';

export interface EventModel {
    title: string;
    type: EventType;
    content: string;
    imageUrl: string;
    imageUrls: string[];
    filesUrl: string[];
}

export const baseApi = createApi({
    reducerPath: 'eventApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/event' }),
    endpoints: (builder) => ({
        getEventList: builder.query<ResultType<Event[]>, EventType>({
            query: (type) => `list?type=${type}`
        }),
        getEventDetail: builder.query<ResultType<EventDetail>, string>({
            query: (eventId) => `detail?eventId=${eventId}`
        }),
    }),
})

// exhibitor或superuser或admin用api
export const eventApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        addEvent: builder.mutation<ResultType<string>, EventModel>({
            query: ({ ...patch }) => ({
                url: ``,
                method: 'POST',
                body: patch,
            }),
        }),
        editEvent: builder.mutation<ResultType<string>, { id: string } & EventModel>({
            query: ({ id, ...patch }) => ({
                url: `${id}`,
                method: 'PUT',
                body: patch,
            }),
        }),
        deleteEvent: builder.mutation<ResultType<null>, string>({
            query: (id) => ({
                url: `${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: false
});

export const { useGetEventListQuery } = eventApi;
export const { useLazyGetEventListQuery } = eventApi;
export const { useGetEventDetailQuery } = eventApi;
export const { useLazyGetEventDetailQuery } = eventApi;

export const { useAddEventMutation } = eventApi;
export const { useEditEventMutation } = eventApi;
export const { useDeleteEventMutation } = eventApi;