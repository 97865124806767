import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from '../class'
import { ResultType } from './utils';

export interface profileObj { // Google登入時提供
    tokenId: string;
    googleId: string;
    imageUrl: string;
    email: string;
    name: string;
    givenName: string;
    familyName: string;
}

export interface UserId { userId: string };

export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/login' }),
    endpoints: (builder) => ({
        login: builder.mutation<ResultType<User>, { account: string, password: string }>({
            query: ({ ...patch }) => ({
                url: `login`,
                method: 'POST',
                body: patch,
            }),
        }),
        logout: builder.mutation<ResultType<null>, void>({
            query: () => `logout`,
        }),
        loginWithGoogle: builder.mutation<ResultType<User>, profileObj>({
            query: ({ ...patch }) => ({
                url: `googleLogin`,
                method: 'POST',
                body: patch,
            }),
        }),
        register: builder.mutation<ResultType<UserId>, { account: string, password: string }>({
            query: ({ ...patch }) => ({
                url: `register`,
                method: 'POST',
                body: patch,
            }),
        }),
        forgotPassword: builder.mutation<ResultType<null>, string>({
            query: (email) => ({
                url: `forgotPassword`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: email
            }),
        }),
        verify: builder.mutation<ResultType<User>, { userId: string, code: string }>({
            query: ({ ...patch }) => ({
                url: `verify`,
                method: 'POST',
                body: patch,
            }),
        }),
        verifyHash: builder.mutation<ResultType<User>, { userId: string, hash: string }>({
            query: ({ ...patch }) => ({
                url: `verifyHash`,
                method: 'POST',
                body: patch,
            }),
        }),
        checkUser: builder.mutation<ResultType<null>, string>({
            query: (userId) => ({
                url: `checkUser`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: userId
            }),
        }),
        reverify: builder.mutation<ResultType<null>, string>({
            query: (userId) => ({
                url: `reverify`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: userId
            }),
        }),
        checkResetPassword: builder.mutation<ResultType<null>, { userId: string, hash: string }>({
            query: ({ ...patch }) => ({
                url: `checkUserResetPw`,
                method: 'POST',
                body: patch,
            }),
        }),
        resetPassword: builder.mutation<ResultType<null>, { userId: string, hash: string, newPassword: string }>({
            query: ({ ...patch }) => ({
                url: `resetPassword`,
                method: 'POST',
                body: patch,
            }),
        }),
        getIsLogin: builder.query<ResultType<User>, void>({
            query: () => `state`,
        }),
        guestLogin: builder.mutation<ResultType<null>, void>({
            query: () => ({
                url: `guestLogin`,
                method: 'POST',
            }),
        }),
        bindGoogleAccount: builder.mutation<ResultType<null>, profileObj>({
            query: (patch) => ({
                url: `bind/google`,
                method: 'POST',
                body: patch,
            }),
        }),
    })
});

export const { useLoginMutation } = loginApi;
export const { useLogoutMutation } = loginApi;
export const { useLoginWithGoogleMutation }= loginApi;
export const { useRegisterMutation } = loginApi;
export const { useForgotPasswordMutation }= loginApi;
export const { useVerifyMutation } = loginApi;
export const { useVerifyHashMutation }= loginApi;
export const { useCheckUserMutation } = loginApi;
export const { useReverifyMutation } = loginApi;
export const { useResetPasswordMutation } = loginApi;
export const { useCheckResetPasswordMutation } = loginApi;
export const { useGetIsLoginQuery } = loginApi;
export const { useLazyGetIsLoginQuery } = loginApi;
export const { useGuestLoginMutation } = loginApi;
export const { useBindGoogleAccountMutation } = loginApi;
