import { useTheme, Box, Container, Paper, Typography, Stack, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";
import { GroupList } from "../../components/GroupList";

export function Tab3() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
   
 
    return (<>
        <SubTitle title="相關表單下載" />
        <br />
        <Stack direction='column' spacing={1 }>
        <Link href='pdfs/中華學生事務學會會員入會資料表.doc' target='_blank'>1.會員入會資料表</Link>
        <Link href='pdfs/【附件】112會費劃撥單.pdf' target='_blank'>2.郵政劃撥單</Link>
        </Stack>
    </>)


}


