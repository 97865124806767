import { Circle } from "@mui/icons-material";
import { Typography, styled, TypographyProps, IconProps, SvgIconProps, Box, Link, LinkProps, ButtonProps, Divider } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router";

export const important = <Typography component="span" sx={{ color: 'error.light' }}>*</Typography>;
export const ErrorText: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));
export const Title: (props: TypographyProps) => JSX.Element = styled(Typography)({
    padding: 10,
    fontWeight: 'bold',
    color: '#545569',
    background: 'linear-gradient(to right, #C1DFE1, #FFFCE2)',
});

export const DividerText: (props: DividerTextProps) => JSX.Element = (props) => (
    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
        <Typography fontWeight='bold' sx={{ color: theme => theme.palette.primary.main }}>{props.text}</Typography>
        <Divider sx={{ flexGrow: 1, margin: '0px 20px', borderColor: theme => theme.palette.primary.main }} />
    </Box>
)
interface DividerTextProps {
    text?: string;
}

export const SubTitle: (props: SubTitleProps) => JSX.Element = (props) => {
    const history = useHistory();
    function handleClickLink(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        history.push(props.link)
	}

    return (<>
        <div style={{ display: 'flex', alignItems: 'center', padding: '12px 0px 12px 0px', paddingBottom: props.subText ? '0px' :'12px', ...props.style }} className={props.className}>
            {props.icon === 'circle' ? <Circle fontSize='small' sx={{ color: (theme) => theme.palette.primary.main }} {...props.iconProps} /> :
                props.icon === 'square' ? <Box sx={{ width: '20px', height: '20px', backgroundColor: (theme) => theme.palette.primary.main, ...props.iconProps?.sx }} style={props.iconProps?.style} /> :
                    props.icon
            }
            {props.icon &&
                <span style={{ width: '12px' }} />
            }
            <Typography fontWeight='bold' variant='h6' {...props.textProps}>
                {props.text}
            </Typography>
            {(props.link && props.linkText) &&
                <Link component="button" onClick={handleClickLink} href={props.link} variant='subtitle2' style={{ marginLeft: 'auto', ...props.linkStyle }}>
                    {props.linkText}
                </Link>
            }
        </div>
        {props.subText &&
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '12px', ...props.style }} className={props.className}>
                {props.icon &&
                    <span style={{ width: '20px' }} />
                }
                {props.icon &&
                    <span style={{ width: '12px' }} />
                }
                <Typography variant='subtitle1' {...props.subTextProps}>
                    {`${props.subText}`}
                </Typography>
            </div>
		}
    </>)
}
interface SubTitleProps {
    icon?: iconList | JSX.Element;
    iconProps?: SvgIconProps;
    text?: string;
    textProps?: TypographyProps;
    subText?: string;
    subTextProps?: TypographyProps;
    link?: string;
    linkText?: string;
    linkStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    className?: string;
}
type iconList = 'circle' | 'square';