import { Box, Button, styled, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PublicationManage from "./PublicationManage";

export default function PublicationIndex() {
    const { t } = useTranslation();
    const query = new URLSearchParams(useLocation().search);
    const [code, setCode] = React.useState<string>(query.get("e"));
    React.useEffect(() => { setCode(query.get("e")) }, [query.get("e")]);

    if (code == "0" || code == "1")
        return (
            <PublicationManage eventType={Number(code)} />
        )
    else return (<>
        <Box display="flex">
       期刊管理
        </Box>
    </>)
}

const BigButton = styled(Button)(({ theme }) => ({

}));