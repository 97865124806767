import {
    Box, Button, Divider, IconButton, InputAdornment, Link, Stack, Tab, Tabs, TextField, Typography, useTheme
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useDispatchWithType, useUser } from '../store';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubTitle } from "../components/Common";
import { UserData, UserType } from "../components/class";
import { useLoginMutation, useLogoutMutation } from '../components/services/login';
import { useLazyGetUserQuery } from '../components/services/user';
import { loginUser, logoutUser } from '../store/rootReducer';
import { deleteAllCookie } from '../cookie-utils';

export enum loginType {
    個人會員 = 0,
    團體會員 = 1,
}
export type formData = {
    account: string;
    password: string;
}
export function LoginBox() {
    const history = useHistory();
    const user = useUser();
    const dispatch = useDispatchWithType();
    const theme = useTheme();
    const { t } = useTranslation();
    let query = new URLSearchParams(useLocation().search);

    const [login, { isLoading }] = useLoginMutation();
    const [logout, { isLoading: logoutLoading }] = useLogoutMutation();
    const [getUserData, { }] = useLazyGetUserQuery();

    const [userData, setUserData] = React.useState<UserData>();

    React.useEffect(() => {
        if (user) {
            getUserData(user.userId).unwrap().then(result => {
                if (result.isSuccess) {
                    setUserData(result.data)
                }
                else {
                    console.log(result.message)
                }
            })
        }
    }, []);

    const [filterType, setFilterType] = React.useState<loginType>(0);
    function handleChange(event: React.SyntheticEvent, newValue: any) {
        setFilterType(newValue);
    };

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword(show => !show);
    }

    const [errorMessage, setErrorMessage] = React.useState<JSX.Element>();

    const schema = yup.object().shape({
        account: yup.string().required(t('login.account.error')),
        password: yup.string().required(t('login.password.error')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { account: "", password: "" }
    });

    function onLoginSubmit(data: { account: string, password: string }) {
        setErrorMessage(<></>);
        login(data).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    getUserData(result.data.userId).unwrap().then(result => {
                        if (result.isSuccess) {
                            setUserData(result.data)
                        }
                        else {
                            console.log(result.message)
                        }
                    })
                    dispatch(loginUser(result.data));
                    if (query.get('redirect')) {
                        history.push(query.get('redirect'));
                    }
                    else { history.push('/'); }
                }
                else {
                    if (result.message == "此帳號已被停權") { setErrorMessage(<>此帳號已被停用，如有疑問請聯絡管理員。<br /> <Link href='mailTo:a0989015018@gmail.com'>a0989015018@gmail.com</Link></>) } else setErrorMessage(<>登入失敗：帳號密碼或驗證碼錯誤</>);
      }
            }).catch((error) => {
                // 後端報錯 ex:500、404
                setErrorMessage(<>Unconnect</>);
                console.error("發生錯誤", error)
            });
    }

    function handleLogout() {
        logout().unwrap().then(result => {
            if (result.isSuccess) {
                dispatch(logoutUser());
                deleteAllCookie();
            }
            else {
                console.log(result.message);
            }
        })
    }

    // 跳轉 
    const handleClickLink = (link: string) => {
        history.push(link);
    }

    return (<>
        <Box sx={{ width: { xs: "95%", md: "25vw" }, height: "auto", backgroundColor: "background.paper", borderRadius: "12px", my: 3, }}>
            <Box sx={{ m: 2 }}>
                <SubTitle title="會員登入" />
            </Box>
            <Divider />

            {!user ? <>
                <Tabs value={filterType} onChange={handleChange} centered
                    textColor="secondary" indicatorColor="secondary" sx={{ my: 1 }}>
                    <Tab value={loginType.個人會員} label="個人會員"
                        sx={{ fontSize: "18px", mx: 1 }}
                    />
                    <Tab value={loginType.團體會員} label="團體會員"
                        sx={{ fontSize: "18px", mx: 1 }}
                    />
                </Tabs>

                <Stack component="form" onSubmit={handleSubmit(onLoginSubmit)}
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <TextField
                        variant="outlined"
                        placeholder={filterType == loginType.個人會員 ? "個人會員帳號" : "團體會員帳號"}
                        inputRef={register('account').ref}
                        error={!!errors.account}
                        helperText={errors.account ? errors.account.message : ' '}
                        {...register("account")}

                        FormHelperTextProps={{ style: { position: 'absolute', bottom: '0.5px', right: '40px' } }}
                        disabled={isLoading}
                        sx={{
                            m: 2, width: "80%", height: "40px",
                            "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
                        }}
                    />
                    <TextField
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="密碼"
                        inputRef={register('password').ref}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ' '}
                        {...register("password")}

                        FormHelperTextProps={{ style: { position: 'absolute', bottom: '0.5px', right: '40px' } }}
                        disabled={isLoading}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end" className="ml-1">
                                    <IconButton onClick={handleClickShowPassword}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        sx={{
                            m: 2, width: "80%", height: "40px",
                            "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
                        }}
                    />
                    <LoadingButton variant="contained" type="submit" loading={isLoading}
                        sx={{ width: "80%", height: "45px", m: 2 }}>
                        登入
                    </LoadingButton>

                    {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
                    { /* <Typography color="text.highlight2" sx={{ my: 2 }} >忘記密碼？</Typography> */}
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", my: 3 }}>
                        <Button variant="outlined" sx={{ width: "100%", height: "35px", mx: 1, color: "primary.light" }}
                            onClick={() => handleClickLink("/ApplyMember")}
                        >
                            申請會員
                        </Button>
                        <Button variant="outlined" sx={{ width: "100%", height: "35px", mx: 1, color: "primary.light" }}
                            onClick={() => handleClickLink("/MemberService")}
                        >
                            入會辦法
                        </Button>
                    </Box>
                </Stack>
            </>
                :
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography fontSize="18px" sx={{ my: 2 }}>{user.name}，您好</Typography>
                    <Typography color="text.subText">身分：{UserType[userData?.type]}</Typography>
                    <Typography color="text.subText">會員ID：{user.userId}</Typography>
                    <LoadingButton variant="text" loading={logoutLoading} onClick={handleLogout} sx={{ my: 1, color: "text.highlight2" }}>登出</LoadingButton>
                </Box>
            }
        </Box>
    </>)
}