import { useTheme, Box, Container, Paper, Typography, Stack, Pagination, Button, CircularProgress, Link } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'
import { SubTitle } from "../../components/Common";
import { EventType, Event, EventDetail } from "../../components/class";
import { useGetEventDetailQuery, useGetEventListQuery, useLazyGetEventDetailQuery } from "../../components/services/event";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export function EventContent(props: { id: string }) {
    const { id } = props;
    const history = useHistory();
    const [getEventDetail, { isLoading, isSuccess }] = useLazyGetEventDetailQuery()
    const [data, setData] = React.useState<EventDetail>()
    React.useEffect(() => {
        getEventDetail(id).unwrap().then(res => {
            if (res.isSuccess) setData(res.data)
        })
    }, [id])

    const fileName = (url:string) => {
        let s = url.replace('attachment/', '').replace(/\.[^/.]+$/, "");
        return s.substring(0, s.length - 15)       
    }

    return <>{data&&< Stack direction = "column" spacing = { 2} >
        <Stack direction="column" sx={{ "width": "100%", "borderBottom": "1px solid ", borderColor: 'background.dividing' }} >
            <Stack direction="row" spacing={1} alignItems='center'>
                <Typography variant='body1' bgcolor='text.highlight2' color='#fff' borderRadius='2px' padding='2px 10px'>{EventType[data.type]}</Typography>
                <Typography variant='body1' color="secondary.main">{new Date(data.createTime).toLocaleDateString()}</Typography>
            </Stack>
            <Typography variant='h5' color="32425F" margin="5px">{data.title}</Typography>
        </Stack>
        {/*   <Box>
            <img
                src={data.imageUrl}
                alt={data.title}
                style={{ cursor: 'pointer', maxWidth: '100%' }}
            />
        </Box>*/}
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
        <Stack spacing={2 }>
            {data?.imageUrls?.map(url => <img
                src={url}
                alt={url}
                key={url }
            />)}
        </Stack>
        <Stack spacing={2}>
            {data?.filesUrl?.map((url,index) => <Link
                href={url}
                key={url}
                target='_blank'
            >
                {fileName(url)}
            </Link>)}
        </Stack>
        <Button variant='contained' sx={{ backgroundColor: 'text.theme3', width: '100px' }} onClick={() => { history.goBack() }}>回列表</Button>
    </Stack >}</>
}