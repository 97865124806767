import { Backdrop, Box, BoxProps, Button, Card, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, Paper, Stack, styled, SvgIcon, TextField, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PageTitle } from "../components/Common";
import { TreeView } from "@mui/lab";
import { PageSidbar, SibarItem } from "../components/PageSidbar";
import { Tab1of1 } from './tabs/Tab1of1';
import { Tab1of2 } from './tabs/Tab1of2';
import { Tab1of3 } from './tabs/Tab1of3';
import { Tab2 } from './tabs/Tab2';
import { Tab3 } from './tabs/Tab3';
import { Tab4 } from './tabs/Tab4';
import { Tab5 } from './tabs/Tab5';

export function MemberService() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const [nodeId, setNodeId] = React.useState<string>("1-1")

    const sibarItem: SibarItem[] = [{
        nodeId: "1",
        title: "申請入會",
        childs: [{
            nodeId: "1-1",
            title: "會員種類與會費"
        }, {
            nodeId: "1-2",
            title: "會費繳款方式"
        }, {
            nodeId: "1-3",
            title: "團體入會方式"
        }, 
        ]
    }, {
        nodeId: "2",
        title: "各類辦法",
    }, {
        nodeId: "3",
        title: "相關表單下載",
    }, {
        nodeId: "4",
        title: "常見問題",
    }, {
        nodeId: "5",
        title: "聯絡我們",
    },
    ]

 


    const handleSelect = (event: React.SyntheticEvent, nodeId: string) => {
        if (nodeId != '1') {
            setNodeId(nodeId)
        }
    };

    return (<>
        <PageTitle title={sibarItem.find(e => e.nodeId == nodeId || e.childs?.find(i => i.nodeId == nodeId)).title} subtitle="會員服務" />

        <Container sx={{ marginTop: "30px" }}>
            <Stack
                direction={{ xs: "column", md: "row" }}
            >
                <Paper sx={{ width: 250, padding: "50px 20px", height: "max-content" }}>
                    <TreeView
                        aria-label="navigator"
                        sx={{ "& .MuiTreeItem-content": { padding: "5px 10px" } }}
                        onNodeSelect={handleSelect}
                        defaultExpanded={['1']}
                        selected={nodeId }

                    >
                        <PageSidbar sibarItem={sibarItem} />
                    </TreeView>
                </Paper>
                <Box sx={{ width: { sm: "100%", md: "calc(100% - 200px)" }, }}>
                    <Tabs nodeId={nodeId} />
                </Box>
            </Stack>
        </Container>
    </>)
}

function Tabs(props: { nodeId: string }) {
    const { nodeId } = props;
    return (
        <Box sx={{ width: '100%' }}>
            <TabPanel value={nodeId} index={"1-1"}>
                <Tab1of1 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-2"}>
                <Tab1of2 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-3"}>
                <Tab1of3 />
            </TabPanel>
            <TabPanel value={nodeId} index={"2"}>
                <Tab2 />
            </TabPanel>
            <TabPanel value={nodeId} index={"3"}>
                <Tab3 />
            </TabPanel>
            <TabPanel value={nodeId} index={"4"}>
                <Tab4 />
            </TabPanel>
            <TabPanel value={nodeId} index={"5"}>
                <Tab5 />
            </TabPanel>
        </Box>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
