import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User, UserRole } from '../class'
import { ResultType } from './utils';

export interface UserSignData {
    id: string;
    level: UserRole;
    name: string;
    time: string;
}

//通用api
const baseApi = createApi({
    reducerPath: 'systemApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/system' }),
    endpoints: (builder) => ({
        getIsSignIn: builder.query<ResultType<boolean>, string>({
            query: (userId) => `signin/${userId}`,
        }),
        signIn: builder.mutation<ResultType<null>, string>({
            query: (userId) => ({
                url: `signin/${userId}`,
                method: 'PUT',
            }),
        }),
        redeem: builder.mutation<ResultType<null>, string>({
            query: (code) => ({
                url: `redeemCode`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: code
            }),
        })
    })
});

//superuser或admin用api
export const systemApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserSignIn: builder.query<ResultType<string[]>, string>({
            query: (userId) => `signin/user/${userId}`,
        }),
        getDateInSign: builder.query<ResultType<string[]>, void>({
            query: () => `signin/date`,
        }),
        getSignInList: builder.query<ResultType<UserSignData[]>, string>({
            query: (date) => `signin/list/${date}`,
        }),
    }),
    overrideExisting: false
});

export const { useGetIsSignInQuery } = systemApi;
export const { useGetUserSignInQuery } = systemApi;
export const { useLazyGetIsSignInQuery } = systemApi; //Lazy
export const { useSignInMutation } = systemApi;
export const { useRedeemMutation } = systemApi;
//superuser或admin用api
export const { useGetDateInSignQuery } = systemApi;
export const { useLazyGetSignInListQuery } = systemApi;