import * as React from 'react';
import { Backdrop, Box, Button, CircularProgress, Dialog, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfirmButton, TextField } from '../../components/responsive-components';
import { useChangeUserPasswordMutation } from '../services/user';

export interface ChangePasswordEditState {
 //   oldPassword: string,
    newPassword: string,
    confirmPassword: string
}
interface EditUserPasswordProps { userId: string; }
export default function EditUserPassword(props: EditUserPasswordProps) {

    const { t } = useTranslation();
    const theme = useTheme();
    const [changePWValues, setChangePWValues] = React.useState<ChangePasswordEditState>({
       // oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const editChangePWValues = (event) => {
        setChangePWValues({ ...changePWValues, [event.target.name]: event.target.value });
    };

    const [errorText, setErrorText] = React.useState<string>();

    const [editPassword] = useChangeUserPasswordMutation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const handleChangePassword = async (): Promise<boolean> => {
        //檢查新密碼與密碼確認是否相同
        if (changePWValues.newPassword != changePWValues.confirmPassword) {
            setErrorText("密碼與密碼確認不符合。");
            return;
        }
        //去除判斷空值狀態
        if (Object.keys(changePWValues).some(x => changePWValues[x].trim() === "")) {
            setErrorText("密碼不得為空");
            return;
        }

        setErrorText(null);

        try {
            setIsLoading(true);
            let result = await editPassword({ userId: props.userId, password: changePWValues.newPassword }).unwrap();
            if (!result.isSuccess)
                throw `編輯失敗：${result.message}`;
            setChangePWValues({
               // oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            });
            setOpenDialog(true);
            setDialogMessage("編輯成功");
        }
        catch (e) {
            setOpenDialog(true);
            setDialogMessage(e as string);
        }
        finally {
            setIsLoading(false);
        }
    }

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    return (<>
        <Typography variant='h6' fontWeight='bold'>
            修改密碼
        </Typography>
        <Stack spacing={1}>
            { /* <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end" sx={{ maxWidth: '510px' }}>
                <Typography variant='subtitle1'>
                    輸入舊密碼 <span style={{ color: theme.palette.text.danger }}>*</span>
                </Typography>
                <TextField
                    variant='outlined'
                    type="password"
                    name='oldPassword'
                    value={changePWValues.oldPassword}
                    onChange={editChangePWValues}
                    disabled={isLoading}
                />
            </Box>
            */}
            <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end" sx={{ maxWidth: '510px' }}>
                <Typography variant='subtitle1'>
                    新的密碼 <span style={{ color: theme.palette.text.danger }}>*</span>
                </Typography>
                <TextField
                    variant='outlined'
                    type="newPassword"
                    name='newPassword'
                    value={changePWValues.newPassword}
                    onChange={editChangePWValues}
                    disabled={isLoading}
                    size='small'
                />
            </Box>
            <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end" sx={{ maxWidth: '510px' }}>
                <Typography variant='subtitle1'>
                    再次輸入新密碼 <span style={{ color: theme.palette.text.danger }}>*</span>
                </Typography>
                <TextField
                    variant='outlined'
                    type="confirmPassword"
                    name='confirmPassword'
                    value={changePWValues.confirmPassword}
                    onChange={editChangePWValues}
                    disabled={isLoading}
                    size='small'

                />
            </Box>

            <Typography color="error.main">
                {t(errorText || " ")}
            </Typography>
        </Stack>

        <ConfirmButton onClick={handleChangePassword} loading={isLoading}>
            修改密碼
        </ConfirmButton>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
    </>)
};