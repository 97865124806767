import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ResultType } from './utils';

export interface MultiUploadResult {
    count: number;
    size: number;
    urls: string[];
}

export interface SingleUploadResult {
    size: number;
    url: string;
}

export const baseApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        uploadPublicationPdf: builder.mutation<ResultType<SingleUploadResult>, FormData>({
            query: (formData) => ({
                url: `upload/publication`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadPublicationImage: builder.mutation<ResultType<SingleUploadResult>, FormData>({
            query: (formData) => ({
                url: `upload/publication/image`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadNewsImage: builder.mutation<ResultType<MultiUploadResult>, FormData>({
            query: (formData) => ({
                url: `upload/news/image`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadEventImage: builder.mutation<ResultType<MultiUploadResult>, FormData>({
            query: (formData) => ({
                url: `upload/event/image`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadAttachment: builder.mutation<ResultType<MultiUploadResult>, FormData>({
            query: (formData) => ({
                url: `upload/attachment`,
                method: 'POST',
                body: formData
            }),
        }),
        
        uploadUserImage: builder.mutation<ResultType<SingleUploadResult>, { userId: string, formData: FormData }>({
            query: ({ userId, formData }) => ({
                url: `upload/user/${userId}`,
                method: 'POST',
                body: formData
            }),
        }),
    }),
})

//exhibitor或superuser或admin用api
export const fileApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
    }),
    overrideExisting: false
});

export const { useUploadUserImageMutation } = fileApi;
export const { useUploadPublicationPdfMutation } = fileApi;
export const { useUploadPublicationImageMutation } = fileApi;
export const { useUploadNewsImageMutation } = fileApi;
export const { useUploadEventImageMutation } = fileApi;
export const { useUploadAttachmentMutation } = fileApi;