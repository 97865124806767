import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, FormControl, FormControlLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import zhTW from "date-fns/locale/zh-TW";
import * as React from "react";
import { Controller, FormState, useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import * as yup from "yup";
import { NewsType, UserRole, UserRoleText, UserType } from "components/class";
import { ConfirmButton, important, TextField } from "components/responsive-components";
import {  EditUserModel, useEditUserMutation, useLazyGetUserDetailQuery } from "../../components/services/user";
import { AddButton, OutlinedButton } from "../components";
import {  useAddNewsMutation, useGetNewsDetailQuery, useLazyGetNewsDetailQuery } from "../../components/services/news";
import { DropzoneRef } from "react-dropzone";
import { UploadWithAttachments, UploadWithImg, UploadWithImgs, UploadWithPdf } from "../../components/responsive-components/Upload";
import { Upload } from '@mui/icons-material';
import { useUploadAttachmentMutation, useUploadNewsImageMutation } from "../../components/services/file";
import TextEditor from "../../components/CKEditor/TextEditor";
import EditUserPassword from "../../components/UserManage/EditUserPassword";

//import { AddUserModel, useAddUserMutation } from "components/services/user";

interface EditUserPageProps {
    onBack: () => void;
    complete: () => void;
    userId: string;
}
export function EditUserPage(props: EditUserPageProps) {
    const { onBack, complete, userId } = props;
    function handleComplete() {
        complete()
    }
    return (<>
        <Box display='flex'>
            <OutlinedButton onClick={onBack} sx={{ ml: '24px' }}>回到會員列表</OutlinedButton>
        </Box>
        <EditUser onComplete={handleComplete} userId={userId} />
    </>);
}


interface EditUserProps {
    onComplete: () => void;
    userId: string;

}
function EditUser(props: EditUserProps) {
    const { onComplete, userId } = props;
    const [editUser, { isLoading }] = useEditUserMutation();
    const [getUserDetail, { isLoading: isGetUserDetailLoading, isSuccess: isGetUserDetailSuccess, isFetching }] = useLazyGetUserDetailQuery();
    const [account, setAccount] = React.useState('')
  
    React.useEffect(() => {
        if (userId) {
            getUserDetail(userId).then(res => {
                if (res.isSuccess) {
                    let data = res.data.data;
                    reset(data)
                    console.log(data)
                    setAccount(data.account)
                }
            })
        }
    }, [userId])
    const schema = yup.object().shape({
        name: yup.string().trim().required('必填'),
        type: yup.number().required('必填'),
        gender: yup.string().trim().required('必填'),
        birthday: yup.date().typeError("日期格式錯誤"),
        telephone: yup.string().trim().required('必填'),
        mobilephone: yup.string().matches(/^09[0-9]{8}$/, { message: "手機格式錯誤", excludeEmptyString: false }).required('必填'),
        fax: yup.string().trim(),
        email: yup.string().email('信箱格式錯誤').trim().required('必填'),
        job: yup.string().trim(),
        experience: yup.string().trim(),
        address: yup.string().trim().required('必填'),
        comment: yup.string().trim(),
    });
    const { register, handleSubmit, formState: { errors }, control, setValue, formState, watch, reset } = useForm<EditUserModel>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: {
            role: UserRole.normal,
            birthday: new Date(),
            type: UserType.一般會員,
            name: '',
            gender: '',
            telephone: '',
            mobilephone: '',
            fax: '',
            email: '',
            job: '',
            experience: '',
            address: '',
            comment: '',
        }
    });

    const [isComplete, setIsComplete] = React.useState<boolean>(false);
    async function onSubmit(data: EditUserModel) {
        try {
            var result = await editUser({ ...data, userId:userId }).unwrap();
            if (result.isSuccess) {
                setDialogMessage("編輯成功");
                setOpenDialog(true);
                setIsComplete(true);
            }
            else {
                setDialogMessage(result.message);
                setOpenDialog(true);
            }
        }
        catch (e: any) {
            if (e.status == 401) {
                setDialogMessage("未登入，請重新整理頁面並登入。");
            } else setDialogMessage("發生問題");
            setOpenDialog(true);
        }
    }
    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    function handleDialogClose() {
        setOpenDialog(false);
        if (isComplete)
            onComplete();
    }

    //確認密碼
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');


    return (<>{!isGetUserDetailLoading && !isFetching ?
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ marginTop: '20px' }}>
            <Stack spacing={1} width='max-content'>
                <Box>
                    <Typography variant='subtitle1'>
                        帳號 
                    </Typography>
                    {account}
                </Box>
                <EditUserPassword userId={userId} />
                <Box>

                    <Typography variant='subtitle1'>
                        {watch('type') == 0 ? "姓名" : "聯絡人"}  {important}
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('name').ref}
                        fullWidth
                        size="small"
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("name")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        性別 {important}
                    </Typography>
                    <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={field?.value?.toString()}
                                    onChange={(gender) => {
                                        field.onChange(gender.target.value)
                                    }}
                                    error={!!errors.gender}
                                    sx={{ '& legend': { display: 'none' } }}

                                >
                                    {['男性', '女性'].map(gender => <MenuItem value={gender} key={gender}>{gender}</MenuItem>)}

                                </Select>
                            </FormControl>
                        )}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        權限 {important}
                    </Typography>
                    <Controller
                        name="role"
                        control={control}
                        render={({ field }) => (
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={field.value.toString()}
                                    onChange={(role) => {
                                        field.onChange(Number(role.target.value))
                                    }}
                                    error={!!errors.role}
                                    sx={{ '& legend': { display: 'none' } }}
                                >
                                    {Object.keys(UserRole).filter(type => !Number.isNaN(Number(type)) && type != '0' && type != '3').map(type => <MenuItem value={type} key={type}>{UserRoleText[type]}</MenuItem>)}

                                </Select>
                            </FormControl>
                        )}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        身分 {important}
                    </Typography>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={field.value.toString()}
                                    onChange={(type) => {
                                        field.onChange(Number(type.target.value))
                                    }}
                                    error={!!errors.type}
                                    sx={{ '& legend': { display: 'none' } }}
                                >
                                    {Object.keys(UserType).filter(type => !Number.isNaN(Number(type))).map(type => <MenuItem value={type} key={type}>{UserType[type]}</MenuItem>)}

                                </Select>
                            </FormControl>
                        )}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        生日
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                        <Controller
                            name="birthday"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    inputFormat="yyyy/MM/dd"
                                    mask="____/__/__"
                                    renderInput={(params) => <TextField
                                        {...params}
                                        error={!!errors.birthday}
                                        helperText={errors.birthday ? errors.birthday.message : ''}
                                    />}
                                    value={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    disabled={isLoading}
                                />
                            )}
                        />
                    </LocalizationProvider >
                </Box>
                <Box>

                    <Typography variant='subtitle1'>
                        聯絡電話 {important}
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('telephone').ref}
                        size="small"
                        fullWidth
                        error={!!errors.telephone}
                        helperText={errors.telephone ? errors.telephone.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("telephone")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>

                    <Typography variant='subtitle1'>
                        手機 {important}
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('mobilephone').ref}
                        size="small"
                        fullWidth
                        error={!!errors.mobilephone}
                        helperText={errors.mobilephone ? errors.mobilephone.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("mobilephone")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>

                    <Typography variant='subtitle1'>
                        傳真機
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('fax').ref}
                        size="small"
                        fullWidth
                        error={!!errors.fax}
                        helperText={errors.fax ? errors.fax.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("fax")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        電子信箱 {important}
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('email').ref}
                        type='email'
                        size="small"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("email")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        現職
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('job').ref}
                        size="small"
                        fullWidth
                        error={!!errors.job}
                        helperText={errors.job ? errors.job.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("job")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        經歷
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('experience').ref}
                        size="small"
                        fullWidth
                        error={!!errors.experience}
                        helperText={errors.experience ? errors.experience.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("experience")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        通訊地址 {important}
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('address').ref}
                        size="small"
                        fullWidth
                        error={!!errors.address}
                        helperText={errors.address ? errors.address.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("address")}
                        disabled={isLoading}
                    />
                </Box>
                <Box>
                    <Typography variant='subtitle1'>
                        註記
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('comment').ref}
                        size="small"
                        fullWidth
                        error={!!errors.comment}
                        helperText={errors.comment ? errors.comment.message : ''}
                        inputProps={{ maxLength: 320 }}
                        {...register("comment")}
                        disabled={isLoading}
                        multiline={true}
                    />
                </Box>
            </Stack>
            <br />
            <Button type='submit' variant='contained' >
                送出
            </Button>
        </Box> :
        <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
        }
        <Dialog open={openDialog} onClose={handleDialogClose}>{dialogContents}</Dialog>
    </>);
}

