import { Dialog, styled, TypographyProps } from "@mui/material";
import * as React from "react";
import { PublicationDetail, PublicationType, UserRole } from "components/class";
import { useUser } from "store";
import { PublicationList } from "./PublicationList";
import { useLocation } from "react-router";
import { useDeletePublicationMutation, useGetPublicationListQuery, useLazyGetPublicationDetailQuery } from "../../components/services/publication";
import { AddPublicationPage } from "./AddPublicationPage";
import { EditPublicatioPage } from "./EditPublicationPage";

type pageState = 'list' | 'add' | 'edit' | 'view';
interface PublicationManagePageProps {
    eventType: PublicationType;
}
export default function PublicationManage(props: PublicationManagePageProps) {
    const query = new URLSearchParams(useLocation().search);
    const [code, setCode] = React.useState<string>(query.get("e"));
    React.useEffect(() => { setCode(query.get("e")) }, [query.get("e")]);
    const user = useUser();
    const [pageState, setPageState] = React.useState<pageState>('list');
    const [publicationId, setPublicationId] = React.useState<string>('');
    const { data: publicationList, isLoading: isPublicationListLoading, refetch, isFetching } = useGetPublicationListQuery(Number(code));
    const [deletePublication, { isLoading: isDeletePublicationLoading }] = useDeletePublicationMutation();
    const [getPublicationDetail, { isLoading: isGetPublicationDetail }] = useLazyGetPublicationDetailQuery();

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        setPageState('list');
        refetch()
    }, [code])

    function handleAddPublication() {
        setPageState('add');
    }

    function handleEditPublication(publicationId: string){
        setPublicationId(publicationId)
        setPageState('edit');
    }

    function handleViewPublication(publicationId: string) {
        setPublicationId(publicationId)
        setPageState('view');
    }

    function handleDeletePublication(publicationId: string) {    
            try {
                deletePublication(publicationId).unwrap().then((data) => {
                    if (data.isSuccess) {
                        refetch();
                    }
                    else {
                        console.log(data.message);
                        setDialogMessage(`刪除失敗：${data.message}`);
                        setOpenDialog(true);
                    }
                });
            } catch (error) {
                console.log(error);
                setDialogMessage(`刪除失敗：${error}`);
                setOpenDialog(true);
            }
        
    }

    function handleBackToList() {
        setPageState('list');
    }

    function handleBackToListAndReftch() {
        setPageState('list');
        refetch()
    }

    if (!user || user.role < UserRole.administrator)
        return <></>;
    // else
    //     return <PublicationList onAdd={handleAddUser} onEdit={handleEditUser} onView={handleViewUser} isPublicationListLoading={isPublicationListLoading} isFetching={isFetching} dataList={publicationList} />;
    /*    if (!user || user.role < UserRole.administrator)
        return;
    else if (pageState =='add')
        return <AddUserPage onBack={handleBackToList} complete={handleBackToListAndReftch}/>;
    else if (pageState == 'edit')
        return <EditUserPage userId={userId} onBack={handleBackToList} complete={handleBackToListAndReftch}/>;
    else if (pageState == 'view')
        return <ShowUserPage userId={userId} userData={currentUserData } onBack={handleBackToList} />;*/
    else
        return(
            <>{pageState == 'add' ? <AddPublicationPage onBack={handleBackToList} complete={handleBackToListAndReftch} /> :
                pageState == 'edit' ? <EditPublicatioPage onBack={handleBackToList} complete={handleBackToListAndReftch} publicationId={publicationId} /> :
                <PublicationList
                    onAdd={handleAddPublication}
                    onEdit={handleEditPublication}
                    onView={handleViewPublication}
                    onDelete={handleDeletePublication }
                    isPublicationListLoading={isPublicationListLoading}
                    isFetching={isFetching}
                    publicationList={publicationList}
                    type={PublicationType[code]} />
                }
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>

            </>)
}

