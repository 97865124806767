import { useTheme, Box, Container, Paper, Typography, Stack, Pagination, Button, Link } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'

import { PageTitle, SubTitle } from "components/Common";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { object } from "yup";
import { NewsType, News, NewsDetail } from "components/class";
import { useGetNewsListQuery, useLazyGetNewsDetailQuery } from "../components/services/news";

export function NewsList() {
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const { t } = useTranslation();
    const [nodeId, setNodeId] = React.useState<NewsType>(NewsType.所有公告)
    const [newList, setNewList] = React.useState<News[]>([])
    const [page, setPage] = React.useState<number>(1)
    const perPageCount = 10;
    const sortList = newList.filter(n => nodeId == NewsType.所有公告 ? true : n.type == nodeId).sort((a, b) => { return a.top == true ? -1 : 0 })
    const pages = Math.ceil(sortList.length/perPageCount);
    const showList = sortList.filter((n, index) => index >= perPageCount * (page - 1) && index < perPageCount * page)
    const id = new URLSearchParams(location.search).get('id')
    const type = new URLSearchParams(location.search).get('type')
  
    //預留api query

    const { data, isLoading, isSuccess, refetch } = useGetNewsListQuery(nodeId)
    React.useEffect(() => {
        if (isSuccess) setNewList(data.data)
    }, [data])

    React.useEffect(() => {
        if (type) setNodeId(NewsType[type])
        if (data) refetch()
    }, [type])

    const handleSelect = (event: React.SyntheticEvent, nodeId: string) => {
        setNodeId(NewsType[nodeId])
        history.push('/News')
        setPage(1)

    };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (<>
        <PageTitle title={NewsType[nodeId]} subtitle="最新消息" />
        <Container sx={{ marginTop: "30px" }}>
            <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={2}
            >
                <Paper sx={{ width: 250, padding: "50px 20px", height: "max-content" }}>
                    <TreeView
                        aria-label="navigator"
                        sx={{ "& .MuiTreeItem-content": { padding: "5px 10px" } }}
                        onNodeSelect={handleSelect}
                        selected={NewsType[nodeId]}
                        
                    >{Object.keys(NewsType).filter(k => !isNaN(Number(k))).map(k => <TreeItem nodeId={NewsType[k]} key={k} label={NewsType[k]} sx={{ color: theme.palette.primary.main }} />)}
                    </TreeView>
                </Paper>
                <Box sx={{ width: { sm: "100%", md: "calc(100% - 200px)" }, }}>
                    {id ? 
                        <NewContent id={id} />
                        :
                        <>
                            <Stack direction="column" spacing={2}>
                            {showList.map(n => <NewItem data={n} key={n.newsId} />)}
                        </Stack>
                            <Stack spacing={2} mt="20px" >
                                <Pagination count={pages} page={ page} variant="outlined" shape="rounded" onChange={handleChange} />
                            </Stack>
                        </> 
                        }                 
                </Box>
            </Stack>
            <br/>
        </Container>
    </>)
}

export function NewItem(props: { data: News }) {
    const { data } = props;
    const history = useHistory();
    return <Stack direction="row" spacing={1}>
        <Stack direction="column" sx={{ "width": "100%", "borderBottom": "1px solid ", borderColor: 'background.dividing', paddingBottom:'10px' }} >
            <Stack direction="row" spacing={1.25} alignItems='center'>
                {data.top ? <Flag /> : <Box style={{ width: "18px", height: "18px" }} />}
                <Typography variant='body1' bgcolor='text.highlight2' color='#fff' borderRadius='2px' padding='2px 10px'>{NewsType[data.type]}</Typography>
                <Typography variant='body1' color="secondary.main">{new Date(data.createTime).toLocaleDateString()}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
                <Box style={{ width: "18px", height: "18px" }} />
                <Typography variant='body1' onClick={() => { history.push(`/News?id=${data.newsId}`) }} sx={{ cursor:"pointer" }}>{data.title}</Typography>
            </Stack>
        </Stack>
    </Stack>
}

function Flag(props) {
    return <img {...props} style={{ width: "18px", height:"18px" }} src='images/flag.svg' />
}

function NewContent(props: { id: string }) {
    const { id } = props;
    const history = useHistory();
    const [getNewsDetail, { isLoading, isSuccess }] = useLazyGetNewsDetailQuery();
    const [data, setData] = React.useState<NewsDetail>(null)
   React.useEffect(() => {
        getNewsDetail(id).unwrap().then(res => {
            if (res.isSuccess) setData(res.data)
    }
            )
   }, [id])

    const fileName = (url: string) => {
        let s = url.replace('attachment/', '').replace(/\.[^/.]+$/, "");
        return s.substring(0, s.length - 15)
    }
    return <>{data && <Stack direction="column" spacing={2}>
        <Stack direction="column" sx={{ "width": "100%", "borderBottom": "1px solid ", borderColor: 'background.dividing' }} >
            <Stack direction="row" spacing={1} alignItems='center'>
                <Typography variant='body1' bgcolor='text.highlight2' color='#fff' borderRadius='2px' padding='2px 10px'>{NewsType[data.type]}</Typography>
                <Typography variant='body1' color="secondary.main">{new Date(data.createTime).toLocaleDateString()}</Typography>
            </Stack>
            <Typography variant='h5' color="32425F" margin="5px">{data.title}</Typography>
        </Stack>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
        {data?.filesUrl?.map((file, index) => <Link href={file} key={index}>{fileName(file) }</Link>)}
        <Button variant='contained' sx={{ backgroundColor: 'text.theme3', width: '100px' }} onClick={() => { history.goBack() }}>回列表</Button>
    </Stack>}
    </>
}
