import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, MenuItem, Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import cryptoRandomString from "crypto-random-string";
import zhTW from "date-fns/locale/zh-TW";
import * as React from "react";
import { Controller, FormState, useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import * as yup from "yup";
import { PublicationType, UserRole } from "components/class";
import { ConfirmButton, important, TextField } from "components/responsive-components";
import { AddUserModel } from "../../components/services/user";
import { AddButton, OutlinedButton } from "../components";
import { PublicationModel, useAddPublicationMutation } from "../../components/services/publication";
import { DropzoneRef } from "react-dropzone";
import { UploadWithImg, UploadWithPdf } from "../../components/responsive-components/Upload";
import { Upload } from '@mui/icons-material';
import { useUploadPublicationPdfMutation, useUploadPublicationImageMutation } from "../../components/services/file";
import { useLocation } from "react-router";

//import { AddUserModel, useAddUserMutation } from "components/services/user";

interface AddPublicationPageProps {
    onBack: () => void;
    complete: () => void;
}
export function AddPublicationPage(props: AddPublicationPageProps) {
    const { onBack, complete} = props;
    function handleComplete() {
        complete()
    }
    return (<>
        <Box display='flex'>
            <OutlinedButton onClick={onBack} sx={{ ml: '24px' }}>回到期刊管理</OutlinedButton>
        </Box>
        <AddPublication onComplete={handleComplete} />
    </>);
}


interface AddPublicationProps {
    onComplete: () => void;
}
function AddPublication(props: AddPublicationProps) {
    const { onComplete } = props;
    const [addPublication, { isLoading }] = useAddPublicationMutation();
    const query = new URLSearchParams(useLocation().search);
    const type=Number(query.get("e"));

    const schema = yup.object().shape({
        title: yup.string().trim().required('必填'),
        subtitle: yup.string().trim().nullable(),
        publicationDate: yup.date().typeError("日期格式錯誤"),
        imageUrl: yup.string().trim().nullable(),
        pdfUrl: yup.string().trim().required('必填'),
        editor: yup.string().trim().nullable(),
        editor2: yup.string().trim().nullable(),
        editor3: yup.string().trim().nullable(),
    });
    const { register, handleSubmit, formState: { errors }, control, setValue, formState, watch } = useForm<PublicationModel>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: {
            publicationDate: new Date().toString(),
            subtitle: '',
            imageUrl: '',
            editor: '',
            editor2: '',
            editor3: '',
            type: type,
        }
    });

    const [isComplete, setIsComplete] = React.useState<boolean>(false);
    async function onSubmit(data: PublicationModel) {
        try {
            var result = await addPublication({ ...data }).unwrap();
               if (result.isSuccess) {
                   setDialogMessage("新增成功");
                   setOpenDialog(true);
                   setIsComplete(true);
               }
               else {
                   setDialogMessage(result.message);
                   setOpenDialog(true);
               }
        }
        catch (e: any) {
            if (e.status == 401) {
                setDialogMessage("未登入，請重新整理頁面並登入。");
            } else setDialogMessage("發生問題");
            setOpenDialog(true);
        }
    }

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    function handleDialogClose() {
        setOpenDialog(false);
        if (isComplete)
            onComplete();
    }

    return (<>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ marginTop: '20px' }}>
            <Stack spacing={1} width='max-content'>
                {type == PublicationType.學生事務與輔導季刊 &&<>              
            <Typography variant='subtitle1'>
                卷期 {important}
            </Typography>
            <TextField
                variant='outlined'
                inputRef={register('title').ref}
                size="small"
                error={!!errors.title}
                helperText={errors.title ? errors.title.message : ' '}
                inputProps={{ maxLength: 320 }}
                {...register("title")}
                disabled={isLoading}
            />
            <Typography variant='subtitle1'>
                季刊主題
            </Typography>
            <TextField
                variant='outlined'
                inputRef={register('subtitle').ref}
                size="small"
                error={!!errors.subtitle}
                helperText={errors.subtitle ? errors.subtitle.message : ' '}
                inputProps={{ maxLength: 100 }}
                {...register("subtitle")}
                disabled={isLoading}
            />
            <Typography variant='subtitle1'>
                  本期主編
            </Typography>
            <TextField
                variant='outlined'
                    inputRef={register('editor').ref}
                size="small"
                    error={!!errors.editor}
                    helperText={errors.editor ? errors.editor.message : ' '}
                inputProps={{ maxLength: 100 }}
                    {...register("editor")}
                disabled={isLoading}
            />
            <Typography variant='subtitle1'>
                    題目/作者/職稱
            </Typography>
            <TextField
                variant='outlined'
                    inputRef={register('editor2').ref}
                size="small"
                    error={!!errors.editor2}
                    helperText={errors.editor2 ? errors.editor2.message : ' '}
                inputProps={{ maxLength: 100 }}
                    {...register("editor2")}
                disabled={isLoading}
            />
            <Typography variant='subtitle1'>
                    題目/作者/職稱
            </Typography>
            <TextField
                variant='outlined'
                    inputRef={register('editor3').ref}
                size="small"
                    error={!!errors.editor3}
                    helperText={errors.editor3 ? errors.editor3.message : ' '}
                inputProps={{ maxLength: 100 }}
                    {...register("editor3")}
                disabled={isLoading}
                    /></>}
                {type == PublicationType.研討會簡報 && <>
                    <Typography variant='subtitle1'>
                        標題 {important}
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('title').ref}
                        size="small"
                        error={!!errors.title}
                        helperText={errors.title ? errors.title.message : ' '}
                        inputProps={{ maxLength: 320 }}
                        {...register("title")}
                        disabled={isLoading}
                    />
                    <Typography variant='subtitle1'>
                       刊物說明
                    </Typography>
                    <TextField
                        variant='outlined'
                        inputRef={register('subtitle').ref}
                        size="small"
                        error={!!errors.subtitle}
                        helperText={errors.subtitle ? errors.subtitle.message : ' '}
                        inputProps={{ maxLength: 100 }}
                        {...register("subtitle")}
                        disabled={isLoading}
                        multiline
                        rows={4}
                    />
                </>}
                <Typography variant='subtitle1'>
                發行日期
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                <Controller
                    name="publicationDate"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            inputFormat="yyyy/MM/dd"
                            mask="____/__/__"
                            renderInput={(params) => <TextField
                                {...params}
                                error={!!errors.publicationDate}
                                helperText={errors.publicationDate ? errors.publicationDate.message : ' '}
                            />}
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            disabled={isLoading}
                        />
                    )}
                />
                </LocalizationProvider >
                <AddImage register={register}
                    watch={watch}
                    setValue={setValue}
                    formState={formState}
                    setDialogMessage={setDialogMessage}
                    setOpenDialog={setOpenDialog}
                />
                <AddPdf
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    formState={formState}
                    setDialogMessage={setDialogMessage}
                    setOpenDialog={setOpenDialog}
                />
 <Typography color='error'> {errors.pdfUrl?.message}</Typography>
                   

            </Stack>
            <br />
            <Button type='submit' variant='contained' >
                新增期刊
            </Button>
        </Box>
        <Dialog open={openDialog} onClose={handleDialogClose}>{dialogContents}</Dialog>
    </>);
}


interface AddImageProps {
    register: UseFormRegister<PublicationModel>;
    watch: UseFormWatch<PublicationModel>;
    setValue: UseFormSetValue<PublicationModel>;
    formState: FormState<PublicationModel>;
    setDialogMessage: (string: string) => void;
    setOpenDialog: (boolean: boolean) => void;
}

function AddImage(props: AddImageProps) {
    const { register, watch, setValue, formState: { errors }, setDialogMessage, setOpenDialog } = props;

    //進行圖片上傳
    const [uploadPublicationImage, { isLoading: isUploading }] = useUploadPublicationImageMutation();
    const imageUrlValue = watch("imageUrl");
    const handleImageUrlChange = (url: string) => setValue("imageUrl", url);
    const [clearImage, setClearImage] = React.useState([]);

    //上傳圖片
    function handleUploadImageFile(file: File) {
        handleSubmit(file);
    }

    //圖片編輯按鈕
    const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    //上傳圖片
    const handleSubmit = async (uploadFile?: File): Promise<boolean> => {
        let tempResult = false
        if (uploadFile) {
            const formData = new FormData();
            formData.append("file", uploadFile);
            await uploadPublicationImage(formData).unwrap().then(async result => {
                if (result.isSuccess) {
                    let uri = result.data.url;
                    handleImageUrlChange(uri);
                }
            }).catch((error) => {
                if (error.status == 401) {
                    setOpenDialog(true)
                    setDialogMessage("未登入，請重新整理頁面並登入。");
                }

                tempResult = false;
            })
            return tempResult;
        }
    }

    return (
        <Stack spacing={1} paddingTop='12px' paddingBottom='12px'>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Button variant='outlined' onClick={openDialog}>
                    上傳圖片
                </Button>
           <Typography variant='subtitle1'>
                    ※建議尺寸為 332*368 px
                </Typography>
            </Stack>
            <Box position="relative" className="d-flex flex-column mt-2" fontSize='1rem' sx={{ width: 'fit-content' }}>
                <UploadWithImg
                    value={imageUrlValue} onChange={(file) => handleUploadImageFile(file)}
                    dropzoneRef={dropzoneRef}
                    width='100%' height='100%'
                    imgStyle={{ margin: "auto" }}
                    textCenter
                    clearImage={clearImage}
                >
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            color: "darkgray",
                            cursor: "pointer",
                            width: '166px',
                            height: '184px',
                            maxWidth: `100%`,
                            maxHeight: `100%`,
                        }}>
                        <Typography variant='h5' align="center">
                            <Upload />上傳圖片
                        </Typography>
                    </div>
                </UploadWithImg>
            </Box>

            {(isUploading) &&
                <Backdrop open sx={{ color: "#fff", zIndex: '99' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Stack>
    )
}


function AddPdf(props: AddImageProps) {
    const { register, watch, setValue, formState: { errors }, setDialogMessage, setOpenDialog } = props;

    //進行PDF上傳
    const [uploadPublicationPdf, { isLoading: isUploading }] = useUploadPublicationPdfMutation();
    const pdfUrlValue = watch("pdfUrl");
    const handlePdfUrlChange = (url: string) => setValue("pdfUrl", url);
    const [clearImage, setClearImage] = React.useState([]);

    //上傳PDF
    function handleUploadPdfFile(file: File) {
        handleSubmit(file);
    }

    //PDF編輯按鈕
    const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    //上傳PDF
    const handleSubmit = async (uploadFile?: File): Promise<boolean> => {
        let tempResult = false
        if (uploadFile) {

            const formData = new FormData();
            formData.append("file", uploadFile);
            await uploadPublicationPdf(formData).unwrap().then(result => {
                console.log(result)
                if (result.isSuccess) {
                    let uri = result.data.url;
                    handlePdfUrlChange(uri);
                }
            }).catch((error) => {
                if (error.status == 401) {
                    setOpenDialog(true)
                    setDialogMessage("未登入，請重新整理頁面並登入。");
                } else {
                    setOpenDialog(true)
                    setDialogMessage("檔案上傳失敗");
                }
                tempResult = false;
            })
            return tempResult;
        }
    }

    return (
        <Stack spacing={1} paddingTop='12px' paddingBottom='12px'>
            <Box display='flex'>
                <Button variant='outlined' onClick={openDialog}>
                    上傳PDF
                </Button>
            </Box>
            <Box position="relative" className="d-flex flex-column mt-2" fontSize='1rem' sx={{ width: 'fit-content' }}>
                <UploadWithPdf
                    value={pdfUrlValue} onChange={(file) => handleUploadPdfFile(file)}
                    dropzoneRef={dropzoneRef}
                    width='100%' height='100%'
                    imgStyle={{ margin: "auto" }}
                    textCenter
                    clearImage={clearImage}
                >
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            color: "darkgray",
                            cursor: "pointer",
                            width: '166px',
                            height: '184px',
                            maxWidth: `100%`,
                            maxHeight: `100%`,
                        }}>
                        <Typography variant='h5' align="center">
                            <Upload />上傳PDF
                        </Typography>
                    </div>
                </UploadWithPdf>
            </Box>

            {(isUploading) &&
                <Backdrop open sx={{ color: "#fff", zIndex: '99' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Stack>
    )
}

