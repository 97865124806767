import * as React from 'react';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';

export interface GroupData {
    gruop: string,
    list: { name: string, info: string }[],
    
}

export function GroupList(dataList: GroupData[]) {
    return <TableContainer component={Box} sx={{ "& td": { fontSize: { xs: "10px", md: "1rem" }, padding: "5px", minWidth: "60px" } }}>
        <Table aria-label="simple table" >
            <TableBody>
                {dataList.map(data => data.list.map((d, index) => (<TableRow key={index}>
                    {index == 0 ?
                        <>
                            <TableCell rowSpan={data.list.length}>{data.gruop}</TableCell>
                            <TableCell sx={{ border: data.list.length > 1 ? 0 : '' }}>{d.name}</TableCell>
                            <TableCell sx={{ border: data.list.length > 1 ? 0 : '' }}>{d.info}</TableCell>
                        </>
                        :
                        <>
                            <TableCell sx={{ border: index == data.list.length - 1 ? "" : 0 }}>{d.name}</TableCell>
                            <TableCell sx={{ border: index == data.list.length - 1 ? "" : 0 }}>{d.info}</TableCell>
                        </>}
                </TableRow>)))}
            </TableBody>
        </Table>
    </TableContainer>
}