import * as React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { amber, blue, blueGrey, brown, cyan, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from '@mui/material/colors';

export interface TextEditorProps {
    data?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
}

export default function TextEditor(props: TextEditorProps) {
    const { data, onChange, onBlur } = props;
    return (<>
        <CKEditor
            editorUrl="https://cdn.ckeditor.com/4.19.1/full-all/ckeditor.js"
            config={{
                toolbarGroups: [
                    { name: 'document', groups: ['mode', 'document', 'doctools'] },
                    { name: 'clipboard', groups: ['clipboard', 'undo'] },
                    { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                    { name: 'forms', groups: ['forms'] },
                    '/',
                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                    { name: 'links', groups: ['links'] },
                    { name: 'insert', groups: ['insert'] },
                    '/',
                    { name: 'styles', groups: ['styles'] },
                    { name: 'colors', groups: ['colors'] },
                    { name: 'tools', groups: ['tools'] },
                    { name: 'others', groups: ['others'] },
                    { name: 'about', groups: ['about'] }
                ],
                removeButtons: 'Source,Templates,Save,NewPage,PasteText,PasteFromWord,Scayt,SelectAll,HiddenField,ImageButton,Button,Select,Textarea,TextField,Radio,Checkbox,Form,CreateDiv,Language,BidiRtl,BidiLtr,ShowBlocks,Maximize,About',
                colorButton_colorsPerRow: 5,
                colorButton_colors: `${red[500].slice(1)},${pink[500].slice(1)},${purple[500].slice(1)},${deepPurple[500].slice(1)},${indigo[500].slice(1)},` +
                    `${red[900].slice(1)},${pink[900].slice(1)},${purple[900].slice(1)},${deepPurple[900].slice(1)},${indigo[900].slice(1)},` +
                    `${blue[500].slice(1)},${lightBlue[500].slice(1)},${cyan[500].slice(1)},${teal[500].slice(1)},${green[500].slice(1)},` +
                    `${blue[900].slice(1)},${lightBlue[900].slice(1)},${cyan[900].slice(1)},${teal[900].slice(1)},${green[900].slice(1)},` +
                    `${lightGreen[500].slice(1)},${lime[500].slice(1)},${yellow[500].slice(1)},${amber[500].slice(1)},${orange[500].slice(1)},` +
                    `${lightGreen[900].slice(1)},${lime[900].slice(1)},${yellow[900].slice(1)},${amber[900].slice(1)},${orange[900].slice(1)},` +
                    `${grey[100].slice(1)},${grey[300].slice(1)},${grey[500].slice(1)},${grey[700].slice(1)},${blueGrey[100].slice(1)},${blueGrey[300].slice(1)},${blueGrey[500].slice(1)},${blueGrey[700].slice(1)},${brown[500].slice(1)},${brown[900].slice(1)}`
                }}
            initData={data}
            onChange={({ editor }) => {
                const data = editor.getData();
                if (onChange) onChange(data);
                //console.log({ event, editor, data });
            }}
            onBlur={({ editor }) => {
                const data = editor.getData();
                if (onBlur) onBlur(data);
                //console.log({ event, editor, data });
            }}
        />
        { /*<CKEditor
            editor={Editor}
            //config={editorConfiguration}
            data={data}
            onReady={editor => {
                // You can store the "editor" and use when it is needed.
                //console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                if (onChange) onChange(data);
                //console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
                const data = editor.getData();
                if (onBlur) onBlur(data);
                //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
                //console.log('Focus.', editor);
            }}
        />*/}
    </>)
}