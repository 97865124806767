import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, FormControl, FormControlLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import zhTW from "date-fns/locale/zh-TW";
import * as React from "react";
import { Controller, FormState, useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import * as yup from "yup";
import { NewsType, UserRole } from "components/class";
import { ConfirmButton, important, TextField } from "components/responsive-components";
import { AddUserModel } from "../../components/services/user";
import { AddButton, OutlinedButton } from "../components";
import { NewsModel, useAddNewsMutation, useEditNewsMutation, useGetNewsDetailQuery, useLazyGetNewsDetailQuery } from "../../components/services/news";
import { DropzoneRef } from "react-dropzone";
import { UploadWithAttachments, UploadWithImg, UploadWithImgs, UploadWithPdf } from "../../components/responsive-components/Upload";
import { Upload } from '@mui/icons-material';
import { useUploadAttachmentMutation, useUploadNewsImageMutation } from "../../components/services/file";
import TextEditor from "../../components/CKEditor/TextEditor";

//import { AddUserModel, useAddUserMutation } from "components/services/user";

interface EditNewsPageProps {
    onBack: () => void;
    complete: () => void;
    newsId: string;
}
export function EditNewsPage(props: EditNewsPageProps) {
    const { onBack, complete, newsId } = props;
    function handleComplete() {
        complete()
    }
    return (<>
        <Box display='flex'>
            <OutlinedButton onClick={onBack} sx={{ ml: '24px' }}>回到消息列表</OutlinedButton>
        </Box>
        <EditNews onComplete={handleComplete} newsId={newsId} />
    </>);
}


interface EditNewsProps {
    onComplete: () => void;
    newsId: string;

}
function EditNews(props: EditNewsProps) {
    const { onComplete, newsId } = props;
    const [editNews, { isLoading }] = useEditNewsMutation();
    const [getNewsDetail,{ data, isLoading: isGetPublicationDetailLoading, isSuccess: isGetNewsDetailSuccess }] = useLazyGetNewsDetailQuery();

    React.useEffect(() => {
        if (newsId) {
            getNewsDetail(newsId).then(res => {
                if (res.isSuccess) reset(res.data.data)
            })
        }
    }, [newsId])
    const schema = yup.object().shape({
        title: yup.string().trim().required('必填'),
        subtitle: yup.string().trim(),
        newsDate: yup.date().typeError("日期格式錯誤"),
        imageUrl: yup.string().trim(),
        pdfUrl: yup.string().trim(),
        type: yup.number().required('必填'),

    });
    const { register, handleSubmit, formState: { errors }, control, setValue, formState, watch, reset } = useForm<NewsModel>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: { type :1}
    });

    const [isComplete, setIsComplete] = React.useState<boolean>(false);
    async function onSubmit(data: NewsModel) {
        try {
            var result = await editNews({ ...data, id:newsId }).unwrap();
            if (result.isSuccess) {
                setDialogMessage("新增成功");
                setOpenDialog(true);
                setIsComplete(true);
            }
            else {
                setDialogMessage(result.message);
                setOpenDialog(true);
            }
        }
        catch (e: any) {
            if (e.status == 401) {
                setDialogMessage("未登入，請重新整理頁面並登入。");
            } else setDialogMessage("發生問題");
            setOpenDialog(true);
        }
    }
    const [content,setContent]  = React.useState<string>('')

    const handleContentChange = (text: string) => {
        setValue('content',text);
    };

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    function handleDialogClose() {
        setOpenDialog(false);
        if (isComplete)
            onComplete();
    }

    return (<>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ marginTop: '20px' }}>
            <Stack spacing={1} width='max-content'>
                <Typography variant='subtitle1'>
                    消息標題 {important}
                </Typography>
                <TextField
                    variant='outlined'
                    inputRef={register('title').ref}
                    size="small"
                    error={!!errors.title}
                    helperText={errors.title ? errors.title.message : ' '}
                    inputProps={{ maxLength: 320 }}
                    {...register("title")}
                    disabled={isLoading}
                />
                <Typography variant='subtitle1'>
                    消息類別
                </Typography>
                <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <FormControl size="small">
                            <Select
                                value={field.value}
                                onChange={(type) => {
                                    field.onChange(Number(type.target.value))
                                }}
                                label="審核類型"
                                required
                                error={!!errors.type}
                                sx={{ '& legend': { display:'none' } }}
                            >
                                {Object.keys(NewsType).filter(type => !Number.isNaN(Number(type))&&type!='0').map(type => <MenuItem value={type} key={type}>{NewsType[type]}</MenuItem>)}

                            </Select>
                        </FormControl>
                    )}
                />
          
             
                {/*     <Typography variant='subtitle1'>
                發行日期
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                <Controller
                    name="newsDate"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            inputFormat="yyyy/MM/dd"
                            mask="____/__/__"
                            renderInput={(params) => <TextField
                                {...params}
                                error={!!errors.newsDate}
                                helperText={errors.newsDate ? errors.newsDate.message : ' '}
                            />}
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            disabled={isLoading}
                        />
                    )}
                />
                </LocalizationProvider >*/}
                <Typography variant='subtitle1'>
                    消息內容
                </Typography>
                {watch('content')!=null && <TextEditor data={watch('content')} onChange={handleContentChange} />
                }
                {/*  <AddImages register={register}
                    watch={watch}
                    setValue={setValue}
                    formState={formState} />*/}
                <Typography variant='subtitle1'>
                    夾帶檔案
                </Typography>
                <AddAttachment
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    formState={formState}
                    setDialogMessage={setDialogMessage}
                    setOpenDialog={setOpenDialog}
                />
                <Typography color='error'> {errors.filesUrl?.message}</Typography>


            </Stack>
            <br />
            <Button type='submit' variant='contained' >
                編輯消息
            </Button>
        </Box>
        <Dialog open={openDialog} onClose={handleDialogClose}>{dialogContents}</Dialog>
    </>);
}


interface AddAttachmentProps {
    register: UseFormRegister<NewsModel>;
    watch: UseFormWatch<NewsModel>;
    setValue: UseFormSetValue<NewsModel>;
    formState: FormState<NewsModel>;
    setDialogMessage: (string: string) => void;
    setOpenDialog: (boolean: boolean) => void;
}
/*
interface AddImagesProps extends AddAttachmentProps { }
function AddImages(props: AddImagesProps) {
    const { register, watch, setValue, formState: { errors } } = props;
    const [imageUrls, setImageUrls] = React.useState<string[]>([])
    //進行圖片上傳
    const [uploadNewsImage, { isLoading: isUploading }] = useUploadNewsImageMutation();
    const handleImageUrlChange = (urls: string[]) => setImageUrls(urls);
    const [clearImage, setClearImage] = React.useState([]);

    //上傳圖片
    function handleUploadImageFiles(files: File[]) {
        handleSubmit(files);
    }

    //圖片編輯按鈕
    const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    //上傳圖片
    const handleSubmit = async (uploadFiles?: File[]): Promise<boolean> => {
        let tempResult = false
        if (uploadFiles) {
            const formData = new FormData();
            uploadFiles.forEach(uploadFile => {
            formData.append("files", uploadFile);
            })
            await uploadNewsImage(formData).unwrap().then(async result => {
                if (result.isSuccess) {
                    let uri = result.data.urls;
                    handleImageUrlChange(uri);
                }
            }).catch((error) => {
                tempResult = false;
            })
            return tempResult;
        }
    }

    return (
        <Stack spacing={1} paddingTop='12px' paddingBottom='12px'>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Button variant='outlined' onClick={openDialog}>
                    上傳圖片
                </Button>
                <Typography variant='subtitle1'>
                    ※建議尺寸為 332*368 px
                </Typography>
            </Stack>
            <Box position="relative" className="d-flex flex-column mt-2" fontSize='1rem' sx={{ width: 'fit-content' }}>
                <UploadWithImgs
                    values={imageUrls} onChange={(file) => handleUploadImageFiles(file)}
                    dropzoneRef={dropzoneRef}
                    width='100%' height='100%'
                    imgStyle={{ margin: "auto" }}
                    textCenter
                    clearImage={clearImage}
                >
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            color: "darkgray",
                            cursor: "pointer",
                            width: '166px',
                            height: '184px',
                            maxWidth: `100%`,
                            maxHeight: `100%`,
                        }}>
                        <Typography variant='h5' align="center">
                            <Upload />上傳圖片
                        </Typography>
                    </div>
                </UploadWithImgs>
            </Box>

            {(isUploading) &&
                <Backdrop open sx={{ color: "#fff", zIndex: '99' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Stack>
    )
}

*/

function AddAttachment(props: AddAttachmentProps) {
    const { register, watch, setValue, formState: { errors }, setDialogMessage, setOpenDialog } = props;

    //進行PDF上傳
    const [uploadAttachment, { isLoading: isUploading }] = useUploadAttachmentMutation();
    const filesUrlValue = watch("filesUrl");
    const handleAttachmentUrlChange = (url: string) => setValue("filesUrl", [url]);
    const [clearImage, setClearImage] = React.useState([]);

    //上傳PDF
    function handleUploadPdfFile(file: File) {
        setPdfFile(file);
        handleSubmit(file);
    }
    const [pdfFile, setPdfFile] = React.useState<File>();

    //PDF編輯按鈕
    const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    //上傳PDF
    const handleSubmit = async (uploadFile?: File): Promise<boolean> => {
        let tempResult = false
        if (uploadFile) {

            const formData = new FormData();
            formData.append("files", uploadFile);
            await uploadAttachment(formData).unwrap().then(async result => {
                if (result.isSuccess) {
                    let uri = result.data.urls[0];
                    handleAttachmentUrlChange(uri);
                }
            }).catch((error) => {
                if (error.status == 401) {
                    setOpenDialog(true)
                    setDialogMessage("未登入，請重新整理頁面並登入。");
                }
                tempResult = false;
            })
            return tempResult;
        }
    }

    return (
        <Stack spacing={1} paddingTop='12px' paddingBottom='12px'>
            <Box display='flex'>
                <Button variant='outlined' onClick={openDialog}>
                    上傳附件
                </Button>
            </Box>
            <Box position="relative" className="d-flex flex-column mt-2" fontSize='1rem' sx={{ width: 'fit-content' }}>
                <UploadWithAttachments
                    value={filesUrlValue ? filesUrlValue[0]:''} onChange={(file) => handleUploadPdfFile(file)}
                    dropzoneRef={dropzoneRef}
                    width='100%' height='100%'
                    imgStyle={{ margin: "auto" }}
                    textCenter
                    clearImage={clearImage}
                >
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            color: "darkgray",
                            cursor: "pointer",
                            width: '166px',
                            height: '184px',
                            maxWidth: `100%`,
                            maxHeight: `100%`,
                        }}>
                        <Typography variant='h5' align="center">
                            <Upload />上傳PDF
                        </Typography>
                    </div>
                </UploadWithAttachments>
            </Box>

            {(isUploading) &&
                <Backdrop open sx={{ color: "#fff", zIndex: '99' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Stack>
    )
}

