import { useTheme, Box, Container, Paper, Typography, Stack, Toolbar } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";

export  function Tab1of2() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();

   
    return (<>
        <SubTitle title="會費繳款方式" />
        <br />
        <Payment/>
    </>)
      
}


function Payment() {
    return <Stack sx={{ "& p": { marginBottom: "10px" } }}>
        <Typography fontWeight='bold'>【劃撥帳戶】</Typography>
        <Typography>
            &nbsp;&nbsp;帳戶名稱：中華學生事務學會<br />
            &nbsp;&nbsp;劃撥帳號：19775094
        </Typography>
        <Toolbar />
        <Typography fontWeight='bold'>一、臨櫃劃撥</Typography>
        <Typography>
            請至郵局填寫劃撥單，臨櫃劃撥後請於通訊欄註明「收據抬頭」、「聯絡人資料」，並將右側收據E-mail至sayi720@gmail.com，以利對帳，謝謝。
        </Typography>
        <img src='images/menberService/1678763883632.jpg' style={{ maxWidth: "700px" }} />
        <Toolbar />
        <Typography fontWeight='bold'>二、郵局金融卡@郵局實體ATM轉帳</Typography>
        <Typography>
            轉帳交易→存簿轉劃撥→本學會劃撥帳號「19775094」<br />
            請記得E-mail至sayi720@gmail.com：轉帳資訊、個人/團體會員、聯絡人姓名、電話、地址
        </Typography>
        <img src='images/menberService/1678763913590.jpg' style={{ maxWidth: "700px" }} />
        <Toolbar />
        <Typography fontWeight='bold'>三、網路郵局</Typography>
        <Typography>
            轉帳→郵局/銀行轉劃撥→輸入本會劃撥帳號「19775094」<br />
            請備註：個人/團體會員、姓名<br />
            並E-mail至sayi720@gmail.com:轉帳資訊、個人/團體會員、聯絡人姓名、電話、地址
        </Typography>
        <Stack direction='row'>
            <Box>
        <img src='images/menberService/1678763931293.jpg' style={{ maxWidth: "700px" }} />
            </Box>
            <Box>
            <img src='images/menberService/55595626.jpg' style={{ maxWidth: "700px" }} />
            </Box>
        </Stack>
        <Toolbar/>
        <Typography fontWeight='bold'>四、跨行轉帳</Typography>
        <Typography>        
            轉帳功能→轉入劃撥帳戶→轉入帳號請輸入金融機構代號：「7000010」+本學會劃撥帳號「19775094」，共15碼<br />
            轉帳完請E-mail至sayi720@gmail.com：轉帳資訊、個人/團體會員、聯絡人姓名、電話、地址<br />
            有人因此成功轉帳但也收到通知秘書處本方式無效的回復。<br />
            這可能與您的帳戶設定有關(這部分需洽銀行窗口確認您的帳戶設定，秘書處無法代答)
        </Typography>
        <Toolbar />
    </Stack>
}