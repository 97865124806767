import { Avatar, Backdrop, Box, Button, CircularProgress, LinearProgress, Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { ResultType } from "../../components/services/utils";
import { SubTitle } from "../../components/Common";
import { zhTWGrid } from "../data-grid-locale";
import { AddButton, CyanBackStageButton, GreenBackStageButton, GreyBackStageButton, RedBackStageButton } from "../components";
import { NewsType, News } from "../../components/class";
import { useLazyGetPublicationDetailQuery } from "../../components/services/publication";
import { useSetTopNewsMutation } from "../../components/services/news";

interface NewsListProps {
    onChangeType: (type: NewsType) => void;
    onAdd: () => void;
    onEdit: (newsId: string) => void;
    onView: (newsId: string) => void;
    onDelete: (newsId: string) => void;
    newList: ResultType<News[]>;
    isGetNewListLoading: boolean;
    isFetching: boolean;
    refetch: () => void;
}



export function NewsList(props: NewsListProps) {
    const {onChangeType,onAdd, onEdit, onView, onDelete, newList, isGetNewListLoading, isFetching, refetch } = props;
    const [type, setType] = React.useState<NewsType>(NewsType.所有公告)



    return (<>
        <Box display='flex'>
            <SubTitle title='最新消息列表'/>
        </Box>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            margin='10px auto'
        >
            <Stack
                direction="row"
                spacing={2}
            >
                <Box onClick={(e) => { onChangeType(NewsType[e.currentTarget.innerText]); setType(NewsType.所有公告) }} sx={{ fontWeight: type == NewsType.所有公告 ? 'bold' : '', cursor: 'pointer' }}>所有公告</Box>
                <Box onClick={(e) => { onChangeType(NewsType[e.currentTarget.innerText]); setType(NewsType.會務公告) }} sx={{ fontWeight: type == NewsType.會務公告 ? 'bold' : '', cursor: 'pointer' }}>會務公告</Box>
                <Box onClick={(e) => { onChangeType(NewsType[e.currentTarget.innerText]); setType(NewsType.研討會公告)  }} sx={{ fontWeight: type == NewsType.研討會公告 ? 'bold' : '', cursor: 'pointer' }}>研討會公告</Box>
                <Box onClick={(e) => { onChangeType(NewsType[e.currentTarget.innerText]); setType(NewsType.活動消息) }} sx={{ fontWeight: type == NewsType.活動消息 ? 'bold' : '', cursor: 'pointer' }}>活動消息</Box>
                <Box onClick={(e) => { onChangeType(NewsType[e.currentTarget.innerText]); setType(NewsType.其他) }} sx={{ fontWeight: type == NewsType.其他 ? 'bold' : '', cursor: 'pointer' }}>其他公告</Box>
        </Stack>

        <AddButton onClick={onAdd} sx={{ my: 1.5 }}>
            新增消息
        </AddButton>
        </Stack>
        <DataList
            dataList={newList?.data.map(item => ({ ...item, id: item.newsId })) ?? []}
            handleEditPublication={onEdit}
            handleViewPublication={onView}
            handleDeletePublication={onDelete}
            loading={isGetNewListLoading}
            isFetching={isFetching}
            refetch={refetch}
        />
    </>);
}

interface GridDataRow extends News {
    id: string;
}

interface DataListProps {
    dataList: GridDataRow[];
    handleEditPublication: (newsId: string) => void;
    handleViewPublication: (newsId: string) => void;
    handleDeletePublication: (newsId: string) => void;
    loading?: boolean;
    isFetching: boolean;
    refetch: () => void;
}
function DataList(props: DataListProps) {
    const { dataList, loading, handleEditPublication, handleViewPublication, handleDeletePublication, isFetching, refetch} = props;

    const [getPublicationDetail, { isLoading }] = useLazyGetPublicationDetailQuery()
    const [setTopNews, { }] = useSetTopNewsMutation()

    async function handleChangeState(id: string) {
        setTopNews(id).unwrap().then(res => { if (res.isSuccess) refetch(); });
    }
   // const showList = dataList.filter(new=>new)

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'type',
            headerName: '類別',
            width: 100,
            sortable: false,
            valueFormatter: ({ value }) => NewsType[value] ,
        },
        {
            field: 'title',
            headerName: '消息標題',
            width: 200,
        },   
        {
            field: 'createTime',
            headerName: '發布日期',
            width: 200,
        },
        {
            field: 'top',
            headerName: '置頂',
            width: 200,
            renderCell: ({ row: { newsId, top } }: GridRenderCellParams<GridDataRow>) => (
                <Button variant='outlined' sx={{ borderColor: '#4EB596', color: top == 1 ? '#fff' : '#4EB596', backgroundColor: top == 1 ? '#4EB596' : 'inherit' }} onClick={() => { handleChangeState(newsId) }}>置頂</Button>
            )
        },
        {
            field: 'actions',
            headerName: '操作',
            width: 270,
            renderCell: ({ row: { newsId } }: GridRenderCellParams<GridDataRow>) => (
                <Stack direction='row' spacing={1}>
                    <GreenBackStageButton onClick={() => handleEditPublication(newsId)}>
                        編輯
                    </GreenBackStageButton>
                    <RedBackStageButton variant='contained' onClick={() => handleDeletePublication(newsId)} >
                        刪除
                    </RedBackStageButton>
                </Stack>
            )
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                {/*@ts-ignore*/}
                <GridToolbarFilterButton />
                {/*@ts-ignore*/}
                <GridToolbarDensitySelector /> 
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (<>
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoPageSize
            autoHeight
            rowHeight={100}
            disableColumnMenu
            disableColumnSelector
            components={{
              //  Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress
            }}
            localeText={zhTWGrid}
            loading={loading || isFetching}
        />
        <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: '99' }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
    )
}