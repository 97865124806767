import { useTheme, Box, Container, Paper, Typography, Stack, Pagination, Button, CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'
import { SubTitle } from "../../components/Common";
import { EventType, Event, EventDetail } from "../../components/class";
import { useGetEventListQuery, useLazyGetEventDetailQuery } from "../../components/services/event";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EventContent } from "./EventContent";

export function Tab2() {
    const location = useLocation();
    const [page, setPage] = React.useState<number>(1)
    const perPageCount = 5;
    const [eventList, setEventList] = React.useState<Event[]>([])
    const sortList = eventList//.sort((a, b) => { return a.top == true ? -1 : 0 })
    const pages = Math.ceil(sortList.length / perPageCount);
    const showList = sortList.filter((n, index) => index >= perPageCount * (page - 1) && index < perPageCount * page)
    const id = new URLSearchParams(location.search).get('id')
    const { data, isLoading, isSuccess, refetch } = useGetEventListQuery(EventType.其他活動)

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    React.useEffect(() => {
        if (isSuccess) setEventList(data.data)
        if (data) refetch()

    }, [data])
    //
    return (<>
        <SubTitle title="其他活動" />
        <br />
        {isLoading ? <Stack justifyContent='center' alignItems='center'>
            <CircularProgress />
        </Stack>
            :
            <>
                {id ?
                    <EventContent id={id} />
                    :
                    <>
                        <Grid container spacing={2}>
                            {showList.map(n => <EventItem data={n} key={n.eventId} />)}
                        </Grid>
                        <Stack spacing={2} mt="20px" >
                            <Pagination count={pages} page={page} variant="outlined" shape="rounded" onChange={handleChange} />
                        </Stack>
                    </>
                }
            </>
        }
        < br />
    </>)

}

function EventItem(props: { data: Event }) {
    const { data } = props;
    const theme = useTheme();
    const history = useHistory();

    const titleStyle = {
       /* "textOverflow": "ellipsis",
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "WebkitLineClamp": { xs: '2', md: "1" },
        "overflow": "hidden",
        "width": { xs: '75%', md: "90%" },*/
        "lineHeight": "1.25rem",
        "fontSize": "1.15rem",
        cursor: 'pointer'
    }
    const handleClick = () => {
        history.push(`./Event?type=其他活動&id=${data.eventId}`)
    }
    return <Grid
        item
        xs={12}
        md={4}
    >
        <Box
            sx={{ "borderRadius": "5px", "overflow": "hidden", border: '#A9A9A9 solid 1px' }}
        >
            <Box maxHeight='200px' overflow='hidden'>
            <img
                src={data.imageUrl}
                alt={data.title}
                onClick={handleClick}
                style={{ cursor: 'pointer',width:'100%' }}
            />
            </Box>
            <Stack spacing={ .5} padding='30px' paddingTop='10px'>
                <Typography color='secondary.main'>{new Date(data.createTime).toLocaleDateString('zh-tw')}
                </Typography>
                <Stack
                    direction="row"
                    alignItems="flex-end"
                    spacing={1.25}
                   
                >
                    <Typography
                        color='primary.main'
                        sx={titleStyle}
                        onClick={handleClick}
                    >
                        {data.title}
                    </Typography>
                </Stack>
            </Stack>
        </Box>

    </Grid>
}