export enum UserRole {
    anonymous = 0,
    normal,
    administrator,
    superuser,
}

export enum UserRoleText {
    匿名使用者 = 0,
    一般使用者,
    管理員,
    超級管理員
}

export enum UserState {
    待審核 = 0,
    啟用,
    停權
}

export enum UserType {
    一般會員 = 0,
    團體會員
}

export interface User {
    userId: string;
    name: string;
    role: UserRole;
}

export interface UserData {
    userId: string;
    account: string;
    name: string;
    role: UserRole;
    type: UserType;
    telephone: string;
    mobilephone: string;
    email: string;
    state: UserState;
}

export interface UserDetailData {
    userId: string;
    account: string;
    name: string;
    type: UserType;
    gender: string;
    birthday: Date;
    telephone: string;
    mobilephone: string;
    fax: string;
    email: string;
    job: string;
    experience: string;
    address: string;
    comment: string;
    state: UserState;

}

// 最新消息
export interface News {
    newsId: string;
    title: string;
    type: NewsType;
    createTime: string;
    top?: boolean;
}

export interface NewsDetail {
    newsId: string;
    title: string;
    type: NewsType;
    content: string;
    filesUrl: string[];
    createTime: string;
}

export enum NewsType {
    所有公告 = 0,
    會務公告,
    研討會公告,
    活動消息,
    其他
}

// 學會刊物
export interface Publication {
    publicationId: string;
    title: string;
    subtitle?: string;
    type: PublicationType,
    imageUrl: string;
    publicationDate: string;
    createTime?: string;
}

// 學會刊物內容
export interface PublicationDetail {
    publicationId: string;
    title: string;
    subtitle?: string;
    content: string;
    pdfUrl: string;
    filesUrl: string[];
    publicationDate: string;
    createTime: string;
    editor: string;
    editor2: string;
    editor3: string;
}

export enum PublicationType {
    研討會簡報 = 0,  
    學生事務與輔導季刊       
}

// 活動紀錄
export interface Event {
    eventId: string;
    title: string;
    type: EventType;
    imageUrl: string;
    createTime: string;
}

export interface EventDetail {
    eventId: string;
    title: string;
    type: EventType;
    content: string;
    imageUrl: string;       // 封面圖
    imageUrls: string[];    // 內文插圖
    filesUrl: string[];     // 附件
    createTime: string;
}

export enum EventType {
    理監事會議 = 0,   
    會員大會,         
    研討會,       
    其他活動,
    學習資源
}