import { Dialog, styled, TypographyProps } from "@mui/material";
import * as React from "react";
import { NewsType, NewsDetail, UserRole } from "components/class";
import { useUser } from "store";
import { NewsList } from "./NewsList";
import { useLocation } from "react-router";
import { AddNewsPage } from "./AddNewsPage";
import { useDeleteNewsMutation, useGetNewsListQuery } from "../../components/services/news";
import { EditNewsPage } from "./EditNewsPage";

type pageState = 'list' | 'add' | 'edit' | 'view';

export default function NewsManage() {
    const query = new URLSearchParams(useLocation().search);
    const [code, setCode] = React.useState<string>(query.get("e"));
    React.useEffect(() => { setCode(query.get("e")) }, [query.get("e")]);
    const user = useUser();
    const [pageState, setPageState] = React.useState<pageState>('list');
    const [type, setType] = React.useState<NewsType>(NewsType.所有公告);
    const [newsId, setNewsId] = React.useState<string>('');
    const { data: newList, isLoading: isGetNewListLoading, refetch, isFetching } = useGetNewsListQuery(type);
    const [deleteNew, { isLoading: isDeleteNewLoading }] = useDeleteNewsMutation();

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        setPageState('list');
        refetch()
    }, [code])

    function handleChangeType(type: NewsType) {
        setType(type);
    }

    function handleAddNew() {
        setPageState('add');
    }

    function handleEditNew(newsId: string) {
        setNewsId(newsId)
        setPageState('edit');
    }

    function handleViewNew(newsId: string) {
        setNewsId(newsId)
        setPageState('view');
    }

    function handleDeleteNew(newsId: string) {
        try {
            deleteNew(newsId).unwrap().then((data) => {
                if (data.isSuccess) {
                    refetch();
                }
                else {
                    console.log(data.message);
                    setDialogMessage(`刪除失敗：${data.message}`);
                    setOpenDialog(true);
                }
            });
        } catch (error) {
            console.log(error);
            setDialogMessage(`刪除失敗：${error}`);
            setOpenDialog(true);
        }

    }

    function handleBackToList() {
        setPageState('list');
    }

    function handleBackToListAndReftch() {
        setPageState('list');
        refetch()
    }

    if (!user || user.role < UserRole.administrator)
        return <></>;
    else
        return (
            <>
                {pageState == 'add' ? <AddNewsPage onBack={handleBackToList} complete={handleBackToListAndReftch} /> :
                    pageState == 'edit' ? <EditNewsPage onBack={handleBackToList} complete={handleBackToListAndReftch} newsId={newsId} /> :
                        <NewsList
                            onAdd={handleAddNew}
                            onEdit={handleEditNew}
                            onView={handleViewNew}
                            onDelete={handleDeleteNew}
                            onChangeType={handleChangeType}
                            isGetNewListLoading={isGetNewListLoading}
                            isFetching={isFetching}
                            newList={newList}
                            refetch={refetch}
                             />
                }
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
            </>)
}

