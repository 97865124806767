import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SubTitle } from "../../components/Common";
import { GroupList } from "../../components/GroupList";

export function Tab4() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();

    return (<>
        <SubTitle title="常見問題" />
        <br />
        
    </>)

}
