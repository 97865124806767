import { Box, BoxProps, Container } from "@mui/material";
import * as React from "react";

export const Footer = (props: BoxProps) => {
    const { children, ...other } = props;
    return (
        <Box component="footer" {...other}>
                {children}
        </Box>
    );
};