import { Avatar, Backdrop, Box, CircularProgress, LinearProgress, Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { ResultType } from "../../components/services/utils";
import { SubTitle } from "../../components/Common";
import { zhTWGrid } from "../data-grid-locale";
import { AddButton, CyanBackStageButton, GreenBackStageButton, GreyBackStageButton, RedBackStageButton } from "../components";
import { EventType, Event } from "../../components/class";
import { useLazyGetEventDetailQuery } from "../../components/services/event";

interface EventListProps {
    onAdd: () => void;
    onEdit: (eventId: string) => void;
    onView: (eventId: string) => void;
    onDelete: (eventId: string) => void;
    eventList: ResultType<Event[]>;
    isGetEventListLoading: boolean;
    isFetching: boolean;
    type: EventType;
}



export function EventsList(props: EventListProps) {
    const { onAdd, onEdit, onView, onDelete, eventList, isGetEventListLoading, isFetching, type } = props;

    return (<>
        <Box display='flex'>
            <SubTitle title={type != EventType.學習資源 ? '會議記錄列表' : "學習資源列表"} />
        </Box>
     
        <AddButton onClick={onAdd} sx={{ my: 1.5 }}>
            {type != EventType.學習資源 ? "新增紀錄" : "新增資源"}
        </AddButton>
        <DataList
            dataList={eventList?.data.map(item => ({ ...item, id: item.eventId })) ?? []}
            handleEditEvent={onEdit}
            handleViewEvent={onView}
            handleDeleteEvent={onDelete}
            loading={isGetEventListLoading}
            isFetching={isFetching}
        />
    </>);
}

interface GridDataRow extends Event {
    id: string;
}

interface DataListProps {
    dataList: GridDataRow[];
    handleEditEvent: (eventId: string) => void;
    handleViewEvent: (eventId: string) => void;
    handleDeleteEvent: (eventId: string) => void;
    loading?: boolean;
    isFetching: boolean;
}
function DataList(props: DataListProps) {
    const { dataList, loading, handleEditEvent, handleViewEvent, handleDeleteEvent, isFetching , } = props;

    const [getEventDetail, { isLoading }] = useLazyGetEventDetailQuery()
   // const showList = dataList.filter(new=>new)

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'imageUrl',
            headerName: '封面',
            width: 100,
            sortable: false,
            renderCell: ({ value: imageUrl }: GridRenderCellParams<string, GridDataRow>) => (
                <>
                    <img src={imageUrl} style={{ maxWidth: '100%', height: '80px' }} />
                </>
            ),
        },
        {
            field: 'title',
            headerName: '標題',
            width: 200,
        },   
        {
            field: 'createTime',
            headerName: '發布日期',
            width: 200,
        },
        {
            field: 'actions',
            headerName: '操作',
            width: 270,
            renderCell: ({ row: { eventId } }: GridRenderCellParams<GridDataRow>) => (
                <Stack direction='row' spacing={1}>
                    <GreenBackStageButton onClick={() => handleEditEvent(eventId)}>
                        編輯
                    </GreenBackStageButton>
                    <RedBackStageButton variant='contained' onClick={() => handleDeleteEvent(eventId)} >
                        刪除
                    </RedBackStageButton>
                </Stack>
            )
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                {/*@ts-ignore*/}
                <GridToolbarFilterButton />
                {/*@ts-ignore*/}
                <GridToolbarDensitySelector /> 
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (<>
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoHeight
            rowHeight={100}
            disableColumnMenu
            disableColumnSelector
            components={{
              //  Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress
            }}
            localeText={zhTWGrid}
            loading={loading || isFetching}
        />
        <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: '99' }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
    )
}