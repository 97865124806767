import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Box, Button, CircularProgress, Dialog, FormControl, FormControlLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { Controller, FormState, useForm, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import * as yup from "yup";
import { EventType, } from "components/class";
import { important, TextField } from "components/responsive-components";
import { OutlinedButton } from "../components";
import { DropzoneRef } from "react-dropzone";
import { UploadWithAttachments, UploadWithImg, UploadWithImgs, UploadWithPdf } from "../../components/responsive-components/Upload";
import { Upload } from '@mui/icons-material';
import { useUploadAttachmentMutation, useUploadEventImageMutation } from "../../components/services/file";
import TextEditor from "../../components/CKEditor/TextEditor";
import { EventModel, useEditEventMutation, useGetEventDetailQuery, useLazyGetEventDetailQuery } from "../../components/services/event";

//import { EditUserModel, useEditUserMutation } from "components/services/user";

interface EditNewsPageProps {
    onBack: () => void;
    complete: () => void;
    eventId: string;
}
export function EditEventsPage(props: EditNewsPageProps) {
    const { onBack, complete, eventId } = props;
    function handleComplete() {
        complete()
    }
    return (<>
        <Box display='flex'>
            <OutlinedButton onClick={onBack} sx={{ ml: '24px' }}>回到會議記錄列表</OutlinedButton>
        </Box>
        <EditEvent onComplete={handleComplete} eventId={eventId} />
    </>);
}


interface EditEventProps {
    onComplete: () => void;
    eventId: string;

}
function EditEvent(props: EditEventProps) {
    const { onComplete, eventId } = props;

    const [editEvent, { isLoading }] = useEditEventMutation();

    //初始化
    const [getEventDetail, { isLoading: isGetEventDetailLoading, isSuccess: isGetEventDetailSuccess }] = useLazyGetEventDetailQuery();
    React.useEffect(() => {
        if (eventId) {
            getEventDetail(eventId).then(res => {
                if (res.isSuccess) reset(res.data.data)
            })
        }
    }, [eventId])
    const schema = yup.object().shape({
        title: yup.string().trim().required('必填'),
        content: yup.string().trim(),
        imageUrl: yup.string().trim(),
        filesUrl: yup.array().of(yup.string()),
        type: yup.number().required('必填'),

    });
    const { register, handleSubmit, formState: { errors }, control, setValue, formState, watch, reset } = useForm<EventModel>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: {
            content: null,
            imageUrl: '',
            filesUrl: []
        }
    });

    const [isComplete, setIsComplete] = React.useState<boolean>(false);
    async function onSubmit(data: EventModel) {
        try {
            var result = await editEvent({ ...data, id: eventId }).unwrap();
            if (result.isSuccess) {
                setDialogMessage("編輯成功");
                setOpenDialog(true);
                setIsComplete(true);
            }
            else {
                setDialogMessage(result.message);
                setOpenDialog(true);
            }
        }
        catch (e: any) {
            if (e.status == 401) {
                setDialogMessage("未登入，請重新整理頁面並登入。");
            } else setDialogMessage("發生問題");
            setOpenDialog(true);
        }
    }

    const handleContentChange = (text: string) => {
        setValue('content', text);
    };

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    function handleDialogClose() {
        setOpenDialog(false);
        if (isComplete)
            onComplete();
    }

    return (<>{isGetEventDetailLoading ? <Box sx={{ display: 'flex' }}>
        <CircularProgress />
    </Box> :
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ marginTop: '20px' }}>
            <Stack spacing={1} width='max-content'>
                <Typography variant='subtitle1'>
                    標題 {important}
                </Typography>
                <TextField
                    variant='outlined'
                    inputRef={register('title').ref}
                    size="small"
                    error={!!errors.title}
                    helperText={errors.title ? errors.title.message : ' '}
                    inputProps={{ maxLength: 320 }}
                    {...register("title")}
                    disabled={isLoading}
                />
                <Typography variant='subtitle1'>
                    封面圖片
                </Typography>
                <EditImage register={register}
                    watch={watch}
                    setValue={setValue}
                    formState={formState} />
                {/*     <Typography variant='subtitle1'>
                發行日期
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
                <Controller
                    name="newsDate"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            inputFormat="yyyy/MM/dd"
                            mask="____/__/__"
                            renderInput={(params) => <TextField
                                {...params}
                                error={!!errors.newsDate}
                                helperText={errors.newsDate ? errors.newsDate.message : ' '}
                            />}
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            disabled={isLoading}
                        />
                    )}
                />
                </LocalizationProvider >*/}
                <Box marginBottom="25px">
                    <Typography variant='subtitle1'>
                        紀錄內容
                    </Typography>
                    {watch('content') != null &&
                        <TextEditor data={watch('content')} onChange={handleContentChange} />
                    }
                </Box>
                <Typography variant='subtitle1'>
                    上傳圖片<span style={{ color: '#546C99' }} >*可以滑鼠游標拖曳，一次上傳多張圖</span>
                </Typography>
                <EditImages register={register}
                    watch={watch}
                    setValue={setValue}
                    formState={formState} />
                <Typography variant='subtitle1'>
                    夾帶檔案
                </Typography>
                <EditAttachment
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    formState={formState}
                />
                <Typography color='error'> {errors.filesUrl?.message}</Typography>


            </Stack>
            <br />
            <Button type='submit' variant='contained' >
                確認編輯
            </Button>
        </Box>
    }
        <Dialog open={openDialog} onClose={handleDialogClose}>{dialogContents}</Dialog>
    </>);
}


interface EditAttachmentProps {
    register: UseFormRegister<EventModel>;
    watch: UseFormWatch<EventModel>;
    setValue: UseFormSetValue<EventModel>;
    formState: FormState<EventModel>;
}


function EditAttachment(props: EditAttachmentProps) {
    const { register, watch, setValue, formState: { errors } } = props;

    //進行PDF上傳
    const [uploadAttachment, { isLoading: isUploading }] = useUploadAttachmentMutation();
    const filesUrlValue = watch("filesUrl");
    const handleAttachmentUrlChange = (url: string) => setValue("filesUrl", [url]);
    const [clearImage, setClearImage] = React.useState([]);

    //上傳PDF
    function handleUploadPdfFile(file: File) {
        setPdfFile(file);
        handleSubmit(file);
    }
    const [pdfFile, setPdfFile] = React.useState<File>();

    //PDF編輯按鈕
    const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    //上傳PDF
    const handleSubmit = async (uploadFile?: File): Promise<boolean> => {
        let tempResult = false
        if (uploadFile) {

            const formData = new FormData();
            formData.append("files", uploadFile);
            await uploadAttachment(formData).unwrap().then(async result => {
                if (result.isSuccess) {
                    let uri = result.data.urls[0];
                    handleAttachmentUrlChange(uri);
                }
            }).catch((error) => {
                tempResult = false;
            })
            return tempResult;
        }
    }

    return (
        <Stack spacing={1} paddingTop='12px' paddingBottom='12px'>
            <Box display='flex'>
                <Button variant='outlined' onClick={openDialog}>
                    上傳附件
                </Button>
            </Box>
            <Box position="relative" className="d-flex flex-column mt-2" fontSize='1rem' sx={{ width: 'fit-content' }}>
                <UploadWithAttachments
                    value={filesUrlValue ? filesUrlValue[0] : ''} onChange={(file) => handleUploadPdfFile(file)}
                    dropzoneRef={dropzoneRef}
                    width='100%' height='100%'
                    imgStyle={{ margin: "auto" }}
                    textCenter
                    clearImage={clearImage}
                >
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            color: "darkgray",
                            cursor: "pointer",
                            width: '166px',
                            height: '184px',
                            maxWidth: `100%`,
                            maxHeight: `100%`,
                        }}>
                        <Typography variant='h5' align="center">
                            <Upload />上傳附件
                        </Typography>
                    </div>
                </UploadWithAttachments>
            </Box>

            {(isUploading) &&
                <Backdrop open sx={{ color: "#fff", zIndex: '99' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Stack>
    )
}


interface EditImagesProps extends EditAttachmentProps { }
function EditImages(props: EditImagesProps) {
    const { register, watch, setValue, formState: { errors } } = props;
    //進行圖片上傳
    const [uploadNewsImage, { isLoading: isUploading }] = useUploadEventImageMutation();
    const imageUrlValues = watch("imageUrls");
    const handleImageUrlsChange = (url: string[]) => setValue("imageUrls", url);
    const [clearImage, setClearImage] = React.useState([]);

    //上傳圖片
    function handleUploadImageFiles(files: File[]) {
        handleSubmit(files);
    }

    //圖片編輯按鈕
    const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    function handleSetValue(urls){
       setValue('imageUrls',urls)
    }

    //上傳圖片
    const handleSubmit = async (uploadFiles?: File[]): Promise<boolean> => {
        let tempResult = false
        if (uploadFiles) {
            const formData = new FormData();
            uploadFiles.forEach(uploadFile => {
                formData.append("files", uploadFile);
            })
            await uploadNewsImage(formData).unwrap().then(async result => {
                if (result.isSuccess) {
                    let uri = result.data.urls;
                    handleImageUrlsChange(uri);
                }
            }).catch((error) => {
                tempResult = false;
            })
            return tempResult;
        }
    }

    return (
        <Stack spacing={1} paddingTop='12px' paddingBottom='12px'>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Button variant='outlined' onClick={openDialog}>
                    上傳圖片
                </Button>
                <Typography variant='subtitle1'>
                    ※建議尺寸為 332*368 px
                </Typography>
            </Stack>
            <Box position="relative" className="d-flex flex-column mt-2" fontSize='1rem' sx={{ width: 'fit-content' }}>
                <UploadWithImgs
                    values={imageUrlValues}
                    handleSetValue={handleSetValue }
                    onChange={(file) => handleUploadImageFiles(file)}
                    dropzoneRef={dropzoneRef}
                    width='500px' height='100%'
                    imgStyle={{ margin: "auto" }}
                    textCenter
                    clearImage={clearImage}
                >
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            color: "darkgray",
                            cursor: "pointer",
                            width: '166px',
                            height: '184px',
                            maxWidth: `100%`,
                            maxHeight: `100%`,
                        }}>
                        <Typography variant='h5' align="center">
                            <Upload />上傳圖片
                        </Typography>
                    </div>
                </UploadWithImgs>
            </Box>

            {(isUploading) &&
                <Backdrop open sx={{ color: "#fff", zIndex: '99' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Stack>
    )
}



interface EditImageProps {
    register: UseFormRegister<EventModel>;
    watch: UseFormWatch<EventModel>;
    setValue: UseFormSetValue<EventModel>;
    formState: FormState<EventModel>;
}

function EditImage(props: EditImageProps) {
    const { register, watch, setValue, formState: { errors } } = props;

    //進行圖片上傳
    const [uploadEventImage, { isLoading: isUploading }] = useUploadEventImageMutation();
    const imageUrlValue = watch("imageUrl");
    const handleImageUrlChange = (url: string) => setValue("imageUrl", url);
    const [clearImage, setClearImage] = React.useState([]);

    //上傳圖片
    function handleUploadImageFile(file: File) {
        handleSubmit(file);
    }

    //圖片編輯按鈕
    const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    //上傳圖片
    const handleSubmit = async (uploadFile?: File): Promise<boolean> => {
        let tempResult = false
        if (uploadFile) {
            const formData = new FormData();
            formData.append("files", uploadFile);
            await uploadEventImage(formData).unwrap().then(async result => {
                if (result.isSuccess) {
                    let uri = result.data.urls[0];
                    handleImageUrlChange(uri);
                }
            }).catch((error) => {
                tempResult = false;
            })
            return tempResult;
        }
    }

    return (
        <Stack spacing={1} paddingTop='12px' paddingBottom='12px'>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Button variant='outlined' onClick={openDialog}>
                    上傳圖片
                </Button>
                <Typography variant='subtitle1'>
                    ※建議尺寸為 332*368 px
                </Typography>
            </Stack>
            <Box position="relative" className="d-flex flex-column mt-2" fontSize='1rem' sx={{ width: 'fit-content' }}>
                <UploadWithImg
                    value={imageUrlValue} onChange={(file) => handleUploadImageFile(file)}
                    dropzoneRef={dropzoneRef}
                    width='100%' height='100%'
                    imgStyle={{ margin: "auto" }}
                    textCenter
                    clearImage={clearImage}
                >
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            color: "darkgray",
                            cursor: "pointer",
                            width: '166px',
                            height: '184px',
                            maxWidth: `100%`,
                            maxHeight: `100%`,
                        }}>
                        <Typography variant='h5' align="center">
                            <Upload />上傳圖片
                        </Typography>
                    </div>
                </UploadWithImg>
            </Box>

            {(isUploading) &&
                <Backdrop open sx={{ color: "#fff", zIndex: '99' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Stack>
    )
}

