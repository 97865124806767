import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "components/Common";
import { GroupList } from "components/GroupList";

export function Tab1of2() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();


    return (<>
        <SubTitle title="編輯委員" />
        <br />
        {
            GroupList([
                {
                    gruop: "總編輯",
                    list: [
                        {
                            name: "劉若蘭", info: "國立臺灣師範大學公民教育與活動領導學系 教授兼全人教育中心執行長"
                        },
                    ]
                },
                {
                    gruop: "輪值主編",
                    list: [
                        { name: "簡信男", info: "亞洲大學副學務長" },
                        { name: "陳明國", info: "朝陽科技大學服務學習中心主任" },
                        { name: "梁朝雲", info: "國立臺灣大學生物產業傳播暨發展學系特聘教授" },
                        { name: "李育齊", info: "國立高雄大學教學發展中心專案助理研究員" },

                    ]
                },
                {
                    gruop: "執行編輯",
                    list: [
                        { name: "吳振邦", info: "致理科技大學休閒遊憩管理學系兼任助理教授" },
                        { name: "蔡昕璋", info: "國立臺灣師範大學教育學院兼任助理教授" },
                        { name: "吳建隆", info: "中華科技大學副學務長" },
                        { name: "李立旻", info: "國立臺灣師範大學專責導師" },

                    ]
                },
            ])}
    </>)

}
