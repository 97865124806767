import { Box, Button, styled, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import EventsManage from "./EventsManage";

export default function EventsIndex() {
    const { t } = useTranslation();
    const query = new URLSearchParams(useLocation().search);
    const [code, setCode] = React.useState<string>(query.get("e"));
    React.useEffect(() => { setCode(query.get("e")) }, [query.get("e")]);

    if (code == "0" || code == "1"|| code == "2"|| code == "3"|| code == "4")
        return (
            <EventsManage eventType={Number(code)} />
        )
    else return (<>
        <Box display="flex">
       會議記錄管理
        </Box>
    </>)
}

const BigButton = styled(Button)(({ theme }) => ({

}));