import * as React from 'react';
import { Backdrop, Box, Container, SxProps, Theme, Typography, useTheme, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';


export interface SibarItemchilds {
    nodeId: string;
    title: string;
}

export interface SibarItem {
    nodeId: string;
    title: string;
    childs?: SibarItemchilds[];
}

export function PageSidbar(props: { sibarItem: SibarItem[] }) {


    return (
        <>
            {props.sibarItem.map(item => item.childs ?
                <TreeItem
                    nodeId={item.nodeId}
                    label={<Box sx={{ color: 'primary.main' }}>{item.title}< ExpandMoreIcon sx={{ fill: 'secondary.light' }} /></Box>}
                    sx={{ "& .MuiTreeItem-label": { color: 'text.subText', } }}
                    key={item.nodeId}
                >{item.childs.map(child => <TreeItem nodeId={child.nodeId} label={child.title} key={child.nodeId} />)}</TreeItem>
                :
                <TreeItem nodeId={item.nodeId} label={item.title} sx={{ color: 'primary.main' }} key={item.nodeId} />)}

        </>)
}


export interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}