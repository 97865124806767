import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { News, NewsDetail, NewsType } from '../class';
import { ResultType } from './utils';

export interface NewsModel {
    title: string;
    type: NewsType;
    top: boolean;
    content: string;
    filesUrl: string[];
}

export const baseApi = createApi({
    reducerPath: 'newsApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/news' }),
    endpoints: (builder) => ({
        getNewsList: builder.query<ResultType<News[]>, NewsType>({
            query: (type) => `list?type=${type}`
        }),
        getNewsDetail: builder.query<ResultType<NewsDetail>, string>({
            query: (newsId) => `detail?newsId=${newsId}`
        }),
    }),
})

// exhibitor或superuser或admin用api
export const newsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        addNews: builder.mutation<ResultType<string>, NewsModel>({
            query: ({ ...patch }) => ({
                url: ``,
                method: 'POST',
                body: patch,
            }),
        }),
        editNews: builder.mutation<ResultType<string>, { id: string } & NewsModel>({
            query: ({ id, ...patch }) => ({
                url: `${id}`,
                method: 'PUT',
                body: patch,
            }),
        }),
        deleteNews: builder.mutation<ResultType<null>, string>({
            query: (id) => ({
                url: `${id}`,
                method: 'DELETE',
            }),
        }),
        setTopNews: builder.mutation<ResultType<boolean>, string>({
            query: (id) => ({
                url: `settop/${id}`,
                method: 'PUT',
            }),
        }),
    }),
    overrideExisting: false
});

export const { useGetNewsListQuery } = newsApi;
export const { useLazyGetNewsListQuery } = newsApi;
export const { useGetNewsDetailQuery } = newsApi;
export const { useLazyGetNewsDetailQuery} = newsApi;

export const { useAddNewsMutation } = newsApi;
export const { useEditNewsMutation } = newsApi;
export const { useDeleteNewsMutation } = newsApi;
export const { useSetTopNewsMutation } = newsApi;