import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";

export function Tab1of3() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();


    return (<>
        <SubTitle title="組織章程" />
        <br />
        <Box sx={{ "& .MuiTypography-root, & div": { "fontFamily": "標楷體" }, "& p": { lineHeight: "22px", marginBottom: "10px" }, "& li": { marginBottom: "10px" } }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
                中華學生事務學會組織章程
            </Typography>
            <br />
            <Typography variant="h6" sx={{ textAlign: "right" }}>
                民國105年5月13日第41屆第2次會員大會修正
            </Typography>
            <br />
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第一條
                </Typography>
                <Typography variant="body1">
                    本會定名為中華學生事務學會，以下簡稱「本會」。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二條
                </Typography>
                <Typography variant="body1">
                    本會以強化學生事務研究，促進學生事務經驗分享，增進學生學習與發展為宗旨。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第三條
                </Typography>
                <Typography variant="body1">
                    本會設立在中華民國中央政府所在地。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第四條
                </Typography>
                <Box>
                    <Typography variant="body1">
                        本會之任務如下：
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>研究並促進各級學校學生事務工作之發展。</li>
                        <li>協助學生事務人員增進專業知能。</li>
                        <li>分享工作經驗，聯絡會員感情。</li>
                    </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第五條
                </Typography>
                <Box>
                    <Typography variant="body1">
                        本會會員分為一般會員、團體會員、榮譽會員及永久會員四種。各類會員申請資格如下：
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>一般會員：現任或曾任各級學校學生事務（訓導）工作之人員；或熱心學生事務工作並認同本會宗旨者。</li>
                        <li>團體會員：國內教育主管機關立案之各級學校。團體會員應遴派代表一人，以行使會員權利。</li>
                        <li>榮譽會員：對學生事務研究與發展著有貢獻，經本會理監事推荐並經理事會審核通過者。</li>
                        <li>永久會員：一次繳交新台幣一萬元常年會費之個人會員。</li>
                    </ul>
                    <Typography variant="body1">
                        會員入會時應填具入會申請書，經理事會審核通過後，除榮譽會員及永久會員外，應依本會章程規定繳納入會費及常年會費。
                    </Typography>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第六條
                </Typography>
                <Typography variant="body1">
                    凡有違反本會章程行為者，得經理事會決議，予以警告或停權處分；其危害團體情節重大者，得經會員大會決議予以除名。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第七條
                </Typography>
                <Box>
                    <Typography variant="body1">
                        會會員（會員代表）應享權利如下：
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>發言權及表決權。</li>
                        <li>選舉權及被選舉權。</li>
                        <li>本會所舉辦各種事業之利益。</li>
                        <li>其他公共應享之權利。</li>
                    </ul>
                    <Typography variant="body1">
                        每一會員（會員代表）各具有一權。但榮譽會員無前項權利。
                    </Typography>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第八條
                </Typography>
                <Box>
                    <Typography variant="body1">
                        本會會員應負義務如下：
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>遵守本會章程及決議案。</li>
                        <li>擔任本會所舉辦各項活動之職務。</li>
                        <li>繳納入會費及常年會費。未繳納入會費及常年會費者，不得享有會員權利；連續二年未繳納常年會費者，視為自動退會。</li>
                    </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第九條
                </Typography>
                <Box>
                    <Typography variant="body1">
                        會員有下列情事之一者，為出會：
                    </Typography>
                        <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                            <li>喪失會員資格者。</li>
                            <li>經會員大會決議除名者。</li>
                        </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十條
                </Typography>
                <Typography variant="body1">
                    會員得以書面敘明理由向本會聲明退會。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十一條
                </Typography>
                <Box>
                    <Typography variant="body1">
                        本會以會員大會為最高權力機關，在會員大會閉會期間，由理事會代行職務。監事會為監察機構。
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '10px' }}>會員大會之職權如下：</Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>訂定與變更章程。</li>
                        <li>選舉及罷免理事、監事。</li>
                        <li>議決入會費、常年會費、永久會員會費、事業費及會員捐款之數額及方式。</li>
                        <li>議決年度工作計畫、報告及預算、決算。</li>
                        <li>議決會員（會員代表）之除名處分。</li>
                        <li>議決財產之處分。</li>
                        <li>議決本會之解散。</li>
                        <li>議決與會員權利義務有關之其他重大事項。</li>
                    </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十二條
                </Typography>
                <Typography variant="body1">
                    本會理事會置理事二十一人，候補理事七人，由會員（會員代表）選舉之。常務理事七人，由理事互選之，並由理事就常務理事中選舉一人為理事長。本會置副理事長二人，其選任方式與理事長同。理事得採用通訊選舉，但不得連續辦理。通訊選舉辦法由理事會通過，報請主管機關核備後行之。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十三條
                </Typography>
                <Typography variant="body1">
                    理事長對內綜理督導會務，對外代表本會，並擔任會員大會、理事會主席。
                    理事長因事不能執行職務時，應指定常務理事一人代理之，未指定或不能指定時，由常務理事互推一人代理之。  </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十四條
                </Typography>
                <Typography variant="body1">
                    本會監事會置監事七人，候補監事二人，由會員（會員代表）選舉之。常務監事一人，由監事互選之，監察日常會務，並擔任監事會主席。

                    常務監事因事不能執行職務時，應指定監事一人代理之，未指定或不能指定時，由監事互推一人代理之，並應於一個月內補選之。

                    監事得採用通訊選舉，但不得連續辦理。通訊選舉辦法由理事會通過，報請主管機關核備後行之。 </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十五條
                </Typography>
                <Box>
                    <Typography variant="body1">理事會之職權如下：
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>議決會員大會之召開事項。</li>
                        <li>審定會員（會員代表）之資格。</li>
                        <li>選舉及罷免常務理事、理事長。</li>
                        <li>議決理事、常務理事及理事長之辭職。</li>
                        <li>聘免工作人員。</li>
                        <li>擬定年度工作計畫、報告及預算與決算。</li>
                        <li>其他應執行事項。</li>
                    </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十六條
                </Typography>
                <Box>
                    <Typography variant="body1">監事會之職權如下：
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>監察理事會工作之執行。</li>
                        <li>審核年度決算。</li>
                        <li>選舉及罷免常務監事。</li>
                        <li>議決監事及常務監事之辭職。</li>
                        <li>其他應監察事項。</li>
                    </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十七條
                </Typography>
                <Box>
                    <Typography variant="body1">本會理監事如有下列各款之一者，應予解職。
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>不得已事故，經會員大會議決，准其辭職者。</li>
                        <li>曠廢職務，經會員大會議決，令其退職者。</li>
                        <li>職務上違反法令或有重大不正當行為，經會員大會議決，令其退職者。</li>
                    </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十八條
                </Typography>
                <Typography variant="body1">本會理監事任期為二年，連選得連任之，理事長限連任一次。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第十九條
                </Typography>
                <Typography variant="body1">本會置秘書長一人，副秘書長一至三人，工作人員若干人，由理事會聘請之，並報主管機關備查。前項工作人員不得由選任之理事、監事擔任。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十條
                </Typography>
                <Typography variant="body1">本會得由理事會聘請名譽理事長一人、名譽理事、顧問若干人，並參加理監事會，提供會務諮詢，其聘期與理事、監事之任期同。   </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十一條
                </Typography>
                <Typography variant="body1">本會得設各種委員會、小組或其他內部作業組織，其組織簡則經理事會通過後施行，變更時亦同。</Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十二條
                </Typography>
                <Box>
                    <Typography variant="body1">本會會員大會每年舉行一次，必要時得經會員四分之一以上連署同意舉行臨時會議。

                        會員大會之決議，以會員過半數之出席，出席人數較多數之同意行之。但下列事項之決議以出席人數三分之二以上同意行之。
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>章程之訂定與變更。</li>
                        <li>會員之除名。</li>
                        <li>理事、監事之罷免。</li>
                        <li>財產之處分。</li>
                        <li>本會之解散。</li>
                        <li>其他與會員權利義務有關之重大事項。</li>
                    </ul>
                    會員（會員代表）不能親自出席會員大會時，得以書面委託其他會員（會員代表）代理，每一會員（會員代表）以代理一人為限。
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十三條
                </Typography>
                <Typography variant="body1">
                    本會理事會、監事會至少每六個月開會一次，常務理事會、監事會得每月舉行一次，必要時均得舉行臨時會議。

                    會議之決議，各以理事、監事過半數之出席，出席人數較多數之同意行之。

                    理事應出席理事會議，監事應出席監事會議，理事會、監事會不得委託出席；理事、監事連續二次無故缺席理事會、監事會者，視同辭職。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十四條
                </Typography>
                <Typography variant="body1">
                    本會應於召開會員大會十五日前、或召開理事會、監事會七日前以書面通知，並將會議種類、時間、地點連同議程函報主管機關備查。  </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十五條
                </Typography>
                <Box>
                    <Typography variant="body1">本會經費來源為下列各款：
                    </Typography>
                    <ul style={{ "marginLeft": "-2rem", "marginBottom": "0", "marginTop": "10px", listStyleType: "cjk-ideographic" }}>
                        <li>入會費：一般會員新台幣五百元、團體會員新台幣一千元。</li>
                        <li>常年會費：團體會員大專校院新台幣四千元，團體會員中小學新台幣二千元，一般會員新台幣五百元，個人永久會員，新台幣一萬元。</li>
                        <li>補助費。</li>
                        <li>捐款。</li>
                        <li>事業費。</li>
                        <li>基金及其孳息。</li>
                        <li>其他收入。</li>
                    </ul>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十六條
                </Typography>
                <Typography variant="body1">本會解散或撤銷時，所剩餘財產應依法處理，不得以任何方式歸屬個人或私人企業所有，應歸屬政府，或地方自治團體所有。
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十七條
                </Typography>
                <Typography variant="body1">本會各項之辦理，細則另訂之。

                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ "marginBottom": "10px" }}>
                <Typography variant="body1" sx={{ minWidth: "max-content" }}>
                    第二十八條
                </Typography>
                <Typography variant="body1">本會章程經會員大會通過，報經主管機關核備後施行，修正時亦同。

                </Typography>
            </Stack>
        </Box>
    </>)

}
