import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User, UserRole, UserState, UserType, UserData, UserDetailData } from '../class';
import { ResultType } from './utils';

export interface ApplyUserModel {
    account: string;
    password: string;
    name: string;
    type: UserType;
    gender: string;
    birthday: Date;
    telephone: string;
    mobilephone: string;
    fax: string;
    email: string;
    job: string;
    experience: string;
    address: string;
    comment: string;
}

export interface AddUserModel {
    account: string;
    password: string;
    name: string;
    role: UserRole;
    type: UserType;
    gender: string;
    birthday: Date;
    telephone: string;
    mobilephone: string;
    fax: string;
    email: string;
    job: string;
    experience: string;
    address: string;
    comment: string;
}

export interface EditUserModel {
    name: string;
    role: UserRole;
    type: UserType;
    state: UserState;
    gender: string;
    birthday: Date;
    telephone: string;
    mobilephone: string;
    fax: string;
    email: string;
    job: string;
    experience: string;
    address: string;
    comment: string;
}

const baseApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/user' }),
    endpoints: (builder) => ({
        getUser: builder.query<ResultType<UserData>, string>({
            query: (userId) => `GetById/${userId}`,
        }),
        applyUser: builder.mutation<ResultType<string>, ApplyUserModel>({
            query: (patch) => ({
                url: `apply`,
                method: 'POST',
                body: patch
            }),
        }),
    }),
});

//superuser或admin用api
export const userApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({   
        getUserList: builder.query<ResultType<UserData[]>, void>({
            query: () => `allusers`,
        }),
        getUserDetail: builder.query<ResultType<UserDetailData>, string>({
            query: (userId) => `detail/${userId}`,
        }),
        addUser: builder.mutation<ResultType<null>, AddUserModel>({
            query: (patch) => ({
                url: `add`,
                method: 'POST',
                body: patch
            }),
        }),
        editUser: builder.mutation<ResultType<null>, { userId: string } & EditUserModel>({
            query: ({ userId, ...patch }) => ({
                url: `edit/${userId}`,
                method: 'PUT',
                body: patch
            }),
        }),
        changeUserState: builder.mutation<ResultType<null>, {userId: string, state: UserState }>({
            query: ({ userId, ...patch }) => ({
                url: `state/${userId}`,
                method: 'PUT',
                body: patch
            }),
        }),  
        changeUserPassword: builder.mutation<ResultType<null>, { userId: string, password: string }>({
            query: ({ userId, ...patch }) => ({
                url: `password/${userId}`,
                method: 'POST',
                body: patch,
            }),
        }),
    }),
    overrideExisting: false
});


export const { useApplyUserMutation } = userApi;

export const { useGetUserQuery } = userApi;
export const { useLazyGetUserQuery } = userApi;
export const { useGetUserListQuery } = userApi;
export const { useLazyGetUserListQuery } = userApi;
export const { useGetUserDetailQuery } = userApi;
export const { useLazyGetUserDetailQuery } = userApi;
export const { useAddUserMutation } = userApi;
export const { useEditUserMutation } = userApi;
export const { useChangeUserStateMutation } = userApi;
export const { useChangeUserPasswordMutation } = userApi;
