import { styled, TextField, TextFieldProps } from "@mui/material";

export const CustomField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme, hiddenLabel }) => ({
    '& .MuiOutlinedInput-root':{
        '& fieldset': {
            top: 0,
        },
        '& fieldset legend': {
            display: 'none',
        },
    },
}));