import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SubTitle } from "../../components/Common";

export  function Tab5() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();

    const dataList = [
        {
            item: "會員繳費、期刊訂閱等經費相關事宜", contact: "陳欣怡 會計", phone: "02-28819471#7102", mail:"sayi720@gmail.com"
        },
        {
            item: "會員權益、理監事參選、網站管理、理監事會議、會員大會等", contact: "許鶴永 秘書", phone: "02-27361661#2213", mail:"andy826@tmu.edu.tw"
        },
        {
            item: "研討會、期刊寄送、投稿等相關事項", contact: "賴佳琪 秘書", phone: "02-27361661#2220", mail:"jessie116@tmu.edu.tw"
        },
        {
            item: "認證考試、證書發放等", contact: "江翊菱 秘書", phone: "03-4117578#666", mail:"ling0423@hsc.edu.tw"
        },
        {
            item: "其他事項", contact: "沈軍廷 秘書長", phone: null, mail:"a0989015018@gmail.com"
        },
    ]

    return (<>
        <SubTitle title="聯絡我們" />
        <br />
        <TableContainer component={Box} sx={{ "& td": { fontSize: { xs: "10px", md: "1rem" }, padding: "5px 10px ", minWidth: "60px" } }}>
            <Table aria-label="simple table" >
                <TableHead>
                    <TableCell sx={{ minWidth: '150px', maxWidth:'150px' }}>項目</TableCell>
                    <TableCell >聯絡人</TableCell>
                    <TableCell >電話</TableCell>
                    <TableCell >信箱</TableCell>
                </TableHead>
                <TableBody>
                    {dataList.map((data ,index) => (<TableRow key={index}>                   
                            <>
                            {/* <TableCell sx={{ border: index == data.length - 1 ? "" : 0 }}>{data.item}</TableCell>*/}
                            <TableCell sx={{ maxWidth: '150px' }} >{data.item}</TableCell>
                            <TableCell >{data.contact}</TableCell>
                            <TableCell >{data?.phone}</TableCell>
                            <TableCell >{data.mail}</TableCell>
                            </>
                    </TableRow>))}
                    <TableRow sx={{ padding: "30px" }}>
                        <TableCell colSpan={4} >公文收發地址：新竹市香山區玄奘路48號(玄奘大學 宗教與文化學系 林至善 理事長收)</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>)     
}
