import { Logout } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { BottomNavigation, BottomNavigationAction, Box, Divider, Link, Paper, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../components/class';
import { useDispatchWithType, useUser } from '../store';
import { deleteAllCookie } from '../cookie-utils';
import { useLogoutMutation } from '../components/services/login';
import { logoutUser } from '../store/rootReducer';
import ManageSideBar from './ManageSideBar';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ManagePageView, sideBarItems, viewItems } from './sideBar';
import ManageBoardPanel from './ManageBoardPanel';
const HomeIcon = "images/logo.png";
const BG = "images/fin_Background.png";

export default function ManageBoardIndex() {
    const user = useUser();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    const [miniPageList, setMiniPageList] = React.useState<ManageSideBarItem[]>([]);
    const [allowSideBarList, setAllowSideBarList] = React.useState<ManageSideBarItem[]>([]);
    const [pageView, setPageView] = React.useState<ManagePageView>(viewItems);

    function isPass(page: IAllow) {
        if (page.allowAnonymous || user?.role > UserRole.anonymous) {
            if (!page.adminOnly || user?.role >= UserRole.administrator) {
                if (!page.superuserOnly || user?.role >= UserRole.superuser)
                    return true;
            }
        }
        return false;
    }
    function checkPageList(pageList: ManageSideBarItem[]) {
        let pages: ManageSideBarItem[] = [];
        pageList.forEach(page => {
            if (isPass(page)) {
                if (page.pageList) page.pageList = page.pageList.filter(isPass);
                pages.push(page);
            }
        });
        return pages;
    }
    function checkForMiniPage(pageList: ManageSideBarItem[]) {
        let pages: ManageSideBarItem[] = [];
        pageList.forEach(page => {
            if (page.showOnMobile) pages.push(page);
            if (page.pageList) pages.push(...checkForMiniPage(page.pageList));
        });
        return pages;
    }
    function checkView(viewList: ManagePageView) {
        Object.keys(viewList).forEach(code => {
            viewList[code].list = [...viewList[code].list].filter(isPass);
        });
        return viewList;
    }
    React.useLayoutEffect(() => {
        if (user) {
            let allowItems = checkPageList(sideBarItems);
            setAllowSideBarList(allowItems);
            
            setMiniPageList(checkForMiniPage(allowItems));
            setPageView(checkView(viewItems));
        }
    }, [user]);

    return (<>
        <Box display="flex" height='calc(100vh - 1px)'>  
                <TabsBoard
                    allowSideBarList={allowSideBarList}
                    pageView={pageView}
                    defaultExpanded={["manage.title.events.events"]}
                />             
        </Box>
    </>)
}

export interface ManageSideBarItem extends IAllow {
    name: string; //顯示文字，不一定district，基本上都是放translation的key，除了攤位名稱的部分
    value?: string; //對應頁面值
    className?: string; //使用scroll跳轉的對應className
    icon?: JSX.Element; //顯示圖示
    pageList?: ManageSideBarItem[]; //子頁面
    query?: string; //附加的query值
    showOnMobile?: boolean; //預設為false，是否在mini page上顯示
}
export interface ManagePageItem extends IAllow {
    className: string,
    content: JSX.Element,
}
export interface IAllow {
    allowAnonymous?: boolean; //預設為false
    adminOnly?: boolean; //預設為false，是否只准許管理員以上權限閱覽
    superuserOnly?: boolean; //預設為false，是否只准許超級使用者以上權限閱覽
}

function TabsBoard(props: TabsBoardProps) {
    const { allowSideBarList, pageView, defaultExpanded } = props;

    const history = useHistory();
    //判斷與紀錄當前頁面
    const [value, setValue] = React.useState('0');
    async function handleChange(newValue: string, query?: string) {
        await setValue(newValue);
        if (query) history.push({ search: query }); else history.push({ search: '' });
        return;
    };

    const width: number = 256;
    return (
        <TabContext value={value}>
            <Box display="flex" flexDirection="column" width={width} position='fixed' height='100vh'>
                <ManageSideBar
                    allowSideBarList={allowSideBarList}
                    handleChange={handleChange}
                    currentPage={value}
                    defaultExpanded={defaultExpanded}
                />
            </Box>
            <Box width={width} />
            <Divider orientation="vertical" flexItem style={{ height: "100%" }} />
            <main id='backStagePanel' style={{ maxWidth: `calc(100% - ${width + 1}px)`, flex: 1, overflowY: "auto" }}>
                {pageView && Object.keys(pageView).map((code, index) => (
                    <ManageBoardPanel
                        key={"panel-" + code}
                        value={code}
                        view={pageView[code]}
                        sx={{ /*width: `calc(100vw - ${width + 1 + 17}px)`,*/ background: "#f8fafb", minHeight: '100%' }}
                    />
                ))}
            </main>
        </TabContext>
    )
};
interface TabsBoardProps {
    allowSideBarList: ManageSideBarItem[];
    pageView: ManagePageView;
    defaultExpanded?: string[];
}

function MiniBoard(props: MiniBoardProps) {
    const { allowSideBarList, pageView } = props;
    const { t } = useTranslation();

    //判斷與紀錄當前頁面
    const [value, setValue] = React.useState('0');
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    //登出
    const dispatch = useDispatchWithType();
    const [logout] = useLogoutMutation();
    const handleClickLogout = () => {
        logout().unwrap().then(result => {
            if (result.isSuccess) {
                dispatch(logoutUser());
                deleteAllCookie();
                window.location.href = '/Login';
            }
        }).catch((error) => {
            console.log("Logout Failed!");
            console.log(error);
        });
    };

    return (
        <TabContext value={value}>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
                <BottomNavigation showLabels value={value} onChange={handleChange}>
                    {allowSideBarList.map((page, index) => (
                        <BottomNavigationAction
                            key={`${index}:${page.name}`}
                            label={t(page.name)}
                            icon={page.icon}
                            value={page.value}
                        />
                    ))}
                    <BottomNavigationAction
                        icon={<Logout />}
                        label={t('manage.title.logout')}
                        onClick={handleClickLogout}
                    />
                </BottomNavigation>
            </Paper>
            <main style={{ maxWidth: '100%', flexGrow: 1 }}>
                {pageView && Object.keys(pageView).map((code) => (
                    <ManageBoardPanel
                        key={"minipanel-" + code}
                        value={code}
                        view={pageView[code]}
                        sx={{ paddingBlockEnd: `${/*toolbar*/56 + /*custom*/8}px` }}
                    />
                ))}
            </main>
        </TabContext>
    )
};
interface MiniBoardProps {
    allowSideBarList: ManageSideBarItem[];
    pageView: ManagePageView;
}