import * as React from "react";
import { useModalControl } from "../../store";

export default function ModalSystem() {
    const modalControl = useModalControl();

    const MODAL_COMPONENTS = {
    }

    if (!modalControl)
        return null;
    else if (!modalControl.modalType)
        return null;
    else {
        const SpecificModal = MODAL_COMPONENTS[modalControl.modalType]
        return <SpecificModal {...modalControl.modalProps} />
    }
}