import * as React from 'react';
import { Box, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Footer } from './Footer';
import { NavBar } from './NavBar';
import { useTranslation } from 'react-i18next';
import { Facebook } from '@mui/icons-material';
const HomeIcon = "images/logo2.png";

export type LayoutVarient = "login" | "noFooter" |"noNavbar";
export const Layout = (props: LayoutProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const [withNavBar, setWithNavBar] = React.useState(true);
    const [withContainer, setWithContainer] = React.useState(true);
    const [withFooter, setWithFooter] = React.useState(true);
    React.useEffect(() => {
        if (props.varient == "login") {
            setWithNavBar(false);
            setWithContainer(false);
            setWithFooter(false);
        }
        else if (props.varient == "noFooter") {
            setWithNavBar(true);
            setWithContainer(false);
            setWithFooter(false);
        }
        else if (props.varient == "noNavbar") {
            setWithNavBar(false);
            setWithContainer(false);
            setWithFooter(false);
        }
        else {
            setWithNavBar(true);
            setWithContainer(true);
            setWithFooter(true);
        }
    }, [props.varient])

    const clearCssStyle: React.CSSProperties = {
        width: 'unset',
        height: 'unset',
        maxWidth: 'unset',
        padding: 'unset',
        margin: 'unset',
    }

    return (
        <>
            {withNavBar &&
                <NavBar logo={<Logo />} title="中華學生事務學會"/*name={<LogoText />}*/ />
            }

            <div style={withContainer ? { minHeight: window.innerHeight - 64 - 100, marginTop: isMobile?"50px":'150px' } : { ...clearCssStyle }}>
                {props.children}
            </div>

            {withFooter &&
                <Footer position="relative" >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "primary.dark", height: "130px" }} gridTemplateColumns={{ xs: "1fr", md: "repeat(3, 1fr)" }} textAlign={{ xs: "center", md: "unset" }}>  
                        <Typography color="white" sx={{ mt: 3 }}>© 2022 中華學生事務學會 All Rights Reserved.</Typography>
                        <Typography color="white">電子郵件：<Link href="mailto:a0989015018@gmail.com" color='#fff'>a0989015018@gmail.com</Link></Typography>
                        <Typography color="white">Powered by Tempest Digital</Typography>
                    </Box>
                </Footer>
            }
        </>
    );
};
export interface LayoutProps {
    varient?: LayoutVarient;
    children: React.ReactElement
}

const Logo = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <img src={HomeIcon} style={{ height: isMobile?'40px':'90px', maxWidth: '100%' }} />
    )
}
const LogoText = () => {
    const { t } = useTranslation();
    return (
        <Typography style={{ padding: 6 }}>{t('navbar.title')}</Typography>
    )
}