//Google 登入
export const googleClientId = "100391442517-spqoob45hrtt5pc6itc14lr3aq74nmbm.apps.googleusercontent.com";

//jitsi server domain
export const mediaDomain = 'shuttle.tempestdigi.com';

//jitsi live streaming server domain
export const liveDomain = "shuttle.tempestdigi.com";

//Google 分析
export const gaTrackingId = "G-KV1S9J0961";