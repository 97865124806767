import { useTheme, Box, Container, Paper, Typography, Stack, Pagination, Button } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'

import { PageTitle, SubTitle } from "components/Common";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { object } from "yup";
import { EventType, Event } from "components/class";
import { PageSidbar, SibarItem, TabPanel } from "../components/PageSidbar";
import { Tab1of1 } from './tabs/Tab1of1';
import { Tab1of2 } from './tabs/Tab1of2';
import { Tab1of3 } from './tabs/Tab1of3';
import { Tab2 } from './tabs/Tab2';

export function EventIndex() {
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const { t } = useTranslation();
    const [nodeId, setNodeId] = React.useState<string>('1-1')
    const [page, setPage] = React.useState<number>(1)
    const perPageCount = 10;
    const type = new URLSearchParams(location.search).get('type')

    const sibarItem: SibarItem[] = [
        {
            nodeId: "1",
            title: "會議記錄",
            childs: [
                {
                    nodeId: "1-1",
                    title: "理監事會議"
                },
                {
                    nodeId: "1-2",
                    title: "會員大會"
                },
                {
                    nodeId: "1-3",
                    title: "研討會"
                }
            ]
        },
        {
            nodeId: "2",
            title: "其他活動",
        }
    ]
    React.useEffect(() => {
         if (type) setNodeId(sibarItem.find(e => e.title == type)?.nodeId || sibarItem[0].childs.find(e => e.title == type).nodeId)
    }, [type])

    const handleSelect = (event: React.SyntheticEvent, nodeId: string) => {
        if (nodeId != '1') {
            setNodeId(nodeId)
            history.push('/Event')
            setPage(1)
        }

    };

    return (<>
        <PageTitle title={sibarItem.find(e => e.nodeId == nodeId || e.childs?.find(i => i.nodeId == nodeId)).title} subtitle="活動資訊" />
        <Container sx={{ marginTop: "30px" }}>
            <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={2}
            >
                <Paper sx={{ width: 250, padding: "50px 20px", height: "max-content" }}>
                    <TreeView
                        aria-label="navigator"
                        sx={{ "& .MuiTreeItem-content": { padding: "5px 10px" } }}
                        onNodeSelect={handleSelect}
                        selected={nodeId}
                        defaultExpanded={['1']}
                    >
                        <PageSidbar sibarItem={sibarItem} />  
                    </TreeView>
                </Paper>
                <Box sx={{ width: { sm: "100%", md: "calc(100% - 200px)" }, }}>
                    <Tabs nodeId={nodeId} />
                </Box>
            </Stack>
        </Container>
    </>)
}

function Tabs(props: { nodeId: string }) {
    const { nodeId } = props;
    const location = useLocation();
    return (
        <Box sx={{ width: '100%' }}>
            <TabPanel value={nodeId} index={"1-1"}>
                <Tab1of1 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-2"}>
                <Tab1of2 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-3"}>
                <Tab1of3 />
            </TabPanel>
            <TabPanel value={nodeId} index={"2"}>
                <Tab2 />
            </TabPanel>
        </Box>
    );
}


