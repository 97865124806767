import * as React from "react";
import { Backdrop, Box, Button, CircularProgress, Dialog as HintDialog, Stack, Typography } from '@mui/material';
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone';
import { Trans } from "react-i18next";
import { ErrorText } from "./Typography";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

interface UploadProps {
    value?: string;
    uploadPath?: string;
    onChange: (value: File) => void;
    dropProps?: object;
    children: React.ReactNode;
    dropzoneRef?: React.Ref<DropzoneRef>;
    containerStyle?: React.CSSProperties;
    imgStyle?: React.CSSProperties;
    clearImage?: string[];
    width?: string | number;
    height?: string | number;
    textCenter?: boolean;
}

export function Upload(props: UploadProps) {
    const { value, uploadPath, onChange, dropProps, children,
        dropzoneRef, containerStyle, imgStyle, clearImage, width, height, textCenter } = props;
    const [file, setFile] = React.useState(null);
    const [errorMessages, setErrorMessage] = React.useState([]);
    const [waiting, setWaiting] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const onDropAccepted = <T extends File>(acceptedFiles: T[]) => {
        let upload = Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        })
        setErrorMessage([]);
        onChange(upload);
        setFile(upload);
    }

    const onDropRejected = (rejectedFile: FileRejection[]) => {
        let errorMessages = [];
        rejectedFile.forEach(file => file.errors.forEach(error => errorMessages.push(error.message)))
        setErrorMessage(errorMessages);
        setDialogMessage("上傳失敗");
        setOpenDialog(true);
    }

    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
            <p style={{ marginBottom: 0 }}>
                {errorMessages.map(message => (
                    <Typography color="error.main">{message}</Typography>
                ))}
            </p>
        </div>
    );

    React.useEffect(() => {
        if (file) {
            setFile(null);
        }
    }, [clearImage])

    return (<>
        <Dropzone
            ref={dropzoneRef}
            maxFiles={1}
            multiple={false}
            maxSize={2000000} //2MB
            accept='image/jpeg,image/jpg,image/png'
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
        >
            {({ getRootProps, getInputProps, acceptedFiles }) => (
                <Box className="w-100 h-100" {...getRootProps()}>
                    {/* @ts-ignore */}
                    <input {...getInputProps()} />

                    {file ?
                        <div style={containerStyle}>
                            <img src={file.preview}
                                width={width ?? `477px`}
                                height={height ?? `159px`}
                                style={{ objectFit: "cover", ...imgStyle }}
                            />
                        </div>
                        : value && value != "" ?
                            <div style={containerStyle}>
                                <img src={value}
                                    width={width ?? `477px`}
                                    height={height ?? `159px`}
                                    style={{ objectFit: "cover", ...imgStyle }}
                                />
                            </div>
                            : children
                    }
                </Box>
            )}
        </Dropzone>
        <span style={textCenter ? { textAlign: "center" } : null}><Trans i18nKey={'common.hint.imageUpload.uploadLimit'} />: 2MB</span>
        {errorMessages.map(message => (
            <Typography color="error.main">{message}</Typography>
        ))}
        <Backdrop open={waiting}><CircularProgress /></Backdrop>
        <HintDialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</HintDialog>
    </>)
}


export function UploadWithImg(props: UploadProps) {
    const { value, uploadPath, onChange, dropProps, children,
        dropzoneRef, containerStyle, imgStyle, clearImage, width, height, textCenter } = props;
    const [file, setFile] = React.useState(null);
    const [errorMessages, setErrorMessage] = React.useState([]);
    const [waiting, setWaiting] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const onDropAccepted = <T extends File>(acceptedFiles: T[]) => {
        let upload = Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        })
        setErrorMessage([]);
        onChange(upload);
        setFile(upload);
    }

    const onDropRejected = (rejectedFile: FileRejection[]) => {
        let errorMessages = [];
        rejectedFile.forEach(file => file.errors.forEach(error => errorMessages.push(error.message)))
        setErrorMessage(errorMessages);
        setDialogMessage("上傳失敗");
        setOpenDialog(true);
    }

    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        if (file) {
            setFile(null);
        }
    }, [clearImage])

    return (
        <>
            <Dropzone
                ref={dropzoneRef}
                maxFiles={1}
                multiple={false}
                maxSize={2000000} //2MB
                accept='image/jpeg,image/jpg,image/png'
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
            >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <Box className="w-100 h-100" {...getRootProps()}>
                        {/* @ts-ignore */}
                        <input {...getInputProps()} />

                        {
                            file ?
                                <div style={containerStyle}>
                                    <img src={file.preview} style={{
                                        width: width ?? `477px`,
                                        height: height ?? `159px`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        ...imgStyle
                                    }} />
                                </div>
                                : value && value != "" ?
                                    <div style={containerStyle}>
                                        <img src={value} style={{
                                            width: width ?? `477px`,
                                            height: height ?? `159px`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            ...imgStyle
                                        }} />
                                    </div>
                                    : children
                        }

                    </Box>
                )}
            </Dropzone>
            <span style={textCenter ? { textAlign: "center" } : null}><Trans i18nKey={'uploadLimit'} />: 2MB</span>
            {errorMessages.map(message => (
                <ErrorText>{message}</ErrorText>
            ))}
            <Backdrop open={waiting}><CircularProgress color="inherit" /></Backdrop>
            <HintDialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</HintDialog>
        </>
    )
}
interface UploadImgsProps extends Omit<UploadProps, 'onChange'> {
    values: string[];
    handleSetValue: (urls: string[]) => void;
    onChange: (values: File[]) => void;

}

export function UploadWithImgs(props: UploadImgsProps) {
    const { values, uploadPath, onChange, handleSetValue, dropProps, children,
        dropzoneRef, containerStyle, imgStyle, clearImage, width, height, textCenter } = props;
    const [files, setFiles] = React.useState(null);
    const [errorMessages, setErrorMessage] = React.useState([]);
    const [waiting, setWaiting] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const onDropAccepted = <T extends File>(acceptedFiles: T[]) => {
        let files = [];
        acceptedFiles.forEach(file => {
            let upload = Object.assign(file, {
                preview: URL.createObjectURL(file)
        })
                files.push(upload)
        } )
        setErrorMessage([]);
            onChange(files);
            setFiles(files);
    }

    const onDropRejected = (rejectedFile: FileRejection[]) => {
        let errorMessages = [];
        rejectedFile.forEach(file => file.errors.forEach(error => errorMessages.push(error.message)))
        setErrorMessage(errorMessages);
        setDialogMessage("上傳失敗");
        setOpenDialog(true);
    }

    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        if (files) {
            setFiles(null);
        }
    }, [clearImage])

    return (
        <>
            <Dropzone
                ref={dropzoneRef}
                maxFiles={10}
                multiple={true}
                maxSize={2000000} //2MB
                accept='image/jpeg,image/jpg,image/png'
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
            >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <Box className="w-100 h-100" {...getRootProps()}>
                        {/* @ts-ignore */}
                        <input {...getInputProps()} />

                        {
                      /*      files ? <Stack spacing={2}>{files.map((file, index) => <div style={{ ...containerStyle, position: 'relative' }}>
                                <img src={file.preview} style={{
                                    width: width ?? `477px`,
                                    height: height ?? `159px`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    maxWidth: '100%',
                                    ...imgStyle
                                }} /> 
                                <Box >
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            console.log(file)
                                            console.log(index)
                                            console.log(values)
                                           // handleSetValue(values.filter(v => v != value[]))
                                        }}
                                        sx={{ "position": "absolute", "top": "0", "right": "0px", "color": "red" }}
                                    >
                                        <DisabledByDefaultIcon />
                                    </Button>
                                </Box>
                            </div>)} </Stack>
                                :*/
                                values && values.length > 0 ?
                                    <Stack spacing={2}>
                                    {values.map(value => (<div style={{ ...containerStyle, position: 'relative' }} key={value }>
                                            <img src={value} style={{
                                                width: width ?? `477px`,
                                                height: height ?? `159px`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                ...imgStyle
                                            }} />

                                            <Box >{value}
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleSetValue(values.filter(v => v != value))
                                                    }}
                                                    sx={{ "position": "absolute", "top": "0", "right": "0px", "color": "red" }}
                                                >
                                                    <DisabledByDefaultIcon />
                                                </Button>
                                            </Box>
                                        </div>))
                                        }

                                    </Stack>
                                    : children
                        }

                    </Box>
                )}
            </Dropzone>
            <span style={textCenter ? { textAlign: "center" } : null}><Trans i18nKey={'uploadLimit'} />: 2MB</span>
            {errorMessages.map(message => (
                <ErrorText>{message}</ErrorText>
            ))}
            <Backdrop open={waiting}><CircularProgress color="inherit" /></Backdrop>
            <HintDialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</HintDialog>
        </>
    )
}
export function UploadWithPdf(props: UploadProps) {
    const { value, uploadPath, onChange, dropProps, children,
        dropzoneRef, containerStyle, imgStyle, clearImage, width, height, textCenter } = props;
    const [file, setFile] = React.useState(null);
    const [errorMessages, setErrorMessage] = React.useState([]);
    const [waiting, setWaiting] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const onDropAccepted = <T extends File>(acceptedFiles: T[]) => {
        let upload = Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        })
        setErrorMessage([]);
        onChange(upload);
        setFile(upload);
    }

    const onDropRejected = (rejectedFile: FileRejection[]) => {
        let errorMessages = [];
        rejectedFile.forEach(file => file.errors.forEach(error => errorMessages.push(error.message)))
        setErrorMessage(errorMessages);
        setDialogMessage("上傳失敗");
        setOpenDialog(true);
    }

    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        if (file) {
            setFile(null);
        }
    }, [clearImage])

    return (
        <>
            <Dropzone
                ref={dropzoneRef}
                maxFiles={1}
                multiple={false}
                maxSize={50000000} //50MB
                accept='.pdf'
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
            >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <Box className="w-100 h-100" {...getRootProps()}>
                        {/* @ts-ignore */}
                        <input {...getInputProps()} />

                        {
                            file ?
                                <div style={containerStyle}>
                                    { file.name}
                                </div>
                                : value && value != "" ?
                                    <div style={containerStyle}>
                                        {value}
                                    </div>
                                    : children
                        }

                    </Box>
                )}
            </Dropzone>
            <span style={textCenter ? { textAlign: "center" } : null}><Trans i18nKey={'uploadLimit'} />: 50MB</span>
            {errorMessages.map(message => (
                <ErrorText>{message}</ErrorText>
            ))}
            <Backdrop open={waiting}><CircularProgress color="inherit" /></Backdrop>
            <HintDialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</HintDialog>
        </>
    )
}
interface UploadAttachmentProps extends Omit<UploadProps, 'value'> {
    value: string
}

export function UploadWithAttachments(props: UploadAttachmentProps) {
    const { value, uploadPath, onChange, dropProps, children,
        dropzoneRef, containerStyle, imgStyle, clearImage, width, height, textCenter } = props;
    const [file, setFile] = React.useState(null);
    const [errorMessages, setErrorMessage] = React.useState([]);
    const [waiting, setWaiting] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const onDropAccepted = <T extends File>(acceptedFiles: T[]) => {
        let upload = Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        })
        setErrorMessage([]);
        onChange(upload);
        setFile(upload);
    }

    const onDropRejected = (rejectedFile: FileRejection[]) => {
        let errorMessages = [];
        rejectedFile.forEach(file => file.errors.forEach(error => errorMessages.push(error.message)))
        setErrorMessage(errorMessages);
        setDialogMessage("上傳失敗");
        setOpenDialog(true);
    }

    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        if (file) {
            setFile(null);
        }
    }, [clearImage])

    return (
        <>
            <Dropzone
                ref={dropzoneRef}
                maxFiles={1}
                multiple={false}
                maxSize={50000000} //50MB
                accept='.doc, .docx, .pdf, .xls, .xlsx, '
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
            >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <Box className="w-100 h-100" {...getRootProps()}>
                        {/* @ts-ignore */}
                        <input {...getInputProps()} />
                        {
                            file ?
                                <div style={containerStyle}>
                                    {file.name}
                                </div>
                                : value && value != "" ?
                                    <div style={containerStyle}>
                                        {value}
                                    </div>
                                    : children
                        }

                    </Box>
                )}
            </Dropzone>
            <span style={textCenter ? { textAlign: "center" } : null}><Trans i18nKey={'uploadLimit'} />: 50MB</span>
            {errorMessages.map(message => (
                <ErrorText>{message}</ErrorText>
            ))}
            <Backdrop open={waiting}><CircularProgress color="inherit" /></Backdrop>
            <HintDialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</HintDialog>
        </>
    )
}