import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";

export  function Tab1of4() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();

   
    return (<>
        <SubTitle title="組織結構" />
        <img src='./images/ee42ca544d5c4864d8af8d3a429f5e1b.jpg' width='100%' style={{maxWidth:'500px'}} />
    </>)
      
}
