import { TabPanel, TabPanelProps } from '@mui/lab';
import { Box, Container, styled, Toolbar } from '@mui/material';
import * as React from 'react';
import { PageTitleBarWithoutSticky, PageTitleBarWithoutStickyProps } from '../components/Layout/PageTitleBar';
import { pageView } from './sideBar';

const ManageBoardTitleBar: (props: PageTitleBarWithoutStickyProps) => JSX.Element = styled(PageTitleBarWithoutSticky)(({ theme }) => ({
    position: 'fixed',
    left: 0,
    right: 0,
    top: "64px",
    zIndex: 500,
    width: "calc(100% - 16px)",
    [theme.breakpoints.down("sm")]: {
        top: "56px",
    },
    "& .blankSpace": {
        width: "281px", //sideBarWidth(256) + 1 + padding(24)
        [theme.breakpoints.up("xl")]: {
            width: "calc(50vw - 480px)", //sideBarWidth(256) + 1 + padding(24) + morePadding((100vw - 256 - 1200 - 2*24 - 17 - 1)/2)
        },
        [theme.breakpoints.down("lg")]: {
            width: "124px", //customValue(100) + padding(24)
        },
        [theme.breakpoints.down("md")]: {
            width: "24px", //padding(24)
        },
        [theme.breakpoints.down("sm")]: {
            width: "16px", //padding(16)
        },
    }
}));
interface ManageBoardPanelProps extends TabPanelProps {
    view: pageView;
}
export default function ManageBoardPanel(props: ManageBoardPanelProps) {
    const { view, ...other } = props;

    return (
        <TabPanel {...other}>
            <Container>
                {view.list.map((element, index) => (
                    <Box key={`${index}:${element.className}`} className={element.className} style={{ minHeight: 300 }}>
                        {element.content}
                        <Toolbar />
                    </Box>
                ))}
            </Container>
        </TabPanel>
    )
};
