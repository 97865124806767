import { useTheme, Box, Container, Paper, Typography, Stack, Pagination, Button, CircularProgress } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'
import { SubTitle } from "../../components/Common";
import { EventType, Event } from "../../components/class";
import { useGetEventListQuery } from "../../components/services/event";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EventItem } from "./Tab1of1";
import { EventContent } from "./EventContent";


export  function Tab1of2() {
    const location = useLocation();
    const [page, setPage] = React.useState<number>(1)
    const perPageCount = 5;
    const [eventList, setEventList] = React.useState<Event[]>([])
    const sortList = eventList//.sort((a, b) => { return a.top == true ? -1 : 0 })
    const pages = Math.ceil(sortList.length / perPageCount);
    const showList = sortList.filter((n, index) => index >= perPageCount * (page - 1) && index < perPageCount * page)
    const id = new URLSearchParams(location.search).get('id')
    const { data, isLoading, isSuccess, refetch } = useGetEventListQuery(EventType.會員大會)

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    React.useEffect(() => {
        if (isSuccess) setEventList(data.data)
        if (data) refetch()

    }, [data])
    return (<>
        <SubTitle title="會員大會" />
        <br />
        {isLoading ? <Stack justifyContent='center' alignItems='center'>
            <CircularProgress />
        </Stack>
            :
            <>
                {id ?
                    <EventContent id={id} />
                    :
                    <>
                        <Stack direction="column" spacing={1.25}>
                            {showList.map(n => <EventItem data={n} type={EventType.會員大會} key={n.eventId} />)}
                        </Stack>
                        <Stack spacing={2} mt="20px" >
                            <Pagination count={pages} page={page} variant="outlined" shape="rounded" onChange={handleChange} />
                        </Stack>
                    </>
                }
            </>
        }

        < br />
    </>)

}
