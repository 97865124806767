import { Dialog, styled, TypographyProps } from "@mui/material";
import * as React from "react";
import { EventType, EventDetail, UserRole,} from "components/class";
import { useUser } from "store";
import { useLocation } from "react-router";
import { useDeleteEventMutation, useGetEventListQuery } from "../../components/services/event";
import { AddEventsPage } from "./AddEventsPage";
import { EditEventsPage } from "./EditEventsPage";
import { EventsList } from "./EventsList";

type pageState = 'list' | 'add' | 'edit' | 'view';

export default function EventsManage(props: { eventType: EventType }) {
    const query = new URLSearchParams(useLocation().search);
    const [code, setCode] = React.useState<string>(query.get("e"));
    React.useEffect(() => { setCode(query.get("e")) }, [query.get("e")]);
    const user = useUser();
    const [pageState, setPageState] = React.useState<pageState>('list');
    const type = Number(code);
    const [eventId, setEventId] = React.useState<string>('');
    const { data: eventList, isLoading: isGetEventListLoading, refetch, isFetching } = useGetEventListQuery(type);
    const [deleteNew, { isLoading: isDeleteNewLoading }] = useDeleteEventMutation();

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        setPageState('list');
        refetch()
    }, [code])

    function handleAddNew() {
        setPageState('add');
    }

    function handleEditNew(eventId: string) {
        setEventId(eventId)
        setPageState('edit');
    }

    function handleViewNew(eventId: string) {
        setEventId(eventId)
        setPageState('view');
    }

    function handleDeleteNew(eventId: string) {
        try {
            deleteNew(eventId).unwrap().then((data) => {
                if (data.isSuccess) {
                    refetch();
                }
                else {
                    console.log(data.message);
                    setDialogMessage(`刪除失敗：${data.message}`);
                    setOpenDialog(true);
                }
            });
        } catch (error) {
            console.log(error);
            setDialogMessage(`刪除失敗：${error}`);
            setOpenDialog(true);
        }

    }

    function handleBackToList() {
        setPageState('list');
    }

    function handleBackToListAndReftch() {
        setPageState('list');
        refetch()
    }

    if (!user || user.role < UserRole.administrator)
        return <></>;
    else
        return (
            <>
                {pageState == 'add' ? <AddEventsPage onBack={handleBackToList} complete={handleBackToListAndReftch} type={type } /> :
                    pageState == 'edit' ? <EditEventsPage onBack={handleBackToList} complete={handleBackToListAndReftch} eventId={eventId} /> :
                        <EventsList
                            onAdd={handleAddNew}
                            onEdit={handleEditNew}
                            onView={handleViewNew}
                            onDelete={handleDeleteNew}
                            isGetEventListLoading={isGetEventListLoading}
                            isFetching={isFetching}
                            eventList={eventList}
                            type={type }
                             />
                }
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
            </>)
}

