import { Dialog, styled, TypographyProps } from "@mui/material";
import * as React from "react";
import {  UserRole } from "components/class";
import { useUser } from "store";
import { UserList } from "./UserList";
import { useLocation } from "react-router";
import { AddUserPage } from "./AddUserPage";
import { EditUserPage } from "./EditUserPage";
import {  useGetUserListQuery } from "../../components/services/user";

type pageState = 'list' | 'add' | 'edit' | 'view';
type userType = '所有' | '個人會員' |'團體會員';

export default function UserManage() {
    const query = new URLSearchParams(useLocation().search);
    const [code, setCode] = React.useState<string>(query.get("e"));
    React.useEffect(() => { setCode(query.get("e")) }, [query.get("e")]);
    const user = useUser();
    const [pageState, setPageState] = React.useState<pageState>('list');
    const [type, setType] = React.useState<userType>('所有');
    const [userId, setUserId] = React.useState<string>('');
    const { data: userList, isLoading: isGetUserListLoading, refetch, isFetching } = useGetUserListQuery();
  

    //顯示訊息
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        setPageState('list');
        refetch()
    }, [code])
    
    function handleAddNew() {
        setPageState('add');
    }

    function handleEditNew(userId: string) {
        setUserId(userId)
        setPageState('edit');
    }

    function handleViewNew(userId: string) {
        setUserId(userId)
        setPageState('view');
    }
    function refetchDataList()  { refetch() }

  /*  function handleDeleteNew(userId: string) {
        try {
            deleteNew(userId).unwrap().then((data) => {
                if (data.isSuccess) {
                    refetch();
                }
                else {
                    console.log(data.message);
                    setDialogMessage(`刪除失敗：${data.message}`);
                    setOpenDialog(true);
                }
            });
        } catch (error) {
            console.log(error);
            setDialogMessage(`刪除失敗：${error}`);
            setOpenDialog(true);
        }

    }*/

    function handleBackToList() {
        setPageState('list');
    }

    function handleBackToListAndReftch() {
        setPageState('list');
        refetch()
    }

    if (!user || user.role < UserRole.administrator)
        return <></>;
    else
        return (
            <>
                {pageState == 'add' ? <AddUserPage onBack={handleBackToList} complete={handleBackToListAndReftch} /> :
                    pageState == 'edit' ? <EditUserPage onBack={handleBackToList} complete={handleBackToListAndReftch} userId={userId} /> :
                        <UserList
                            onAdd={handleAddNew}
                            onEdit={handleEditNew}
                            onView={handleViewNew}
                            refetchDataList={refetchDataList }
                            isGetUserListLoading={isGetUserListLoading}
                            isFetching={isFetching}
                            userList={userList}
                             />
                }
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
            </>)
}

