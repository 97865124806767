import { Avatar, Backdrop, Box, Button, ButtonProps, CircularProgress, LinearProgress, Stack, Typography } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { ResultType } from "../../components/services/utils";
import { SubTitle } from "../../components/Common";
import { zhTWGrid } from "../data-grid-locale";
import { AddButton, CyanBackStageButton, GreenBackStageButton, GreyBackStageButton, RedBackStageButton } from "../components";
import { NewsType, News, UserData, UserType, UserState } from "../../components/class";
import { useChangeUserStateMutation, useEditUserMutation, useLazyGetUserDetailQuery } from "../../components/services/user";
import { createSvgIcon } from '@mui/material/utils';
import {
    GridCsvExportOptions,
    GridCsvGetRowsToExportParams,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridSortedRowIdsSelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiContext,
} from '@mui/x-data-grid';

interface NewsListProps {
    onAdd: () => void;
    onEdit: (userId: string) => void;
    onView: (userId: string) => void;
    refetchDataList: () => void;
   // onDelete: (userId: string) => void;
    userList: ResultType<UserData[]>;
    isGetUserListLoading: boolean;
    isFetching: boolean;
}



export function UserList(props: NewsListProps) {
    const { onAdd, onEdit, onView, refetchDataList, userList, isGetUserListLoading, isFetching } = props;
    const [type, setType] = React.useState<UserType>(null)



    return (<>
        <Box display='flex'>
            <SubTitle title='會員管理'/>
        </Box>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            margin='10px auto'
        >
            <Stack
                direction="row"
                spacing={2}
            >
                <Box onClick={(e) => { setType(null) }} sx={{ fontWeight: type == null ? 'bold' : '', cursor: 'pointer' }}>所有會員</Box>
                <Box onClick={(e) => { setType(UserType.一般會員) }} sx={{ fontWeight: type == UserType.一般會員 ? 'bold' : '', cursor: 'pointer' }}>一般會員</Box>
                <Box onClick={(e) => { setType(UserType.團體會員) }} sx={{ fontWeight: type == UserType.團體會員 ? 'bold' : '', cursor: 'pointer' }}>團體會員</Box>
        </Stack>

        <AddButton onClick={onAdd} sx={{ my: 1.5 }}>
            新增會員
        </AddButton>
        </Stack>
        <DataList
            dataList={userList?.data.filter(data => type!=null? data.type == type:true).map(item => ({ ...item, id: item.userId })) ?? []}
            handleEditUser={onEdit}
            handleViewUser={onView}
            loading={isGetUserListLoading}
            isFetching={isFetching}
            refetchDataList={refetchDataList }
        />
    </>);
}

interface GridDataRow extends UserData {
    id: string;
}

interface DataListProps {
    dataList: GridDataRow[];
    handleEditUser: (userId: string) => void;
    handleViewUser: (userId: string) => void;
    refetchDataList: () => void;
 //   handleDeleteUser: (userId: string) => void;
    loading?: boolean;
    isFetching: boolean;
}
function DataList(props: DataListProps) {
    const { dataList, loading, handleEditUser, isFetching, refetchDataList } = props;
    const [changeUserState, { isLoading,  }] = useChangeUserStateMutation()
   // const showList = dataList.filter(new=>new)

    async function handleChangeState(userId, state) {
        changeUserState({ userId, state }).unwrap().then(res => { if(res.isSuccess)refetchDataList() })
        
    }

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'account',
            headerName: '會員帳號',
            width: 120,
            sortable: false,
        },
        {
            field: 'name',
            headerName: '會員姓名/團體名稱',
            width: 140,
            sortable:false,
        },   
        {
            field: 'actions',
            headerName: '操作',
            disableExport: true,
            filterable: false,
            sortable: false,
            width: 85,
            renderCell: ({ row: { userId } }: GridRenderCellParams<GridDataRow>) => (
                <Stack direction='row' spacing={1}>
                    <GreenBackStageButton onClick={() => handleEditUser(userId)}>
                        編輯
                    </GreenBackStageButton>
                    {/*<RedBackStageButton variant='contained' onClick={() => handleDeleteUser(userId)} >
                        刪除
                    </RedBackStageButton>*/}
                </Stack>
            )
        },
        {
            field: 'state',
            headerName: '會員資格',
            width: 160,
            disableExport: true,
          //  filterable: false,
            renderCell: ({ row: { userId, state } }: GridRenderCellParams<GridDataRow>) => (
                <Stack direction='row' spacing={1}>
                    <Button variant='outlined' sx={{ borderColor: '#4EB596', color: state == 1 ? '#fff' : '#4EB596', backgroundColor: state == 1 ? '#4EB596' : 'inherit' }} onClick={() => { handleChangeState(userId, UserState.啟用) }}>啟用</Button>
                    <Button variant='outlined' sx={{ borderColor: '#A9A9A9', color: state == 2 ? '#fff' : '#A9A9A9', backgroundColor: state == 2 ? '#A9A9A9' : 'inherit' }} onClick={() => { handleChangeState(userId, UserState.停權) }}>停權</Button>
                    {/*<RedBackStageButton variant='contained' onClick={() => handleDeleteUser(userId)} >
                        刪除
                    </RedBackStageButton>*/}
                    {state == 0 && <Typography color="#FBA65A">*待審核</Typography>}
                </Stack>
            ),
            valueGetter: ({ value }) => value==0?'待審核':value==1?'啟用':'停權',

        },  
        {
            field: 'type',
            headerName: '類別',
            width: 80,
            valueGetter: ({ value }) => UserType[value],
            valueFormatter: ({ value }) => value,

        },
        {
            field: 'mobilephone',
            headerName: '手機號碼',
            width: 100,
            type:'string',
            valueGetter: ({ value }) => "/"+value ,
            renderCell: ({ value }) => value.replace('/', ''),

        },
        {
            field: 'telephone',
            headerName: '電話',
            hide:true,
        },
        {
            field: 'email',
            headerName: '電子信箱',
            width: 250,
        },
        {
            field: 'job',
            headerName: '現職',
            width: 250,
        },
        {
            field: 'experience',
            headerName: '經歷',
            hide: true,
        },
        {
            field: 'address',
            headerName: '地址',
            width: 250,
            hide: true,
        },
        
   
    ];

    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt',
    );

    const buttonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon />,
    };
    function CustomToolbar() {
        const apiRef = useGridApiContext();
    const handleExport = (options: GridCsvExportOptions) =>
        apiRef.current.exportDataAsCsv(options);


        return (
            <GridToolbarContainer>
                {/*@ts-ignore*/}
                <GridToolbarFilterButton />
                <Button
                    {...buttonBaseProps}
                    onClick={() => handleExport({ utf8WithBom: true })}
                >
                    匯出篩選後資料
                </Button>         <Button
                    {...buttonBaseProps}
                    onClick={() => handleExport({ utf8WithBom: true, allColumns: true, })}
                >
                    匯出所有會員資料
                </Button>
            </GridToolbarContainer>
        );
    }

    return (<>
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoPageSize
            autoHeight
            //  rowHeight={100}
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress
            }}
            localeText={zhTWGrid}
            loading={loading || isFetching}
        />
        <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: '99' }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
    )
}