import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SubTitle } from "../../components/Common";
import { GroupList } from "../../components/GroupList";

export function Tab4() {
    return (<>
        <SubTitle title="秘書處" />
        <br />
        <Typography variant='subtitle1' color="#3F3F44" fontWeight='bold'>第45屆秘書處工作同仁任務編組</Typography>
        <br />
        {
            GroupList([
                {
                    gruop: "秘書長",
                    list: [
                        { name: "沈軍廷", info: "國立陽明交通大學 全球公民教育中心老師" },
                    ]
                },
                {
                    gruop: "副秘書長",
                    list: [
                        { name: "王秀蓮", info: "靜宜大學 學生成就中心組員" },
                        { name: "邱懋峮", info: "大仁科技大學 休閒運動管理系主任兼所長" },
                        { name: "王克誠", info: "玄奘大學 體育衛保組長" },
                    ]
                },
                {
                    gruop: "執行秘書",
                    list: [
                        { name: "許鶴永", info: "臺北醫學大學 生活輔導組老師" },
                        { name: "賴佳琪", info: "臺北醫學大學 課外活動組老師" },
                        { name: "江翊菱", info: "朝陽科技大學 課外活動組老師" },

                    ]
                },
                {
                    gruop: "會計",
                    list: [
                        { name: "陳欣怡", info: "東吳大學 德育中心組員" },

                    ]
                },
            ])}
        <br />
        <Typography variant='subtitle1' color="#3F3F44" fontWeight='bold'>第44屆秘書處工作同仁任務編組</Typography>
        <br />
        {
            GroupList([
                {
                    gruop: "秘書長",
                    list: [
                        { name: "沈軍廷", info: "國立陽明交通大學 全球公民教育中心老師" },
                    ]
                },
                {
                    gruop: "副秘書長",
                    list: [

                        { name: "王克誠", info: "玄奘大學 學生輔導中心主任" },
                        { name: "邱懋峮", info: "大仁科技大學 休閒運動管理系主任(前學務長)" },
                        { name: "王秀蓮", info: "靜宜大學 課外活動組長" },

                    ]
                },
                {
                    gruop: "執行秘書",
                    list: [
                        { name: "陳佳慧", info: "玄奘大學 宗教與文化學系秘書" },
                        { name: "江翊菱", info: "新生醫護管理專科學校 課外活動指導組老師" },
                        { name: "許鶴永", info: "臺北醫學大學 生活輔導組老師" },
                        { name: "賴佳琪", info: "臺北醫學大學 課外活動組老師" },

                    ]
                },
                {
                    gruop: "會計",
                    list: [
                        { name: "陳欣怡", info: "東吳大學 德育中心老師" },

                    ]
                },
            ])}
        <br />
        <Typography variant='subtitle1' color="#3F3F44" fontWeight='bold'>第43屆秘書處工作同仁任務編組</Typography>
        <br />
        {
            GroupList([
                {
                    gruop: "秘書長",
                    list: [
                        { name: "陳建森", info: "馬偕醫護管理專科學校體育運動組組長" },
                    ]
                },
                {
                    gruop: "副秘書長",
                    list: [
                        { name: "黃明雪", info: "德明財經科技大學課外活動指導組長" },
                    ]
                },
                {
                    gruop: "執行秘書",
                    list: [
                        { name: "劉玉萍", info: "德明財經科技大學課外活動指導組社團與服務學習輔導老師" },
                    ]
                },
                {
                    gruop: "秘書",
                    list: [
                        { name: "李國誠", info: "德明財經科技大學課外活動指導組社團與服務學習輔導老師" },
                        { name: "諶威瑀", info: "德明財經科技大學課外活動指導組社團與服務學習輔導老師" },
                    ]
                },
                {
                    gruop: "會計",
                    list: [
                        { name: "莊凱媖", info: "德明財經科技大學生輔組老師" },
                    ]
                },
            ])}
        <br />
        <Typography variant='subtitle1' color="#3F3F44" fontWeight='bold'>第42屆秘書處工作同仁任務編組</Typography>
        <br />
        {
            GroupList([
                {
                    gruop: "秘書長",
                    list: [
                        { name: "陳建森", info: "馬偕醫護管理專科學校體育運動組組長" },
                    ]
                },
                {
                    gruop: "副秘書長",
                    list: [
                        { name: "黃明雪", info: "德明財經科技大學課外活動指導組長" },

                    ]
                },
                {
                    gruop: "執行秘書",
                    list: [
                        { name: "郭沛璇", info: "德明財經科技大學品格教育中心組員" },

                    ]
                },
                {
                    gruop: "秘書",
                    list: [
                        { name: "洪維倫", info: "德明財經科技大學課外活動指導組社團與服務學習輔導老師" },
                        { name: "歐欣樺", info: "德明財經科技大學課外活動指導組社團與服務學習輔導老師" },

                    ]
                },
                {
                    gruop: "會計",
                    list: [
                        { name: "劉玉萍", info: "德明財經科技大學課外活動指導組社團與服務學習輔導老師" },

                    ]
                },
            ])}
        <br />
        <Typography variant='subtitle1' color="#3F3F44" fontWeight='bold'>第41屆秘書處工作同仁任務編組</Typography>
        <br />
        {
            GroupList([
                {
                    gruop: "秘書長",
                    list: [
                        { name: "黃世雄", info: "曾任致理科技大學通識中心主任、前學務長" },
                    ]
                },
                {
                    gruop: "副秘書長",
                    list: [

                        { name: "陳新霖", info: "元智大學學務處諮商與就業輔導組專員/社工師" },

                    ]
                },
                {
                    gruop: "活動組長",
                    list: [

                        { name: "陳明國", info: "國立臺灣師範大學公領所博士生" },

                    ]
                },
                {
                    gruop: "活動組秘書",
                    list: [

                        { name: "李立旻", info: "國立臺灣師範大學公民教育與活動領導學系專責導師" },
                        { name: "洪敏貴", info: "致理科技大學課外活動組辦事員" },

                    ]
                },
                {
                    gruop: "資訊組組長",
                    list: [

                        { name: "李育齊", info: "國立臺灣師範大學學務處全人教育中心博士研發管理師" },

                    ]
                },
                {
                    gruop: "資訊組秘書",
                    list: [
                        { name: "蕭佩蘭", info: "國立臺灣師範大學學務處專責導師室組員" },

                    ]
                },
                {
                    gruop: "行政組組長",
                    list: [
                        { name: "廖芝萱", info: "東吳大學群育中心專員" },

                    ]
                },
                {
                    gruop: "行政組秘書",
                    list: [
                        { name: "張鳳君", info: "玄奘大學學務處課指組組員" },

                    ]
                },
            ])}
        <br />
        <Typography variant='subtitle1' color="#3F3F44" fontWeight='bold'>第40屆秘書處工作同仁任務編組</Typography>
        <br />
        {
            GroupList([
                {
                    gruop: "秘書長",
                    list: [
                        { name: "林至善", info: "玄奘大學學務長" },
                    ]
                },
                {
                    gruop: "副秘書長",
                    list: [

                        { name: "黃世雄", info: "致理技術學院 學務長" },

                    ]
                },
                {
                    gruop: "秘書",
                    list: [
                        { name: "洪敏貴", info: "致理科技大學課外活動組辦事員" },
                        { name: "廖芝萱", info: "東吳大學群育中心專員" },
                        { name: "張鳳君", info: "玄奘大學學務處課指組組員" },
                        { name: "楊卓圜", info: "玄奘大學學務處課指組組員" },
                    ]
                },

            ])}
    </>)

}
