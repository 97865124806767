import { useTheme, Box, Container, Paper, Typography, Stack, Select, MenuItem, SelectChangeEvent, FormControl, Card, CardContent, CardMedia, Grid, Pagination } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";
import { Publication, PublicationType } from "../../components/class";
import { PublicationContent } from "./PublicationContent";
import { useDispatchWithType, useSelectedPage, useSelectedYear } from "../../store";
import { setSelectedPage, setSelectedYear } from "../../store/rootReducer";
import { useGetPublicationListQuery } from "../../components/services/publication";

export function Tab2() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const [dataList, setDateList] = React.useState<Publication[]>([])
    const location = useLocation();

    const type = new URLSearchParams(location.search).get('type')
    const publicationId = new URLSearchParams(location.search).get('publicationId')


    const testList:Publication[] = [
        {
            publicationId: '123',
            title: '測試用標題',
            type: PublicationType.研討會簡報,
            imageUrl: 'https://fakeimg.pl/317x350',
            publicationDate: '2022/1/19',
    createTime: '2022.9.11'
        }, {
            publicationId: '1234',
            title: '測試用標題2',
            type: PublicationType.學生事務與輔導季刊,
            imageUrl: 'https://fakeimg.pl/317x350',
            publicationDate: '2022/5/29',
    createTime: '2022.9.11'
        }, {
            publicationId: '1234567',
            title: '測試用標題3',
            type: PublicationType.學生事務與輔導季刊,
            imageUrl: 'https://fakeimg.pl/317x350',
            publicationDate: '2023/1/5',
    createTime: '2022.9.11'

        },
    ]

    const { data, isLoading, isSuccess } = useGetPublicationListQuery(0)

    React.useEffect(() => {
        if (isSuccess) setDateList(data.data)
    }, [data])



    return (<>
        <SeminarList dataList={dataList} />
    </>)

}

function SeminarList(props: { dataList: Publication[] }) {

    const { dataList } = props;
 //   const [age, setAge] = React.useState('');
    //const [page, setPage] = React.useState<number>(1)
    const page = useSelectedPage();
    const age = useSelectedYear();
    const perPageCount = 6;
    const sortList = dataList.filter(d => !age ? true : new Date(d.publicationDate).getFullYear().toString() == age).sort((a, b) => { return new Date(a.publicationDate).getTime() - new Date(b.publicationDate).getTime() })
    const pages = Math.ceil(sortList.length / perPageCount);
    const showList = sortList.filter((n, index) => index >= perPageCount * (page - 1) && index < perPageCount * page)
    const dispatch = useDispatchWithType();
    const gruopByYear = (dataList: Publication[]) => {
        return dataList.reduce((accumulator, currentValue) => {
            const dataYear = new Date(currentValue.publicationDate).getFullYear().toString();
            return accumulator.includes(dataYear) ? accumulator : [...accumulator, dataYear];
        }, [])
    }
   

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
       dispatch(setSelectedPage(value));

    };

    const handleAgeChange = (event: SelectChangeEvent) => {
        dispatch(setSelectedYear(event.target.value));
        dispatch(setSelectedPage(1));
    };

    return (
        <Stack
            direction="column"
            spacing={{ sx: 0, md: 2 }}
            paddingLeft={{ sx: '0px', md: '24px' }}
            paddingBottom='40px'
        >
            <Select
                value={age}
                onChange={handleAgeChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                    width: '183px',
                    marginLeft: '16px',
                    '& legend': { display: 'none' },
                    marginBottom: { xs: '20px', md: '0px' }
                }}

            >
                <MenuItem value="">所有年份</MenuItem>
                {gruopByYear(dataList).map(data => <MenuItem key={data} value={data}>{data}</MenuItem>)}
            </Select>
            <Grid container spacing={2} >
                {showList.map(data => <Grid
                    item key={data.publicationId}
                    xs={6} md={4}
                ><SeminarCard data={data} /></Grid>)}
            </Grid>
            <Stack spacing={2} mt="20px" >
                <Pagination count={pages} page={page} variant="outlined" shape="rounded" onChange={handleChange} />
            </Stack>
        </Stack>)
}



function SeminarCard(props: { data: Publication }) {
    const { data } = props;
    const history = useHistory();

    const handleClick = () => {
        history.push(`/Publication?type=${PublicationType[data.type]}&publicationId=${data.publicationId}`)
    }
    return <Card sx={{ padding: '12px', backgroundColor:'#fff' }} >
        <CardMedia
            component="img"
            image={data.imageUrl}
            alt="Paella dish"
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}
        />
        <CardContent>
            <Typography variant='subtitle1' onClick={handleClick} sx={{ cursor: 'pointer'} }> {data.title}</Typography>
        </CardContent>
        </Card>
}
