import { grey } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

export interface TypeOfCard {
    unknown: string;
    title: string;
    theme1: string;
    //theme2: string;
    theme3: string;
    subText: string;
}
declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        black: string;
        first: string;
        second: string;
        third: string;
        fourth: string;
        fifth: string;
        dividing: string;
    }
    interface TypeText {
        white: string;
        danger: string;
        title: string;
        theme1: string;
        //theme2: string;
        theme3: string;
        subText: string;
        highlight1: string;
        highlight2: string;
    }
}
declare module '@mui/styles' {
    interface DefaultTheme extends Theme { }
}
declare module '@mui/material/styles' {
    interface Palette {
        appBar: Palette['primary'];
    }
    interface PaletteOptions {
        appBar: PaletteOptions['primary'];
    }
}
let mainTheme = createTheme({
    palette: {
        primary: {
            light: '#6BA7C0',
            main: '#546C99',
            dark: '#626262',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#D1AB88',
            main: '#BB9776',
            dark: '#F0995A',
            contrastText: '#ffffff',
        },
        background: {
            paper: '#F5F6F8',
            default: '#ffffff',
            black: '#101010',
            first: '#EEEEEE',
            second: '#F5F6F8',
            third: '#E8F0FB',
            fourth: '#F1E9D8',
            fifth: '#BEDAE0',
            dividing:'#E2E2E2'
        },
        appBar: {
            light: '#5b636a',
            main: "#343a40",
            contrastText:"#f5ffff"
        },
        text: {    
            title: '#32425F',
            theme1: '#546C99',
            theme3: '#BB9776',
            subText: '#707070',
            highlight1: '#FBA65A',
            highlight2: '#4EB596',
            danger: '#DA2B48',

        }
    },
    typography: {
        fontFamily: ["Roboto", "Helvetica", "Noto Sans TC", "Arial", "sans-serif"].join(','),
    },
    components: {
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    margin: 'auto'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordBreak: 'break-word'
				}
			}
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        }
    },
});

mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
