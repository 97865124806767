import { ErrorMessage } from '@hookform/error-message';
import {
    Box, ButtonBase, Fade, IconButton, InputAdornment, Paper, Popper, Stack, Toolbar, Typography, useTheme,
    Select, SelectChangeEvent, MenuItem, Radio, RadioGroup, FormControlLabel, Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { HelpOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { zhTW } from 'date-fns/locale';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { important, TextField } from '../components/responsive-components';
import { ApplyUserModel, useApplyUserMutation } from '../components/services/user';
import { UserRole, UserType } from '../components/class';
import { useHistory, useLocation } from 'react-router-dom';
import { sub } from 'date-fns';

export function ApplyMember() {

    const theme = useTheme();
    const history = useHistory();

    const schema = yup.object().shape({
        account: yup.string().trim().required('必填'),
        password: yup.string().trim().required('必填'),
        name: yup.string().trim().required('必填'),
        birthday: yup.date().required('必填').typeError('日期格式錯誤'),
        telephone: yup.string().trim().required('必填'),
        mobilephone: yup.string().trim().required('必填'),
        fax: yup.string().trim(),
        email: yup.string().trim().required('必填'),
        job: yup.string().trim(),
        experience: yup.string().trim(),
        address: yup.string().trim().required('必填'),
    });

    const { register, watch, formState: { errors }, handleSubmit, setValue, control } = useForm<ApplyUserModel>({
        mode: 'onChange', resolver: yupResolver(schema),
        defaultValues: {
            account: '',
            password: '',
            name: '',
            type: UserType.一般會員,
            gender: '男性',
            birthday: new Date(),
            telephone: '',
            mobilephone: '',
            fax: '',
            email: '',
            job: '',
            experience: '',
            address: '',
            comment: '',
        }
    });

    // 顯示密碼
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword(show => !show);
    }

    // 一般/團體會員
    const [selectedCategory, setSelectedCategory] = React.useState<number>(0);
    const handleCategoryChange = (event: SelectChangeEvent) => {
        setSelectedCategory(Number(event.target.value));
    };

    // 性別
    const [selectedGender, setSelectedGender] = React.useState<string>('男性');
    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedGender(event.target.value);
    };

    // 是否劃撥付款
    const [selectedPay, setSelectedPay] = React.useState<string>('是');
    const handlePayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPay(event.target.value);
    };

    // 生日
    const [birthDate, setBirthDate] = React.useState<Date>(sub(new Date(), { years: 18 }));
    function handleBirthDateChange(date: Date) {
        setBirthDate(date);
        setValue("birthday", date);
    }

    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);

    const [applyUser, { isLoading }] = useApplyUserMutation();
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    function onApplySubmit(data: ApplyUserModel) {
        data.type = selectedCategory == 0 ? UserType.一般會員 : UserType.團體會員;
        data.comment = "是否已劃撥付款：" + selectedPay
        console.log(data);

        setErrorMessage("");
        applyUser(data).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    let id: string = result.data;
                    console.log(id);
                    setIsSubmit(true);
                }
                else {
                    setErrorMessage(result.message);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    // 跳轉 
    const handleClickLink = (link: string) => {
        history.push(link);
    }

    return (<>

        <Box component="form" onSubmit={handleSubmit(onApplySubmit)}
            sx={{ backgroundColor: "background.fourth", display: "flex", justifyContent: "center", alignItems: "center", minHeight: "600px" }}>
            <Box sx={{
                width: "600px", backgroundColor: "background.paper", borderRadius: "12px", mt: 3, mb: 3, py: 5, px: 10
            }}>
                {!isSubmit ? <>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
                        <Typography fontSize="22px" color="text.title">申請會員</Typography>
                        <Box sx={{ width: "400px", height: "2px", backgroundColor: "primary.light" }} />
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", mt: 3 }}>
                            <Button variant="outlined" sx={{ width: "120px", height: "35px", mx: 1, color: "primary.light" }}
                                onClick={() => handleClickLink("/")}
                            >
                                回到登入
                            </Button>
                            <Button variant="outlined" sx={{ width: "120px", height: "35px", mx: 1, color: "primary.light" }}
                                onClick={() => handleClickLink("/MemberService")}
                            >
                                入會辦法
                            </Button>
                        </Box>
                    </Box>

                    {/*帳號*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            帳號 {important}
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.account}
                            helperText={errors.account ? errors.account.message : ' '}
                            {...register("account")}
                            disabled={isLoading}
                            placeholder="帳號"
                        />
                    </Box>

                    {/*密碼*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            密碼 {important}
                        </Typography>
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            required
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ' '}
                            {...register("password")}
                            disabled={isLoading}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end" className="ml-1">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            placeholder="密碼"
                        />
                    </Box>

                    {/*姓名*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            {selectedCategory==0?"姓名":"聯絡人"} {important}
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.name}
                            helperText={errors.name ? errors.name.message : ' '}
                            {...register("name")}
                            disabled={isLoading}
                            placeholder="必填"
                        />
                    </Box>

                    {/*身分*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            身分 {important}
                        </Typography>
                        <Select
                            {...register("type")}
                            variant='outlined'
                            size="small"
                            margin="dense"
                            value={selectedCategory.toString()}
                            onChange={handleCategoryChange}
                            sx={{ mt: 1, "& .MuiOutlinedInput-notchedOutline legend": { display: "none", } }}
                        >
                            <MenuItem value={0}>個人會員</MenuItem>
                            <MenuItem value={1}>團體會員</MenuItem>
                        </Select>
                    </Box>

                    {/*性別*/}
                    <Box flex='1 0' className="d-flex flex-column mt-4 justify-content-end" sx={{ my: 3 }}>
                        <Typography variant='subtitle1'>
                            性別 {important}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <RadioGroup
                                {...register("gender")}
                                value={selectedGender}
                                onChange={handleGenderChange}
                                sx={{ display: "flex", flexDirection: "row" }}
                            >
                                <FormControlLabel value="男性" control={<Radio />} label="男性" />
                                <FormControlLabel value="女性" control={<Radio />} label="女性" />
                            </RadioGroup>
                        </Box>
                    </Box>

                    {/*生日*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            出生年月日(格式: yyyy-mm-dd) {important}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhTW}>
                                <DatePicker
                                    value={birthDate}
                                    onChange={(date) => handleBirthDateChange(date)}
                                    inputFormat="yyyy-MM-dd"
                                    mask="____-__-__"
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={!!errors.birthday}
                                            helperText={errors.birthday ? errors.birthday.message : ' '}
                                        />
                                    }
                                    disabled={isLoading}
                                />
                            </LocalizationProvider >
                        </div>
                    </Box>

                    {/*連絡電話*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            連絡電話(格式: XX-XXXXXXXX) {important}
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.telephone}
                            helperText={errors.telephone ? errors.telephone.message : ' '}
                            {...register("telephone")}
                            disabled={isLoading}
                        />
                    </Box>

                    {/*手機*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            手機(格式: 09XX-XXXXXX) {important}
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.mobilephone}
                            helperText={errors.mobilephone ? errors.mobilephone.message : ' '}
                            {...register("mobilephone")}
                            disabled={isLoading}
                        />
                    </Box>

                    {/*傳真*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            傳真機(格式: XX-XXXXXXXX)
                        </Typography>
                        <TextField
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.fax}
                            helperText={errors.fax ? errors.fax.message : ' '}
                            {...register("fax")}
                            disabled={isLoading}
                        />
                    </Box>

                    {/*電子信箱*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            電子信箱 {important}
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : ' '}
                            {...register("email")}
                            disabled={isLoading}
                        />
                    </Box>

                    {/*現職*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            現職
                        </Typography>
                        <TextField
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.job}
                            helperText={errors.job ? errors.job.message : ' '}
                            {...register("job")}
                            disabled={isLoading}
                        />
                    </Box>

                    {/*經歷*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            經歷
                        </Typography>
                        <TextField
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.experience}
                            helperText={errors.experience ? errors.experience.message : ' '}
                            {...register("experience")}
                            disabled={isLoading}
                        />
                    </Box>

                    {/*通訊地址*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                        <Typography variant='subtitle1'>
                            通訊地址 {important}
                        </Typography>
                        <TextField
                            required
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.address}
                            helperText={errors.address ? errors.address.message : ' '}
                            {...register("address")}
                            disabled={isLoading}
                        />
                    </Box>

                    {/*劃撥*/}
                    <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end" sx={{ my: 3 }}>
                        <Typography variant='subtitle1'>
                            是否已劃撥付款 {important}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <RadioGroup
                                value={selectedPay}
                                onChange={handlePayChange}
                                sx={{ display: "flex", flexDirection: "row" }}
                            >
                                <FormControlLabel value="是" control={<Radio />} label="是" />
                                <FormControlLabel value="否" control={<Radio />} label="否" />
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
                        <LoadingButton type="submit" variant="contained" loading={isLoading}
                        sx={{ width: "360px", backgroundColor: "primary.light", mt: 2 }}>
                            送出申請
                        </LoadingButton>
                    </Box>
                    </>
                    :
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
                        <Typography fontSize="22px" color="text.title">申請會員</Typography>
                        <Box sx={{ width: "400px", height: "2px", backgroundColor: "primary.light" }} />
                        <Typography fontSize="16px" sx={{ mx: 2, my: 4 }}>已送出會員申請，請等待審核，結果將以電子郵件通知。</Typography>
                        <Button variant="outlined" sx={{ width: "120px", height: "35px", mx: 1, color: "primary.light" }}
                            onClick={() => handleClickLink("/")}
                        >
                            回到首頁
                        </Button>
                    </Box>
                }
            </Box>
        </Box>
    </>);
}

interface ApplyUserProps {
    onComplete: () => void;
}