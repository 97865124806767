import * as React from "react";
import { AppBar, Avatar, Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import Translation from "../Translation";
import { useTranslation } from "react-i18next";
import { useUser } from "../../store";
import { useHistory, useLocation } from "react-router-dom";
import { UserRole } from "../class";
import { ScrollToTop } from "../ScrollToTop";
import { Menu } from "@mui/icons-material";
import { SxProps } from '@mui/system';
import { ExpandedButton, PopOutButton } from "./NavBarButton";
import { logEvent } from "../Analytics";
import { scroller } from "react-scroll";
import { NavSideBar } from "./NavSideBar";

const navItemList: NavBarItem[] = [
    { name: '認識學會', enName: 'About', link: '/About' },
    { name: '最新消息', enName: 'News', link: '/News' },
    { name: '學會刊物', enName: 'Publication', link: '/Publication' },
    { name: '活動資訊', enName: 'Event', link: '/Event' },
    { name: '會員服務', enName: 'MemberService', link: '/MemberService' },
    { name: '相關網站', enName: 'RelatedSite', link: '/RelatedSite' },
];

export const NavBar = (props: NavBarProps) => {
    const user = useUser();
    const history = useHistory();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();

    //控制左上角顯示
    const [isHomePage, setIsHomePage] = React.useState<boolean>(true);
    const [showTop, setShowTop] = React.useState<boolean>(true);
    const pathName = useLocation().pathname;
    React.useEffect(() => {
        let matches = [/\/(\?.+)?$/];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setShowTop(true);
            setIsHomePage(true);
        }
        else {
            setIsHomePage(false);
        }
    }, [pathName]);

    const handleClickLink = (link: string, event) => {
        event.preventDefault();
        if (link) {
            logEvent(`AppBar`, `Link`, `${link}`);

            //首頁觸發scroll
            /*
            if (showTop) {
                scroller.scrollTo(link.split('#')[1], null);
            }
            else {
                
            }
            */
            history.push(link);
        }
    }
    const handleClickItem = (item: NavBarLinkItem, event) => {
        event.preventDefault();
        if (item.link) {
            logEvent(`AppBar`, `Link`, `${item.link}`);

            //首頁觸發scroll
            /*
            if (showTop) {
                scroller.scrollTo(item.link.split('#')[1], null);
            }
            else {
                
            }
            */
            history.push(item.link);
        }
        if (item.onClick) {
            item.onClick(event);
        }
    };


    const [items, setItems] = React.useState<NavBarItem[]>([]);
    function isPass(item: NavBarItem) {
        if (!item.anonymousOnly || user?.role === UserRole.anonymous || !user) {
            if (!item.adminOnly || user?.role >= UserRole.administrator) {
                return true;
            }
        }
        return false;
    }
    function checkNavItemList(itemList: NavBarItem[]) {
        let navItems: NavBarItem[] = [];
        itemList.forEach(navItem => {
            if (isPass(navItem)) {
                if (isMenuItem(navItem)) {
                    navItem.itemList = navItem.itemList.filter(isPass);
                }
                navItems.push(navItem);
            }
        });
        return navItems;
    }
    React.useLayoutEffect(() => {
        setItems(checkNavItemList(navItemList));
    }, [user]);
    // position: "fixed", background: `url('${BarImg}')`,


    return (<>
        {showTop && <>
            {isMobile ?
                <Box sx={{
                    display: "flex",
                    position: "fixed",
                    top: "0",
                    width: "100%",
                    height:"65px",
                    // backgroundPosition: "top -8px left -12px",
                    backgroundSize: "calc(100% + 16px) calc(100% + 8px)",
                    backgroundColor:"white",
                    padding: "0 2px 2px 0",
                    zIndex: theme.zIndex.appBar,
                    "& a": {
                        color: "inherit", textDecoration: "none", "&:hover": { color: "inherit", textDecoration: "none" }
                    }
                }}>
                    <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 1 }}>
                        {/*LOGO*/}
                        <Typography noWrap component="a" 
                            onClick={(event) => handleClickLink("/#top", event)} href="/"
                            sx={{ mr: 4, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                            {props.logo}
                            <Typography sx={{ ml: 2, fontSize: "22px", color: "text.title" }}>中華學生事務學會</Typography>
                        </Typography>
                    </Toolbar>
                </Box>
                :
                <AppBar sx={{
                    backgroundColor: "#ffffff",
                    color: (theme) => theme.palette.appBar.contrastText,
                    "& a": {
                        color: "inherit", textDecoration: "none", "&:hover": { color: "inherit", textDecoration: "none" }
                    },
                }}>
                    {/*LOGO*/}
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", py: 1 }}>
                        <Typography variant="h6" noWrap component="a"
                            onClick={(event) => handleClickLink("/#top", event)} href="/"
                            sx={{ display: { xs: 'none', md: 'flex' }, mr: 2, width: 90, height: 80 }}>
                            {props.logo}
                        </Typography>
                        <Typography
                            fontSize="32px" component="a" noWrap style={{ color: "#32425F" }}
                            onClick={(event) => handleClickLink("/#top", event)} href="/"
                        >
                            {props.title}
                        </Typography>
                    </Box>
                    <Toolbar sx={{ backgroundColor: "primary.main", width: "100%", minHeight: "64px !important", p: 0, m: 0, display: "flex", justifyContent: "center" }}>
                        {/*Pages*/}
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            {items.map((item, index) => {
                                if (isMenuItem(item)) return (
                                    <PopOutButton
                                        key={item.name} item={item}
                                        onClickItem={handleClickItem}
                                    />
                                );
                                else return (
                                    <Button
                                        key={item.name} href={item.link} disabled={item.disabled} onClick={(event) => handleClickItem(item, event)}
                                        sx={{ mx: 2.5 }}
                                    >
                                        <Typography color="white" fontSize="18px">
                                            {item.t ? t(item.name) : (i18n.language === "ch" ? item.name : item.enName)}
                                        </Typography>
                                    </Button>
                                );
                            })}
                        </Box>
                    </Toolbar>
                </AppBar>
            }
        </>}

        <NavSideBar navItems={items} />
    </>)
}
export interface NavBarProps {
    logo?: React.ReactElement;
    // name?: React.ReactElement;
    title?: string;
}
export type NavBarMenuItem = {
    name: string;
    itemList: NavBarLinkItem[];
    anonymousOnly?: boolean;
    adminOnly?: boolean;
    label?: string;
    sx?: SxProps<Theme>;
    t?: boolean;
    enName?: string;
}
export type NavBarLinkItem = {
    name: string; //顯示的文字
    link?: string;
    anonymousOnly?: boolean; //預設為false
    adminOnly?: boolean; //預設為false，是否只准許管理員以上權限閱覽
    label?: string; //基本上是拿來做key的，所以除了活動議程，其他項目可有可無
    onClick?: (event: MouseEvent | TouchEvent) => void;
    sx?: SxProps<Theme>;
    t?: boolean; //預設為false，是否要翻譯
    enName?: string; //為了活動議程設計的= =
    disabled?: boolean;
}
export type NavBarItem = NavBarMenuItem | NavBarLinkItem;
export function isMenuItem(object: NavBarItem): object is NavBarMenuItem {
    return 'itemList' in object;
}