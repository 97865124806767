import { useTheme, Box, Container, Paper, Typography, Stack, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";

export  function Tab5() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const contents = [
        {
            title: "會員種類與會費",
            content: <MemberType />
        }, {
            title: "會費繳款方式說明",
            content: <Payment />
        }, {
            title: "團體會員入會方式說明",
            content: <Group />
        },]
   
    return (<>
        <SubTitle title="會務運作" />
        <br />
        <img src="images/大事紀.png" style={{ maxWidth: "100%"}} />
        {/*contents.map(c => (<Accordion disableGutters key={c.title}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fill: "#fff" }} />}
                aria-controls="panel1a-content"
                sx={{ flexDirection: 'row-reverse', backgroundColor: "primary.main", color: "#fff" }}
            >
                <Typography >{c.title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ "& td": { fontSize: { xs: "10px", md: "1rem" }, padding: "5px", minWidth: "60px" } }}>
                {c.content}
            </AccordionDetails>
        </Accordion>))*/}
    </>)     
}

function MemberType() {
    return <>
        <Typography variant='subtitle1' fontWeight="bold" marginBottom="10px">會員（Membership）</Typography>
        <Typography variant='body1' color="#3F3F44">一、一般會員：現任或曾任各級學校學生事務（訓導）工作之人員；或熱心學生事務工作並認同本會宗旨者。</Typography>
        <Typography variant='body1' color="#3F3F44">二、團體會員：國內教育主管機關立案之各級學校。</Typography>
        <Typography variant='body1' color="#3F3F44">三、榮譽會員：對學生事務研究與發展著有貢獻者。</Typography>
        <br/>
        <Typography variant='subtitle1' fontWeight="bold" marginBottom="10px">會費（Membership Fee）</Typography>
        <Typography variant='body1' color="#3F3F44">一、入 會 費：一般會員500元、團體會員1000元</Typography>
        <Typography variant='body1' color="#3F3F44">二、常年會費：一般會員500元、團體會員4000元</Typography>
    </>
}
function Payment() {
    return <Box sx={{ "& p": { marginBottom:"10px" } }}>
        <Typography variant='body1' color="#3F3F44">※郵政劃撥</Typography>
        <Typography variant='body1' color="#3F3F44">郵政劃撥帳號：19775094</Typography>
        <Typography variant='body1' color="#3F3F44">戶名：中華學生事務學會</Typography>
        <Typography variant='body1' color="#3F3F44">郵政劃撥單下載: 郵政劃撥單</Typography>
        <Typography variant='body1' color="#3F3F44">備註：</Typography>
        <Typography variant='body1' color="#3F3F44">(1)請於通訊欄註明「收據抬頭」、「聯絡人資料」</Typography>
        <Typography variant='body1' color="#3F3F44">(2)繳款完畢後，若急需開立收據，請電洽02-2311-1531#7102 陳小姐</Typography>
    </Box>
}

function Group() {
    return <Box sx={{ "& p": { marginBottom: "10px" } }}>
        <Typography variant='body1' color="#3F3F44" fontWeight='bold'>歡迎各級學校團體加入中華學生事務學會</Typography>
        <Typography variant='body1' color="#3F3F44"> 敬請下載填寫團體會員資料表</Typography>
        <ul style={{ listStyle: "square" }}>
            <li>團體會員：國內教育主管機關立案之各級學校。入會費新台幣1000元，常年會費新台幣4000元。</li>
            <li>凡加入團體會員者請加蓋單位戳章，以示證明。</li>
            <li>繳款方式：郵政劃撥。帳戶名稱：中華學生事務學會，劃撥帳號：19775094。</li>
            <li>敬請先至郵局劃撥繳款。(下載：郵政劃撥單)</li>
            <li>繳款完畢後，若急需開立收據，請電洽02-2311-1531#7102 陳小姐</li>
        </ul>
    </Box>
}
