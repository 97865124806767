import { Backdrop, Box, BoxProps, Button, Card, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, Stack, styled, SvgIcon, TextField, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { PageTitle } from "../components/Common";

export function RelatedSite() {
    const history = useHistory();
    const theme = useTheme();

    const links = [{
        name: '教育部青年發展署',
        href: 'https://www.yda.gov.tw/'
    }, {
        name: '教育部學生事務與特教司',
        href: 'https://www.edu.tw/Default.aspx?wid=65cdb365-af62-48cc-99d9-f9e2646b5b70'
    }, {
        name: '北一區大專校院學生事務工作協調聯絡中心',
        href: 'http://www.sa.ntnu.edu.tw/files/90-1000-2.php'
    }, {
        name: '北二區大專校院學生事務工作協調聯絡中心',
        href: 'https://www.lhu.edu.tw/osa/n2_news.htm'
    }, {
        name: '中區大專校院學生事務工作協調聯絡中心',
        href: 'https://www.osa.nchu.edu.tw/osa/Coordinating_Center.html'
    }, {
        name: '南區大專校院學生事務工作協調聯絡中心',
        href: 'https://osa.ncku.edu.tw/p/412-1026-10803.php?Lang=zh-tw'
    }, {
        name: '臺灣服務學習學會',
        href: 'https://taiwansla.org/TSLA/TSLA_news.html'
    }, {
        name: '中華學生社團教育學會',
        href: 'http://www.essot.org.tw/'
    }, {
        name: '國立臺灣師範大學公民教育與活動領導學系',
        href: 'http://cve.ntnu.edu.tw/index.php'
    },
    ]

    return (<>
        <PageTitle title="相關網站連結" subtitle="相關網站" />
        <br />
        <Container>
            <ul style={{ margin: '0 auto', width: 'max-content', color: theme.palette.primary.main }}>
                {links.map(link => <LinkItem link={link} key={link.href} />)}
            </ul>
        </Container>
    </>)
}


function LinkItem(props: { link: { name: string, href: string } }) {
    const { link } = props;
    return <li style={{ padding: '5px 0' }}><Link href={link.href} underline="none" target='_blank'>{link.name} <OpenInNewIcon /></Link></li>
}