import { Box, Toolbar, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { StickyContainer, Sticky } from 'react-sticky';

export function PageTitleBar(props: PageTitleBarProps) {
    const { titleTransKey, fontColor, headerBarColor, offsetX, sm, md, lg, xl } = props;
    const { children, className } = props;

    const theme = useTheme();
    const backgroundColor = props.backgroundColor ?? theme.palette.background.default;
    const paddingBottom = props.paddingBottom ?? 30;

    return (
        <StickyContainer className={className}>
            <Sticky topOffset={40}>{({ style, isSticky }) =>
                <header
                    style={{
                        ...style,
                        zIndex: 200,
                        maxWidth: '100vw',
                        transform: isSticky && 'translateY(44px)',
                        transition: isSticky ? 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms' : 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
                    }}
                >
                    <PageTitleBarWithoutSticky
                        titleTransKey={titleTransKey}
                        fontColor={fontColor}
                        backgroundColor={backgroundColor}
                        headerBarColor={headerBarColor}
                        offsetX={offsetX}
                        sm={sm}
                        md={md}
                        lg={lg}
                        xl={xl}
                    />
                </header>
            }
            </Sticky>
            <div style={{ height: paddingBottom }} />
            {children}
        </StickyContainer>
    );
};
type PageTitleBarProps = PageTitleBarCustomProps & React.HTMLProps<HTMLElement>;
interface PageTitleBarCustomProps {
    titleTransKey: string;
    fontColor?: string;
    headerBarColor?: string;
    backgroundColor?: string;
    offsetX?: number;
    sm?: boolean;
    md?: boolean;
    lg?: boolean;
    xl?: boolean;
    paddingBottom?: number | string;
}

export function PageTitleBarWithoutSticky(props: PageTitleBarWithoutStickyProps) {
    const { titleTransKey, children, className, emptySectionClassName, wordsSectionClassName } = props;
    const { t } = useTranslation();

    const theme = useTheme();
    const fontColor = props.fontColor ?? theme.palette.primary.contrastText;
    const backgroundColor = props.backgroundColor ?? theme.palette.background.default;
    const headerBarColor = props.headerBarColor ?? theme.palette.primary.main;
    const offsetX = props.offsetX ?? 0;

    const sm = props.sm ?? true;
    const md = props.md ?? true;
    const lg = props.lg ?? true;
    const xl = props.xl ?? true;

    const xs_width = `calc(40px + ${offsetX}px)`;
    const sm_width = sm ? `calc(calc(calc(100vw - 540px) + ${offsetX}px) / 2)` : xs_width;
    const md_width = md ? `calc(calc(calc(100vw - 720px) + ${offsetX}px) / 2)` : xs_width;
    const lg_width = lg ? `calc(calc(calc(100vw - 960px) + ${offsetX}px) / 2)` : xs_width;
    const xl_width = xl ? `calc(calc(calc(100vw - 1140px) + ${offsetX}px) / 2)` : xs_width;

    return (
        <Toolbar
            variant='dense'
            className={className}
            style={{
                boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px -2px',
                backgroundColor: backgroundColor,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    height: '40px',
                    position: 'absolute',
                    bottom: '-20px',
                    left: 0,
                }}
            >
                <Box className={emptySectionClassName} sx={!emptySectionClassName ?
                    {
                        width: {
                            xs: xs_width, // theme.breakpoints.up('xs')
                            sm: sm_width, // theme.breakpoints.up('sm')
                            md: md_width, // theme.breakpoints.up('md')
                            lg: lg_width, // theme.breakpoints.up('lg')
                            xl: xl_width, // theme.breakpoints.up('xl')
                        },
                    } : null} style={{ background: `linear-gradient(to left, ${headerBarColor}, #00000000)`, height: '100%' }}
                />
                <Box className={wordsSectionClassName}
                    sx={!wordsSectionClassName ?
                        {
                            maxWidth: {
                                xs: `calc(calc(100vw - ${xs_width}) - 24px)`,
                                sm: `calc(calc(100vw - ${sm_width}) - 24px)`,
                                md: `calc(calc(100vw - ${md_width}) - 24px)`,
                                lg: `calc(calc(100vw - ${lg_width}) - 24px)`,
                                xl: `calc(calc(100vw - ${xl_width}) - 24px)`,
                            }
                        } : null
                    }
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 24px',
                        height: '100%',
                        backgroundColor: headerBarColor,
                        borderRadius: '0px 10px 10px 0px',
                        color: fontColor
                    }}
                >
                    {titleTransKey &&
                        <Typography variant='h5' fontWeight='bold'>
                            {t(titleTransKey)}
                        </Typography>
                    }
                    {children}
                </Box>
            </Box>
        </Toolbar>
    )
}
export interface PageTitleBarWithoutStickyProps {
    titleTransKey?: string;
    children?: React.ReactNode;
    fontColor?: string;
    headerBarColor?: string;
    backgroundColor?: string;
    offsetX?: number;
    sm?: boolean;
    md?: boolean;
    lg?: boolean;
    xl?: boolean;
    className?: string;
    emptySectionClassName?: string;
    wordsSectionClassName?: string;
}