import {
    Backdrop, Box, BoxProps, Button, Card, CircularProgress, Divider,
    Stack, Tab, Tabs, tabsClasses, Typography, useMediaQuery, useTheme
} from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router";
import { SubTitle } from "../components/Common";
import { NewsType, News, User, UserData, UserType } from "../components/class";
import { useGetNewsListQuery } from '../components/services/news';

export function NewsBox() {
    const history = useHistory();
    const theme = useTheme();

    const [filterType, setFilterType] = React.useState<NewsType>(NewsType.所有公告);
    const [newsList, setNewList] = React.useState<News[]>([])
    const maxNum = 6;
    const sortList = newsList.filter(n => filterType == 0 ? true : n.type == filterType).sort((a, b) => { return a.top == true ? -1 : 0 }).slice(0, maxNum);

    const { data: newsListData, isLoading: isGetNewListLoading, refetch, isFetching } = useGetNewsListQuery(NewsType.所有公告);

    React.useEffect(() => {
        if (newsListData && newsListData.isSuccess) {
            setNewList(newsListData.data);
        }
    }, [newsListData])

    function handleChange(event: React.SyntheticEvent, newValue: any) {
        setFilterType(newValue);
    };

    const tabStyle = {
        width: { xs: "12vw", sm: "130px" },
        height: "40px",
        mt: "5px",
        mb: "3px",
        mx: 0.5,
        borderRadius: "5px",
        color: "white",
        backgroundColor: "primary.light",
        "&:active": {
            color: "white",
            backgroundColor: "primary.main"
        },
        "&:hover": {
            color: "white",
            backgroundColor: "primary.main"
        },
        "&.Mui-selected, &.Mui-selected.Mui-focused": {
            backgroundColor: "primary.main",
            color: "white"
        },
    }

    // 跳轉 
    const handleClickLink = (link: string) => {
        history.push(link);
    }

    if (!isGetNewListLoading) {
        return (<>
            <Box sx={{ width: { xs: "95%", md: "50vw" }, height: "auto", backgroundColor: "background.paper", borderRadius: "12px", my: 3, position: "relative" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ my: { xs: 1, sm: 2 }, mx: 3,  }}>
                        <SubTitle title="最新消息" />
                    </Box>
                    <Tabs
                        value={filterType} onChange={handleChange}
                        variant="scrollable" scrollButtons
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                xs: {},
                                sm: {}
                            },
                            mr: 1,
                            display: "flex", flexDirection: "row", justifyContent: "center",
                        }}
                    >
                        <Tab value={NewsType.所有公告} label="所有公告"
                            sx={tabStyle} />
                        <Tab value={NewsType.會務公告} label="會務公告"
                            sx={tabStyle} />
                        <Tab value={NewsType.研討會公告} label="研討會公告" sx={tabStyle} />
                        <Tab value={NewsType.活動消息} label="活動消息" sx={tabStyle} />
                        <Tab value="-1" label="會議記錄" onClick={() => handleClickLink("/Event")} sx={tabStyle} />
                    </Tabs>
                </Box>
                <Divider />
                <Box />

                <Stack direction="column" spacing={2} sx={{ m: 3, minHeight: "380px" }}>
                    {sortList.map(n => <NewItem data={n} key={n.newsId} />)}
                </Stack>

                <Button variant="contained"
                    sx={{ backgroundColor: "secondary.light", position: "absolute", right: "20px", bottom: "20px" }}
                    onClick={() => handleClickLink("/News")}
                >
                    更多消息 &gt;
                </Button>
            </Box>
        </>)
    }
    else {
        return (
            <Backdrop open sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}

function NewItem(props: { data: News }) {
    const { data } = props;
    const history = useHistory();
    return <Stack direction="row" spacing={1}>
        <Stack direction="column" sx={{ "width": "100%", "borderBottom": "1px solid ", borderColor: 'background.dividing', paddingBottom: '10px' }} >
            <Stack direction="row" spacing={1.25} alignItems='center'>
                {data.top ? <Flag /> : <Box style={{ width: "18px", height: "18px" }} />}
                <Typography variant='body1' bgcolor='text.highlight2' color='#fff' borderRadius='2px' padding='2px 10px' width='100px' align='center'>{NewsType[data.type]}</Typography>
                <Box style={{ width: "12px", height: "18px" }} />
                <Typography variant='body1' color="secondary.main">{new Date(data.createTime).toLocaleDateString()}</Typography>
                <Box style={{ width: "12px", height: "18px" }} />
                <Typography variant='body1' onClick={() => { history.push(`/News?id=${data.newsId}`) }} sx={{ cursor: "pointer" }}>{data.title}</Typography>
            </Stack>
        </Stack>
    </Stack>
}

function Flag(props) {
    return <img {...props} style={{ width: "18px", height: "18px" }} src='images/flag.svg' />
}
