import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { SubTitle } from "../../components/Common";

export function Tab1of3() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();


    return (<>
        <SubTitle title="團體入會方式" />
        <br />
        <Group />
    </>)

}
function Group() {
    return <Box sx={{ "& p": { marginBottom: "10px" } }}>
        <Typography variant='body1' color="#3F3F44" fontWeight='bold'>歡迎各級學校團體加入中華學生事務學會</Typography>
        <Typography variant='body1' color="#3F3F44"> 敬請下載填寫團體會員資料表</Typography>
        <ul style={{ listStyle: "square" }}>
            <li>團體會員：國內教育主管機關立案之各級學校。入會費新台幣1000元，常年會費新台幣4000元。</li>
            <li>凡加入團體會員者請加蓋單位戳章，以示證明。</li>
            <li>繳款方式：郵政劃撥。帳戶名稱：中華學生事務學會，劃撥帳號：19775094。</li>
            <li>敬請先至郵局劃撥繳款。(下載：郵政劃撥單)</li>
            <li>繳款完畢後，若急需開立收據，請電洽02-2311-1531#7102 陳小姐</li>
        </ul>
    </Box>
}