import {
    Backdrop, Box, Typography, useMediaQuery, useTheme
} from "@mui/material";
import * as React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel/lib/js';
import { LoginBox } from './LoginBox';
import { NewsBox } from './NewsBox';
const logo = "./images/logo.png";


export function Home() {
    const theme = useTheme();
    const isMatchesSm = useMediaQuery(theme.breakpoints.up('md'));

    const [index, setIndex] = React.useState<number>(0);
    const images = ["images/home/大合照.jpg","images/home/2018.jpg", "images/home/2019.jpg", "images/home/2020.jpg", "images/home/2021.jpg", "images/home/2022.jpg", "images/home/2023.jpg"];

    return (<>
        <Box sx={{
            width: "100%", height: { md: "calc(100vh - 160px)",xs:"55vh" }, mt: "10vh", backgroundColor: "background.fourth",
            display: "flex", justifyContent: "center", alignItems: "center"
        }}>
            <Carousel
                autoPlay
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                useKeyboardArrows={false}
                emulateTouch={true}
                selectedItem={index}
                infiniteLoop
            >
                {images.map((imageSrc, idx) => (
                    <Box key={idx} sx={{ height: { md: "calc(100vh - 160px)", xs: "55vh" }}}>
                        <img src={imageSrc} width="100%" height="100%" style={{ objectFit: "contain" }} />
                        <Typography variant="h1" align="center" sx={{ backgroundColor: "text.subText", color: "white" }}>
                            圖片標題
                        </Typography>
                    </Box>

                ))}
            </Carousel>
        </Box>
        {isMatchesSm ?
            <Box height="540px" sx={{
                backgroundColor: "background.fifth",
                display: "flex", flexDirection: "row", justifyContent: "center",
            }}>
                <LoginBox />
                <Box sx={{ mx: 1 }} />
                <NewsBox />
            </Box>
            :
            <Box sx={{
                backgroundColor: "background.fifth",
                display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
            }}>
                <LoginBox />
                <Box sx={{ my: 1 }} />
                <NewsBox />
            </Box>
        } 
    </>)
}