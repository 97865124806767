import * as React from 'react';
import { Assignment, Ballot, EventNote, History as HistoryIcon, Key, Logout, More, Newspaper, PeopleAlt, QrCode2, Settings, Storefront, VpnKey } from '@mui/icons-material';
import { ManagePageItem, ManageSideBarItem } from "./ManageBoardIndex";
import EventsIndex from './Events/EventsIndex';
import PublicationIndex from './PublicationList/PublicationIndex';
import NewsManage from './NewsList/NewsManage';
import UserManage from './UserList/UserManage';

export type viewCode = '0' | '1' | '2' | '3' | '4' ;
export interface pageView {
    titleKey: string;
    list: ManagePageItem[]
}
export type ManagePageView = Record<viewCode, pageView>;

//後台導覽欄顯示設定
export const sideBarItems: ManageSideBarItem[] = [
    {
        name: '會員管理',
        className: 'manage.title.member',
        value: '0',
        adminOnly: true,
    },
  /*  {
        name: '首頁輪播圖',
        className: 'manage.title.home',
        value: '1',
        adminOnly: true,
    },*/
    {
        name: '最新消息列表',
        className: 'manage.title.news',
        value: '2',
        adminOnly: true,
    },
    {
        name: '學會刊物列表',
        className: 'manage.title.publication',
        value: '3',
        adminOnly: true,
        pageList: [
            {
                name: '研討會簡報',
                className: 'manage.title.events.publication1',
                value: '3',
                adminOnly: true,
                query: "e=0"
            },
            {
                name: '學生事務與輔導季刊',
                className: 'manage.title.events.publication2',
                value: '3',
                adminOnly: true,
                query: "e=1"
            },
            {
                name: '學習資源',
                className: 'manage.title.events.meeting',
                value: '4',
                adminOnly: true,
                query: "e=4"
            },
        ]
    },
    {
        name: '會議記錄列表',
        className: 'manage.title.events',
        value: '4',
        adminOnly: true,
        showOnMobile: true,
        pageList: [
            {
                name: '理監事會議',
                className: 'manage.title.events.meeting',
                value: '4',
                adminOnly: true,
                query: "e=0"
            },
            {
                name: '會員大會',
                className: 'manage.title.events.meeting',
                value: '4',
                adminOnly: true,
                query: "e=1"
            },
            {
                name: '研討會',
                className: 'manage.title.events.meeting',
                value: '4',
                adminOnly: true,
                query: "e=2"
            },
            {
                name: '其他活動紀錄',
                className: 'manage.title.events.other',
                value: '4',
                adminOnly: true,
                query: "e=3"
            },
        ]
    },
];

//後台檢視畫面顯示設定
export const viewItems: ManagePageView = {
    0: {
        titleKey: 'manage.title.member', 
        list: [
            { className: 'manage.title.member', content: <UserManage />, adminOnly: true },
        ]
    },
   1: {
        titleKey: 'manage.title.home', 
        list: [          
            { className: 'manage.title.home', content: <EventsIndex />, adminOnly: true },
        ]
    },
    2: {
        titleKey: 'manage.title.news', 
        list: [
            { className: 'manage.title.news', content: <NewsManage />, adminOnly: true },
        ]
    },
    3: {
        titleKey: 'manage.title.publication', 
        list: [
            { className: 'manage.title.publication', content: <PublicationIndex />, adminOnly: true },
        ]
    },
    4: {
        titleKey: 'manage.title.event', 
        list: [
            { className: 'manage.title.event', content: <EventsIndex />, adminOnly: true },
        ]
    },
}